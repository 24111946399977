import React from 'react';

const usePagination = (list, numberItemsPage, firstPage = 0) => {
    const [currentPage, setCurrentPage] = React.useState(firstPage);
    const numberPages = React.useMemo(() => (list?.length ?? 0) / numberItemsPage, [list, numberItemsPage]);
    const pages = React.useMemo(() => {
        var list = [];

        for (var i = 0; i < numberPages; i++) {
            list.push((i + 1));
        }

        return list;
    }, [numberPages])

    return [currentPage, pages, setCurrentPage];
};

export default usePagination;