import React, { useState } from 'react';
import { Link } from "react-router-dom";
import selectUnity from '../hooks/selectUnity'


export default function Productitem(props) {

    const [isShown, setIsShown] = useState({qt_1: '', qt_2:'', qt_3:'', default:true});

   const person = (obj) => {
           return("Idéal pour " + obj + " personne" + ((obj > 1)?"s":""));
   }

   const selectNbre = () => {
       if (props.hidePersons !== undefined && props.hidePersons === true) {
           return("Selectionnez le poids");
       }
        if (isShown.default === true){
            return("Selectionnez le poids")
        }
       if (isShown.qt_1 === true){
           return(person(props.nbr_person))
       }
       if (isShown.qt_2 === true) {
        return(person(props.nbr_person*2))
       }
       if (isShown.qt_3 === true) {
        return(person(props.nbr_person*3))
       }
   }

   const truncate = (text) => {
        return (text.length > 150 ? text.substring(0, 147) + "..." : text);
   }

    return (
        <>
            <article
                className="product-thumbnail"
                itemProp={props.isRelatedTo ? "isRelatedTo" : null}
                itemType="http://schema.org/Product"
            >
                <div className="product-img-wrapper">
                    <Link to={props.slug}>
                        <img className="product-img" alt="product-img" src={props.image} loading={"lazy"} />
                        {(props.confession !== undefined && props.confession !== null && props.confession.id !== 1) &&
                            <span className="confession">{props.confession.libelle}</span>
                        }
                        {/* <span className="discount">{props.discount}</span> */}
                    </Link>
                    <div className="chooseWeight">
                        <div className="number-person">{selectNbre()}</div>
                        <div className="row no-gutters">
                            <div className="col-3"
                            onMouseEnter={() => setIsShown({qt_1: true})}
                            onMouseLeave={() => setIsShown({qt_1: false, default: true})}
                            >

                                <Link rel="nofollow" to={{pathname: props.slug}} className="weight-item">
                                    {selectUnity(props.quantity)}
                                </Link>
                            </div>
                            <div className="col-3"
                            onMouseEnter={() => setIsShown({qt_2: true})}
                            onMouseLeave={() => setIsShown({qt_2: false, default: true})}
                            >
                                <Link rel="nofollow" to={{pathname: props.slug}} className="weight-item">
                                    {selectUnity(props.quantity*2)}
                                </Link>
                            </div>
                            <div className="col-3"
                            onMouseEnter={() => setIsShown({qt_3: true})}
                            onMouseLeave={() => setIsShown({qt_3: false, default: true})}
                            >
                                <Link rel="nofollow" to={{pathname: props.slug}} className="weight-item">
                                    {selectUnity(props.quantity*3)}
                                </Link>
                            </div>
                            <div className="col-3">
                                <Link to={props.slug} className="weight-item next">
                                    <svg viewBox="0 0 17.29 25.05" xmlns="http://www.w3.org/2000/svg"><path d="m0 0 12.52 12.52-12.52 12.53h4.76l12.53-12.53-12.53-12.52z" fill="#090400"/></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={props.slug}>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                        <div className="product">{props.product}</div>
                        <div className="product-category">{props.category}</div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {props.rating > 0 &&
                                <div className="d-flex align-items-center">
                                    <Rating rating={props.rating}/>
                                    <div className="product-number-vote">{props.notice}</div>
                                </div>
                            }
                        </div>
                        {(props.priceMin || props.priceMax) &&
                            [props.priceMin !== props.priceMax
                                ?
                                <div className="product-price-range" key={1}>{parseFloat(props.priceMin).toFixed(2)} € - {parseFloat(props.priceMax).toFixed(2)} € pc.</div>
                                :
                                <div className="product-price-range" key={2}>{parseFloat(props.priceMin).toFixed(2)} € pc.</div>
                            ]
                        }
                    </div>
                </Link>

                {props.description !== undefined && props.description !== null &&
                    <p className="product-descirption pt-2"><small>{truncate(props.description)}</small></p>
                }

            </article>
        </>
    );
}


function Rating(props) {
    const rating = Math.round(props.rating);
    var rates = [];
    for (var i = 0; i < rating; i++) {
        rates.push(<span className='rate-circle mr-s1' key={i}></span>);
    }
    if (props.rating < 5) {
        for (var j = rating; j < 5; j++) {
            rates.push(<span className='rate-circle-empty mr-s1' key={j}></span>);
        }
    }
    return rates;
}
