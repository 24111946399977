export const stockSeller = 
  [{
    slug_seller:"la-boucherie-du-kiosque",
    products:
        [
            {
                slug_product:"cote-premiere",
                img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
                confession:"Halal",
                discount:"-10%",
                productTitle:"Bœuf fermier Aubrac",
                product:"Côte première",
                category:"Bœuf",
                seller:"La boucherie du kiosque",
                notice:"14",
                comments:"13",
                priceActual:"41,27€",
                priceOld:"58,95€",
                rating:"4",
                priceMin:"40,27€",
                priceMax:"58,95€",
                quantity_1:"0.25kg",
                quantity_2:"0.5kg",
                quantity_3:"0.75kg",
                quantity_4:"1.25kg",
                nbr_person_1:"1",
                nbr_person_2:"2",
                nbr_person_3:"3",
                nbr_person_4:"4",
            },
            {
                slug_product:"steak-hache",
                img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
                confession:"Halal",
                discount:"-10%",
                productTitle:"Steak haché",
                product:"Steak haché",
                category:"Bœuf",
                seller:"La boucherie du kiosque",
                notice:"124",
                comments:"13",
                priceActual:"41,27€",
                priceOld:"58,95€",
                rating:"5",
                priceMin:"39,27€",
                priceMax:"60,95€",
                quantity_1:"0.25kg",
                quantity_2:"0.5kg",
                quantity_3:"0.75kg",
                quantity_4:"1.25kg",
                nbr_person_1:"1",
                nbr_person_2:"2",
                nbr_person_3:"3",
                nbr_person_4:"4",

        
            },
            {
              slug_product:"steak-hache",
              img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
              confession:"Halal",
              discount:"-10%",
              productTitle:"Steak haché",
              product:"Steak haché",
              category:"Bœuf",
              seller:"La boucherie du kiosque",
              notice:"124",
              comments:"13",
              priceActual:"41,27€",
              priceOld:"58,95€",
              rating:"5",
              priceMin:"39,27€",
              priceMax:"60,95€",
              quantity_1:"0.25kg",
              quantity_2:"0.5kg",
              quantity_3:"0.75kg",
              quantity_4:"1.25kg",
              nbr_person_1:"1",
              nbr_person_2:"2",
              nbr_person_3:"3",
              nbr_person_4:"4",

      
          },
          {
            slug_product:"steak-hache",
            img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
            confession:"Halal",
            discount:"-10%",
            productTitle:"Steak haché",
            product:"Steak haché",
            category:"Bœuf",
            seller:"La boucherie du kiosque",
            notice:"124",
            comments:"13",
            priceActual:"41,27€",
            priceOld:"58,95€",
            rating:"5",
            priceMin:"39,27€",
            priceMax:"60,95€",
            quantity_1:"0.25kg",
            quantity_2:"0.5kg",
            quantity_3:"0.75kg",
            quantity_4:"1.25kg",
            nbr_person_1:"1",
            nbr_person_2:"2",
            nbr_person_3:"3",
            nbr_person_4:"4",

    
        },
        {
          slug_product:"steak-hache",
          img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
          confession:"Halal",
          discount:"-10%",
          productTitle:"Steak haché",
          product:"Steak haché",
          category:"Bœuf",
          seller:"La boucherie du kiosque",
          notice:"124",
          comments:"13",
          priceActual:"41,27€",
          priceOld:"58,95€",
          rating:"5",
          priceMin:"39,27€",
          priceMax:"60,95€",
          quantity_1:"0.25kg",
          quantity_2:"0.5kg",
          quantity_3:"0.75kg",
          quantity_4:"1.25kg",
          nbr_person_1:"1",
          nbr_person_2:"2",
          nbr_person_3:"3",
          nbr_person_4:"4",

  
      },
            

        ],
    },
    {
    slug_seller:"la-boucherie-des-minimes",
    products:
    [
        {
            slug_product:"cote-premiere",
            img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
            confession:"Halal",
            discount:"-10%",
            productTitle:"Bœuf fermier Aubrac",
            product:"Côte première • Bœuf",
            seller:"La boucherie du kiosque",
                  notice:"124",
            comments:"13",
            priceActual:"41,27€",
            priceOld:"58,95€",
            rating:"5"
            
      
          },
          {
            slug_product:"steak-hache",
            img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
            confession:"Halal",
            discount:"-10%",
            productTitle:"Steak haché",
            product:"Steak haché • Bœuf",
            seller:"La boucherie du kiosque",
            notice:"124",
            comments:"13",
            priceActual:"41,27€",
            priceOld:"58,95€",
            rating:"5"
            
      
          },
    ],
    },
    {
    slug_seller:"la-boucherie-des-copains",
    products:
    [
        {
            slug_product:"cote-premiere",
            img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
            confession:"Halal",
            discount:"-10%",
            productTitle:"Bœuf fermier Aubrac",
            product:"Côte première • Bœuf",
            seller:"La boucherie du kiosque",
            notice:"124",
            comments:"13",
            priceActual:"41,27€",
            priceOld:"58,95€",
            rating:"5"
            
      
          },
          {
            slug_product:"steak-hache",
            img:"https://scontent-cdg2-1.xx.fbcdn.net/v/t1.0-9/76784543_2579786888956925_6882483653578850304_n.jpg?_nc_cat=102&ccb=2&_nc_sid=9267fe&_nc_ohc=Yj_ch7KuO6oAX87mzML&_nc_ht=scontent-cdg2-1.xx&oh=0b35ee7f63dca28af788187c86148fac&oe=5FED6604",
            confession:"Halal",
            discount:"-10%",
            productTitle:"Steak haché",
            product:"Steak haché • Bœuf",
            seller:"La boucherie du kiosque",
            notice:"124",
            comments:"13",
            priceActual:"41,27€",
            priceOld:"58,95€",
            rating:"3"
            
      
          },
    ]
},
]

