import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalResetCart = (props) => {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            centered
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">

                <div className="modal-content border-0">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title" id="shareSocialModal">Vous ne pouvez pas ajouter des produits de plusieurs boutiques différentes</h5>
                    </div>
                    <div className="modal-body py-3">
                        <p className="mb-3">Voulez-vous écraser le panier actuel avec le nouveau produit ?</p>
                    </div>
                    <div className="px-2 d-flex justify-content-end">
                        <button className="btn btn-secondary" onClick={props.onHide}>
                            Annuler
                        </button>
                        <button className="btn btn-primary ml-2" onClick={props.onClear}>
                            Écraser mon panier
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalResetCart;
