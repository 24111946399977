import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";
import {HOME_API_URL, BASE_URL_PHOTO_HOME, BASE_URL_PHOTO_VENDEUR,BASE_URL_NEARSHOP } from '../utils/api'


import HowStep from "../components/HowStep";
import SliceItemVendors from '../components/SliceItemVendors';
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';


function CommencerProfil(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL_NEARSHOP+window.location.search);
                console.log("resp", resp);
                setShops(resp.data);

            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        if (values.password !== values.password2) {
            onError("Les mots de passe ne sont pas identiques");
        } else {
            handleRequest("post", BASE_URL + RESET_PASSWORD(account, token), values, config, (data, status) => {
                if (status === "Done") {

                    props.history.push("/login")
                } else if (status === "Error") {
                    switch (data.status) {
                        case 401:
                            onError("Votre adresse mail ou mot de passe est éronné.");
                            break;
                        case 400:
                            onError("Veuillez remplir tous les champs nécéssaires.");
                            break;
                        default:
                            onError(`Une erreur de type ${data.status} est survenue...`);
                    }
                    console.error("Error on login", data);
                }
            })
        }
    }

    const index = 1
    const link = "#"
    const brand = {
            picture : "La-Coterie-Francaise-Boucherie-en-ligne-instagram-10-31052021.jpg",
        name : "Boucherie de la ruche",
        description : "A votre adresse saisie, nous vous avons sélectionné les partenaires pouvant vous livrer en quelques heures. Faites-vous plaisir en soutenant un partenaire connecté.",
    }

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };

    function Rating(props) {
        const rating = Math.round(props.value);
        var rates = [];
        for (var i = 0; i < rating; i++) {
            rates.push(<span className='rate-circle mr-s1' key={i}></span>);
        }
        if (rating < 5) {
            for (var j = rating; j < 5; j++) {
                rates.push(<span className='rate-circle-empty mr-s1' key={j}></span>);
            }
        }
        return rates;
    }

    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            enterLeft: "animated enterLeft",
            exitRight: "animated exitRight",
            exitLeft: "animated exitLeft",
        },
        demo: true, // uncomment to see more
    });

    const setInstance = () => updateState({
        ...state,
    });

    const First = props => { return null}
    const Second = props => {return null}
    const Third = props => {return null}
    const Quatre = props => {return null}
    const Cinq = props => {return null}
    const Six = props => {return null}
    const Last = props => {return null}

    const displayShops = () => {
        return shops.map((shop, index) => {

            // let link = "/"
            // if (confession !== undefined && confession !== null && confession.id !== 1) {
            //     link += confession.slug + "/label/";
            // } else {
            //     link += "label/";
            // }

            // link += brand.regions[0].slug + "/" + brand.slug;

            let link = "";
                        let imgLink = "";

                        if (shop[0].company?.seller?.type?.id === 2) {
                            link = "/eleveur/";
                          //  imgLink = EleveurDefaultImg;
                        } else {
                            link = "/boucherie/";
                          //  imgLink = BoucherieDefaultImg;
                        }
                        link += shop[0].city.slug +"/"+ shop[0].slug;

                        if (shop[0].specificity && shop[0].specificity.id !== 1) {
                            link = "/" + shop[0].specificity.slug + link;
                        }

            console.log("shop.name ->", shop[0])

            return(
                <div className="col-12 col-md-4 mb-4 VendorListCardHead">
                <div className="VendorListCard">
                    <div className="ConfessionDeBoutique">Boucherie {shop[0].specificity.libelle} à {parseInt(shop.distance).toFixed(1)+"km"}</div>
                    {/* <div><div>Boeuf</div><div>Agneau</div></div> */}
                    <div className="title-shop"><div className="name-shop">{shop[0].name}</div>
                    </div>
                    <div className="product-subline">
                        <div rel="schema:aggregateRating">
                            <div typeof="schema:AggregateRating">
                                    <span property="schema:ratingValue" content={shop[0].avgNotes} className="mr-1">
                                        <Rating value={shop[0].avgNotes}/>
                                    </span>
                                    <span property="schema:reviewCount" content={shop[0].notesCounter}>{shop[0].notesCounter} avis</span>
                            </div>
                        </div>
                    </div>
                    <div>{shop[0].address}</div>
                    {/* <div>31000 Toulouse - Occitanie</div> */}
                    <div>{shop[0].city.code+" "+shop[0].city.libelleAcheminement+" - "+shop[0].city.department.region.name}</div>
                    <div className="voir-shop">
                        <Link to={link} className={"mt-1 btn btn-primary class-full-widh"}>Choisir</Link>
                    </div>
                </div> 
            </div>
            )
        })
    }

    return (
        
        <div className="login">

            <div className="pt-3 pr-5 pl-5 center-content-wiz"> 
                <div className="avatar-passe d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-passe d-lg-none d-none" >1</div>
                <div className="avatar-passe d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar-passe d-lg-none d-none" >2</div>
                <div className="avatar-passe d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar-passe d-lg-none" >3</div>
                <div className="avatar-valide d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar-valide d-lg-none" >4</div>
                <div className="avatar d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar d-lg-none d-none" >5</div>
                <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none d-none" >6</div>
                <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                    {/* <div className="avatar-valide" >2</div>
                    <div className="avatar" >...</div>
                    <div className="avatar">7</div> */}
                </div>
            <div className="center-content-wiz-line pb-4"></div>

            <TitleSimple 
                subtitle={"Choisissez un artisan !"}
                text="Nous vous avons sélectionné les meilleurs artisans pouvant vous livrer à votre adresse, partout en France. Faites-vous plaisir !"
            />

        
                        <div className="row Style-Wizard StyleShopDisp">
                            {false ?
                                <p>Chargement</p>
                                :
                                
                                    displayShops()
                                

                            }
                        </div>
                        <div className="center-content">
                <Link to="/">Annuler</Link>
            </div>


        </div>
    );
}

export default withRouter(CommencerProfil);
