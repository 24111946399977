import React, {useEffect} from "react";
import {
  //StaticRouter as Router,
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Accueil from './pages/Accueil';
import Navigation from './components/Navigation';
import ShareModal from './components/ShareModal';
import Marque from "./pages/Marque";
import Vendeur from "./pages/Vendeur";
import Region from "./pages/Region";
import Product from "./pages/Product"; 
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Cart from "./pages/Cart";
import Payment from "./pages/Payment";
import OrderReturn from "./pages/OrderReturn";

import CommencerProfil from "./pages/CommencerProfil";
import CommencerAdresse from "./pages/CommencerAdresse";
import CommencerConfession from "./pages/CommencerConfession";
import CommencerVendeur from "./pages/CommencerVendeur";
import CommencerAccueil from "./pages/CommencerAccueil";
import CommencerHistoire from "./pages/CommencerHistoire";
import CommencerLivraison from "./pages/CommencerLivraison";

import RestaurantAccueil from "./pages/RestaurantAccueil";
import RestaurantCarte from "./pages/RestaurantCarte";
import RestaurantRace from "./pages/RestaurantRace";
import RestaurantConseil from "./pages/RestaurantConseil"; 
import RestaurantSelectCartoucherie from "./pages/RestaurantSelectCartoucherie"; 

import Account from "./pages/Account";
import Viandes from "./pages/statics/Viandes";
import Eleveurs from "./pages/statics/Eleveurs";
import Boucheries from "./pages/statics/Boucheries";
import PrivateRoutes from "./utils/private-routes";
import Signup from "./pages/Signup";
import ModalAddress from "./components/ModalAddress";
import Labels from "./pages/statics/Labels";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ConfessionProvider from "./hooks/Confession/ConfessionProvider";
import Producteur from "./pages/Producteur";
import ViandesCategory from "./pages/ViandesCategory";
import Forgot from "./pages/Forgot";
import GlobalHeaderTags from "./components/GlobalHeaderTags";
import ResetPassword from "./pages/ResetPassword";
import useCart from "./hooks/Cart/useCart";
import useContextApp from "./hooks/useContextApp";
import {API_DELETE_CART, API_POST_CART} from "./utils/api";
import useRequest from "./hooks/useRequest";
import {createMemoryHistory} from "history";


function App() {
  const [handleRequest] = useRequest();
  const [auth] = useContextApp();
  const [cart] = useCart();

  //const history = createMemoryHistory();

  useEffect(() => {
    if (auth.connected) {
      if (cart.cart !== undefined && cart.cart !== null && cart.cart.length > 0) {
        handleRequest("post", API_POST_CART, {
          "content": JSON.stringify(cart)
        }, auth, (data, status) => {
        });
      } else {
        handleRequest("post", API_DELETE_CART, {}, auth, (data, status) => {});
      }
    }
  }, [cart.cart]);

  return (
    <Router/* history={history}*/>
      <ConfessionProvider>
        <div>
          <GlobalHeaderTags/>
          <ScrollToTop/>
          <Navigation/>
          <Switch>
            {/*<Route path="/artisans" component={Vendeur}/>*/}
            <Route path="/professionnel">
              professionnel
            </Route>

            <Route path="/restaurant-accueil" component={RestaurantAccueil} />
            <Route path="/restaurant-carte" component={RestaurantCarte}/>
            <Route path="/restaurant-race" component={RestaurantRace}/>
            <Route path="/restaurant-conseil" component={RestaurantConseil}/>
            <Route path="/restaurant-RestaurantSelectCartoucherie" component={RestaurantSelectCartoucherie}/>

            {/* <Route path="/commencer-profil" component={CommencerProfil} />
            <Route path="/commencer-adresse" component={CommencerAdresse} /> 
            <Route path="/commencer-confession" component={CommencerConfession} />
            <Route path="/commencer-vendeur" component={CommencerVendeur} /> */}

            <Route path="/histoire-de-la-coterie-francaise" component={CommencerHistoire} /> 
            <Route path="/livraison-de-la-coterie-francaise" component={CommencerLivraison} />

            {/* <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout}/>
            <Route path="/signup" component={Signup}/>
            <Route path="/forgot" component={Forgot}/> */}
            <Route path="/reset/:account/:token" component={ResetPassword}/>
            {/* <Route path="/panier" component={Cart}/>
            <Route path="/paiement" component={Payment}/>
            <Route path="/orderreturn" component={OrderReturn}/>
            <PrivateRoutes path="/compte" component={Account}/> */}

            {/* <Route path={"/boucherie/:slug_city/:slug_seller/:slug_category/:slug_product"} render={(props) => <Product routeType={"boucherie"} {...props}/>}></Route>
            <Route path={"/label/:slug_region/:slug_marque/:slug_category/:slug_product/:slug_confession?"} render={(props) => <Product routeType={"label"} {...props}/>}></Route>
            <Route path={"/eleveur/:slug_city/:slug_seller/:slug_category/:slug_product"} render={(props) => <Product routeType={"eleveur"} {...props}/>}></Route>
            <Route path={"/:slug_confession/boucherie/:slug_city/:slug_seller/:slug_category/:slug_product"} render={(props) => <Product routeType={"boucherie"} {...props}/>}></Route>
            <Route path={"/viande/:slug_category/:slug_product"} render={(props) => <Product routeType={"viande"} {...props}/>}></Route>
            <Route path={"/:slug_confession/viande/:slug_category/:slug_product"} render={(props) => <Product routeType={"viande"} {...props}/>}></Route>
            <Route path={"/viande/:slug_category"} component={ViandesCategory}></Route>
            <Route path={"/:slug_confession/viande/:slug_category"} component={ViandesCategory}></Route>

            <Route path={"/boucherie/:slug_city/:slug_seller"} render={(props) => <Vendeur {...props}/>}></Route>
            <Route path={"/:slug_confession/boucherie/:slug_city/:slug_seller"} render={(props) => <Vendeur {...props}/>}></Route>

            <Route path={"/eleveur/:slug_city/:slug_seller"} render={(props) => <Producteur {...props}/>}></Route>
            <Route path={"/:slug_confession/eleveur/:slug_city/:slug_seller"} render={(props) => <Producteur {...props}/>}></Route>


            <Route path="/:slug_confession/label/:slug_region/:slug_brand" component={Marque}/>
            <Route path="/label/:slug_region/:slug_brand" component={Marque}/>

            <Route path="/:slug_confession/label/:slug_region" component={Region}/>
            <Route path="/label/:slug_region" component={Region}/>

            <Route path="/:slug_confession/label" component={Labels}/>
            <Route path="/label" component={Labels}/>

            <Route path="/:slug_confession/viande" component={Viandes}/>
            <Route path="/viande" component={Viandes}/>
            <Route path="/:slug_confession/boucherie" component={Boucheries}/>
            <Route path="/boucherie" component={Boucheries}/>
            <Route path="/:slug_confession/eleveur" component={Eleveurs}/>
            <Route path="/eleveur" component={Eleveurs}/> */}

            {/* <Route exact path="/:slug_confession?" component={Accueil} /> */}

            <Route path="/" component={CommencerAccueil} />


          </Switch>
        </div>
        <ShareModal />
        <ModalAddress />
      </ConfessionProvider>
    </Router>
  );
}

export default App;
