import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';

import conf from './confessions.json';

export const confessionContext = createContext();

const ConfessionProvider = (props) => {
    const [newConf, setNewConf] = React.useState();
    const location = useLocation();

    React.useEffect(() => {
        const loc = location.pathname.split("/");

        const data = conf.filter(el => loc?.includes(el.slug))[0];

        if (!loc?.includes("viande") && !data) {
            return
        }

        if (data) {
            setConfession(data.id);
            return;
        }
        setConfession(conf[0].id);
    }, [location])

    const objectConfession = React.useMemo(() => {
        let data = null;

        if (typeof window !== 'undefined') {
            data = localStorage.getItem("confession");
        }

        if (data) {
            const parsedData = JSON.parse(data)

            return parsedData;
        }

        return conf[0];
        // eslint-disable-next-line
    }, [newConf])

    const setConfession = (d) => {
        const confObject = conf.filter(el => el.id === d)[0];

        if (newConf !== confObject?.id) {
            localStorage.setItem("confession", JSON.stringify(confObject));
            setNewConf(d);
        }
    }

    const getConf = (d) => {
        return conf.filter(el => el.id === d)[0];
    }

    return (
        <confessionContext.Provider value={{
            confession: objectConfession,
            setConfession,
            confessions: conf,
            getConf
        }}>
            {props.children}
        </confessionContext.Provider>
    );
};

export default ConfessionProvider;
