export const stockData = [
    {
      title: "Quels sont les différents modes de récupération de ma commande de viande ?",
      description: "Où que vous soyez en France, notre partenaire de transport vous livre à votre adresse. La Coterie française est avant tout une solution de livraison de viande de qualité partout en France. Il est cependant également possible, suivant la proximité du point de vente, de récupérer la commande directement en boucherie. en suivant les créneaux horaires proposés par le l'artisan.",
    },
    {
        title: "Comment la boucherie en ligne garantit le respect de la chaîne du froid ?",
        description: "Nos partenaires de livraison assurent le maintien de la chaîne du froid de la collecte à la livraison. Vos colis de viandes sont transportés dans des contenants réfrigérés et des véhicules frigorifiques répondant à la norme ATP. Pour de très longue distance (>20km) les viandes sont ensuite conservées en chambre froide selon la température exigée par le produit. Les températures sont enregistrées et contrôlées, si un écart est constaté nous sommes prévenus par notre transporteur.",
    },
    {
        title: "La livraison de viande est prévue aujourd’hui, puis-je avoir un créneau plus précis ?",
        description: "Notre transporteur de viande s'engage à vous livrer au jour défini. Toutefois, un créneau est donné à titre indicatif. Vous recevrez un SMS et/ou appel lorsque le transporteur commence sa tournée afin de vous communiquer un créneau de passage plus précis. Vous avez la possibilité de re-programmer directement la livraison avec le mail, sms ou appel d'avisage. Si vous souhaitez changer d'adresse de livraison, vous devez nous en faire la demande au transporteur par mail ou appel aux coordonnées communiquées.",
    },
    {
        title: "J'ai un contretemps / Je suis absent pour réceptionner mon colis de viande, que se passe-t-il ?",
        description: "En cas d'absence lors de la livraison, un avis de passage sera déposé dans votre boite aux lettres et votre colis de viande sera conservé en chambre froide dans une agence proche de chez vous. Vous avez aussi la possibilité de re-programmer directement la livraison à une nouvelle adresse avec le mail ou sms d'avisage. Nous sommes conscients que vos pièces de viandes sont des produits à manipuler avec soin, nous accordons le meilleur à son transport.",
    },
    {
        title: "Comment puis-je suivre l'acheminement de votre commande de viande ?",
        description: "Afin de suivre l’acheminement de vos viandes de qualité précisément. Nous vous invitons à aller directement sur votre suivi de commande de viande. Vous pourrez ainsi suivre l'ensemble des étapes de l'envoi de votre commande jusqu'à sa livraison. Notre partenaire Chronofresh (Chronopost) vous assure un suivi en temps réel avec alerte mail, sms et appel si besoin.",
    },
    {
        title: "Est-ce que les livraisons de viandes sont possibles en point relais ?",
        description: "Pour garantir le respect de la chaîne du froid, la livraison de produits frais et surgelés n'est pas autorisée en point relais. Les colis frais et surgelés peuvent être mis à disposition directement dans les agences. Cependant, nous vous recommandons de définir une adresse de livraison de viande définie à votre domicile.",
    },
    {
        title: "J'aimerais me faire livrer la viande à l'international, est-ce possible ?",
        description: "L'envoi de viande fraîche est aujourd'hui disponible uniquement vers l'Espagne et la Belgique (Bruxelles et sa périphérie). Les délais de livraison de viande sont compris entre 5h et 48 jours selon les jours d’activité de votre boucherie en ligne et producteurs locaux. Dans certaines grandes villes en france nous garantissons une livraison le jour même. Les commandes passées avant 16h00 sont prises en charge par notre partenaire pour livraison dans l’après-midi ou en début de soirée.",
    },
  ];
