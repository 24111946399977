import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from "react-input-mask";
import { withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest from '../hooks/useRequest';
import { REGISTER_USER } from '../utils/api';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'

export default withRouter(function Signup(props) {
    const { register, handleSubmit, errors, watch } = useForm({
        defaultValues: {
            professional: false
        }
    });
    const password = useRef({});
    password.current = watch("password", "");
    const [handleRequest] = useRequest();
    const [config] = useContextApp();

    const onSubmit = data => {
        data.username = data.username?.toLowerCase();

        handleRequest("post", REGISTER_USER, data, config, (data, status) => {
            if (status === "Done") {
                //console.log(data);
                props.history.push("/login");

                if (typeof window !== 'undefined' && window !== undefined) {
                    window.gtag('event', 'sign_up', {
                        method: 'mail'
                    });
                }

            } else if (status === "Error") {
                //console.error(data);
            }
        })
    };
    //console.log(errors);

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const [professional, setProfessional] = useState(false);

    return(
        <div className="login">
            <div className="row no-gutters h-100">
                <div className="col-12 col-lg-6 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <section className="pb-3 text-left">
                                <h2 className="generic-title">S'INSCRIRE</h2>
                                <hr className="generic-hr ml-0"/>
                            </section>
                            <div className="row">
                                <div className="col-12 col-md-6 form-group">
                                    <label for="edit-firstname">Prénom</label>
                                    <input className="form-control" type="text" placeholder="Prénom" id="edit-firstname" name="firstname" ref={register({required: true})} />
                                    {
                                        errors.firstname &&
                                        <small className="text-danger">{errors.firstname?.message}</small>
                                    }
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <label for="edit-lastname">Nom</label>
                                    <input className="form-control" type="text" placeholder="Nom" id="edit-lastname" name="lastname" ref={register({required: true})} />
                                    {
                                        errors.lastname &&
                                        <small className="text-danger">{errors.lastname?.message}</small>
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="edit-mail">Adresse mail</label>
                                <input className="form-control" type="email" placeholder="mail@exemple.fr" id="edit-mail" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                                {
                                    errors.username &&
                                    <small className="text-danger">{errors.username?.message}</small>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="edit-phone">Numéro de téléphone</label>
                                <input className="form-control" type="text"
                                       id="edit-phone" name="phone"
                                       ref={register({required: true, pattern: /^\S+@\S+$/i})}/>
                                {
                                    errors.phone &&
                                    <small className="text-danger">{errors.phone?.message}</small>
                                }
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 form-group">
                                    <label for="edit-firstname">Mot de passe</label>
                                    <input className="form-control" type="password" placeholder="········" id="edit-firstname" name="password" ref={register({required: true})} />
                                    {
                                        errors.password &&
                                        <small className="text-danger">{errors.password?.message}</small>
                                    }
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <label for="edit-lastname">Confirmer le mot de passe</label>
                                    <input className="form-control" type="password" placeholder="········" id="edit-lastname" name="confirm_password" ref={register({required: true, validate: value => value === password.current || "Les mots de passes ne sont pas identiques"})} />
                                    {
                                        errors.confirm_password &&
                                        <small className="text-danger">{errors.confirm_password?.message}</small>
                                    }
                                </div>
                            </div>
                            <div className="form-group my-3">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="edit-is-professional"
                                        name="professional"
                                        ref={register()}
                                        onClick={() => setProfessional(!professional)}
                                        checked={professional}
                                        onChange={() => {}}
                                    />
                                    <label className="custom-control-label" for="edit-is-professional">Je suis un professionnel</label>
                                </div>
                            </div>

                            {
                            professional && (
                                <>
                                    <div className="row">
                                        <div className="col-12 col-md-6 form-group">
                                            <label for="occupation">Type de profession</label>
                                            <select className="form-control" id="occupation" name="occupation" ref={register({ required: true })}>
                                                <option value="1">Restauration</option>
                                                <option value="2">Hotêlerie</option>
                                                <option value="3">Épicerie</option>
                                                <option value="4">Traiteur</option>
                                                <option value="5">Café / Bar / Pub</option>
                                                <option value="6">Autre</option>
                                            </select>
                                            {
                                                errors.occupation &&
                                                <small className="text-danger">{errors.occupation?.message}</small>
                                            }
                                        </div>
                                        <div className="col-12 col-md-6 form-group">
                                            <label for="companyName">Raison social</label>
                                            <input className="form-control" type="text" placeholder="Raison sociale" name="companyName" ref={register({required: true})} />
                                        </div>
                                            {
                                                errors.companyName &&
                                                <small className="text-danger">{errors.companyName?.message}</small>
                                            }
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 form-group">
                                            <div className="form-group">
                                                <label for="edit-pro-companySiret">Numéro de Siret</label>
                                                <InputMask
                                                    mask="999 999 999 99999"
                                                    className="form-control"
                                                    id="edit-pro-companySiret"
                                                    name="siret"
                                                    placeholder="000 000 000 00000"
                                                    type="text"
                                                    ref={register({required: true})}
                                                />
                                                {
                                                    errors.siret &&
                                                    <small className="text-danger">{errors.siret?.message}</small>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 form-group">
                                            <div className="form-group">
                                                <label for="edit-pro-companyVAT">Numéro de TVA (optionnel)</label>
                                                <InputMask
                                                    mask="FR99 999999999"
                                                    className="form-control"
                                                    id="edit-pro-companyVAT"
                                                    name="tvaIntra"
                                                    placeholder="FR00 000000000"
                                                    type="text"
                                                    ref={register({required: false})}
                                                />
                                                {
                                                    errors.tvaIntra &&
                                                    <small className="text-danger">{errors.tvaIntra?.message}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="form-group text-right">
                                <button className="btn btn-primary" type="submit">S'inscrire</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-none d-lg-block h-100 right-side" style={imageBgStyle}>
                </div>
            </div>
        </div>
    );

})
