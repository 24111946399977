import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD, BASE_URL_PHOTO_VENDEUR} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import HomeImg from "../assets/images/la-coterie-francaise-accueil.jpg";

import HomeImg1 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-home-16-31052021.jpg";
import HomeImg2 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-artisans-eric-serano.jpg";
import HomeImg3 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-iphone.jpg";
import HomeImg4 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-cooking-16-31052021.jpg";
import HomeImg5 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-instagram-28-01062021.jpg";
import PhoneImg from "../assets/images/La-Coterie-Francaise-Image-de-application.png";
import Title from '../components/Title';
import OrderStepsInfo from "../components/OrderStepsInfo";
import Footer from '../components/Footer';
import SliceItemVendors from '../components/SliceItemVendors';
import Instakot from '../components/Instakot';
import LogoLightImgGoogle from '../assets/images/lacoteriefrancaise_google-play.png';
import LogoLightImgApple from '../assets/images/lacoteriefrancaise_apple-store.png';
import Faq from "../components/Faq";
import {HOME_API_URL } from '../utils/api' 
import PL1Img from "../assets/images/Photo-du-fondateur-de-La-Coterie-Francaise-1024x587.jpeg";
import PL1Img2 from "../assets/images/Livraisonprofessionnel2.jpg";
import {Helmet} from "react-helmet-async";
import LogoImg from "../assets/images/La-Coterie-Francaise-dark.jpg";


function CommencerAccueil(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);
    const [randShops, setRandShops] = useState([]);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(HOME_API_URL);
                console.log("resp", resp);
                setRandShops(resp.data.randShops);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + HomeImg1 + ')',
    };

    var imageBgStyle2 = {
        backgroundImage: 'url(' + HomeImg2 + ')',
    };

    var imageBgStyle3 = {
        backgroundImage: 'url(' + HomeImg3 + ')',
    };

    var imageBgStyle4 = {
        backgroundImage: 'url(' + HomeImg4 + ')',
    };

    var imageBgStyle5 = {
        backgroundImage: 'url(' + HomeImg5 + ')',
    };

    var imagePhone = {
        backgroundImage: 'url(' + PhoneImg + ')',
    };

    const index = 1
    const link = "/commencer-profil"
    const brand = {
            picture : "La-Coterie-Francaise-Boucherie-en-ligne-instagram-11-31052021.jpg",
        name : "Boucherie de la ruche",
        description : "A votre adresse saisie, nous vous avons sélectionné les partenaires pouvant vous livrer en quelques heures. Faites-vous plaisir en soutenant un partenaire connecté.",
    }


    return (

        <div >

        <Helmet>
            <title>{"La Coterie Française | Artisans et Producteurs en ligne"}</title>
            <meta name="description" content={"Pour particuliers et restaurateurs : Application mobile spécialisée en viande rare, de terroir et d’excellence pour livraison nationale."}/>
        </Helmet>

            <div className="d-block d-lg-none d-md-none" >

                <picture className="d-block d-lg-none position-logo text-center">
                <img className="position-logo-dl "
                    src={LogoImg}
                    data-img={"url("+LogoImg+")"}
                    alt="Logo de La Coterie Française sur fond blanc"
                    loading="lazy"
                />
                </picture>

                <div className="commencer-accueil-text-dl">
                    <div>
                        <h1 className="title-of-h1-dl text-center">Particulier et Restaurateur</h1>
                        <p className="title-of-p-dl text-center">Application mobile spécialisée en viande rare, de terroir et d’excellence pour livraison nationale</p>
                        <div className="text-center"><a href="https://app.coterie-francaise.fr/" ><div className="text-center mt-1 btn btn-primary" >
                            Voir les produits
                        </div></a></div>
                        <p className="title-of-subp-dl text-center">#Traditionnelle #Halal #Casher</p>
                    </div>
                </div>
            </div>

            <div className="d-none d-lg-block d-md-block">

            <div style={imageBgStyle} className="commencer-accueil-image">
                <div className="commencer-accueil-text">
                    <div>
                        <h1 className="title-of-h1">Téléchargez l'application mobile</h1>
                        <p className="title-of-p">Application mobile spécialisée en viande rare, de terroir et d’excellence pour livraison nationale. Ne ratez plus jamais l’occasion de vous faire plaisir !</p>
                        <p className="title-of-subp">#Traditionnelle #Halal #Casher</p>
                        {/* <div >
                            <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link>
                        </div> */}
                                                <div className="center-content-appstore">
                            <picture >
                                <a href={"https://play.google.com/store/apps/details?id=com.bwade.MyMeatApp&hl=fr&gl=US"}><img src={LogoLightImgGoogle} alt=" La Coterie Française sur Google Play" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                            <picture >
                                <a href={"https://apps.apple.com/fr/app/la-coterie-fran%C3%A7aise/id1385886926#?platform=iphone"}><img src={LogoLightImgApple} alt="La Coterie Française sur Apple Store" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Title 
                title={"QU'EST-CE QU'UNE COTERIE ?"}
                subtitle="La définition"
            />

            <div style={imageBgStyle2} className="commencer-accueil-image-def">
                <div className="commencer-accueil-text-def">
                    <div>
                    <h2 className="title-of-h2-def">Une Coterie est une association entre certains groupes d'individus unis par un intérêt commun.</h2>
                    <p className="title-of-p">Mieux manger et mieux vivre ensemble !</p>
                        <div >
                            <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link>
                        </div>
                    </div>
                </div>
            </div>  */}

            {/* <Title 
                title={"DEUX ARTISANS À LA UNE"}
                subtitle="Des artisans passsionnés et engagés"
            />
            
            <div className="row StyleShopAccueil"> {randShops.map((randShop, index) => {
                        let link = "";
                        let imgLink = "";

                        if (randShop.company?.seller?.type?.id === 2) {
                            link = "/eleveur/";
                        //    imgLink = EleveurDefaultImg;
                        } else {
                            link = "/boucherie/";
                        //    imgLink = BoucherieDefaultImg;
                        }
                        link += randShop.city.slug +"/"+ randShop.slug;

                        if (randShop.specificity && randShop.specificity.id !== 1) {
                            link = "/" + randShop.specificity.slug + link;
                        }

                        if (randShop.picture) {
                        //    imgLink = BASE_URL_PHOTO_HOME+randShop.picture;
                        }

                        return (
                            <div key={index} className="col-12 col-md-6 mb-4" id={brand}>
                                <SliceItemVendors
                                    image={BASE_URL_PHOTO_VENDEUR + brand.picture}
                                    altImage={"Photo de " + randShop.name + " | Boucherie en ligne"}
                                    title={randShop.name}
                                    description={randShop.description.length > 200 ? randShop.description.substring(0, 197) + "..." : randShop.description}
                                    btnTitle="Commander"
                                    link={link}
                                    small
                                />
                            </div>
                        );
                    })}</div> */}


            <Link to="/livraison-de-la-coterie-francaise"><div className="Menu-Accueil-All d-lg-none d-md-none">
                <div className="Menu-Accueil-Element">Comment livrons nous
                    <div className="Menu-Accueil-Content">L'excellence en -24h</div>
                    <div><img className="Menu-Accueil-Pic2" loading="lazy" src={PL1Img2} alt="Fondateur de La Coterie Française"/></div>
                </div>
                <div className="Menu-Accueil-Go"> 
                    <svg className="Menu-Accueil-Fleche" viewBox="0 0 17.29 25.05" xmlns="http://www.w3.org/2000/svg"><path d="m0 0 12.52 12.52-12.52 12.53h4.76l12.53-12.53-12.53-12.52z" fill="#090400"/></svg>
                </div>
            </div></Link>

            <Link to="/histoire-de-la-coterie-francaise"><div className="Menu-Accueil-All d-lg-none d-md-none">
                <div className="Menu-Accueil-Element">Découvrez l'histoire
                    <div className="Menu-Accueil-Content">Une histoire simple</div>
                    <div><img className="Menu-Accueil-Pic1" loading="lazy" src={PL1Img} alt="Fondateur de La Coterie Française"/></div>
                </div>
                <div className="Menu-Accueil-Go"> 
                    <svg className="Menu-Accueil-Fleche" viewBox="0 0 17.29 25.05" xmlns="http://www.w3.org/2000/svg"><path d="m0 0 12.52 12.52-12.52 12.53h4.76l12.53-12.53-12.53-12.52z" fill="#090400"/></svg>
                </div>
            </div></Link>

            {/* <div style={imageBgStyle4} className="commencer-accueil-image">
                <div className="commencer-accueil-text">
                    <div>
                        <h2 className="title-of-h2">Téléchargez l'application mobile</h2>
                        <p className="title-of-p">Vivez une expérience unique sur mobile. Ne ratez plus jamais l’occasion de vous faire plaisir !</p>
                        <div className="center-content-appstore">
                            <picture >
                                <a href={"https://play.google.com/store/apps/details?id=com.bwade.MyMeatApp&hl=fr&gl=US"}><img src={LogoLightImgGoogle} alt=" La Coterie Française sur Google Play" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                            <picture >
                                <a href={"https://apps.apple.com/fr/app/la-coterie-fran%C3%A7aise/id1385886926#?platform=iphone"}><img src={LogoLightImgApple} alt="La Coterie Française sur Apple Store" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                        </div>
                    </div>
                </div>
            </div> */}

            <div style={imageBgStyle2} className="commencer-accueil-image-def d-lg-none d-md-none">
                <div className="commencer-accueil-text-def">
                    <div>
                    <h2 className="title-of-h2-def">Vous êtes un artian ou producteur engagè et passionné ? Portez-nous candidat !</h2>
                    <p className="title-of-p">Simplifiez l'accès à vos produits en livrant vos clients (et de nouveaux) à leur adresse.</p>
                        {/* <p className="title-of-p">Aspirez à une alimentation saine, de qualité et soucieuse de l’environnement avec de bons artisans et producteurs.</p> */}
                        <div >
                        <a href="https://info.coterie-francaise.fr/" className={"mt-1 btn btn-dark btn-primary"}>Devenir partenaire</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="news-banner" style={imageBgStyle5}>
                <div className="commencer-accueil-text-banner">
                    <div>
                        <h2 className="title-of-h1-banner">Abonnez-vous à la newsletter !</h2>
                        <p className="title-of-p-banner">Ne manquez pas nos actualités et promos de nos artisans, envoyés 2 fois par mois.</p>
                        <div className="form-group">
                            <input className="form-control" type="email" placeholder="mail@exemple.fr" id="edit-mail" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                        </div>
                        <div >
                            <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Valider</Link>
                        </div>
                    </div>
                </div>
            </div> */}

            <section className="container mb-4">
                <Faq/>
            </section>

            <Instakot/>
            
            <Footer/>

            </div>

        </div>
    );
}

export default withRouter(CommencerAccueil);
