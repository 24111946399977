import React from 'react';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import { HiX } from "react-icons/hi";

export default function ShareModal(props) {
    let location = null
    if (typeof window !== 'undefined') {
        location = window.location.href;
    }

    return (
    <div className="modal fade" id="shareModal" tabIndex="-1" aria-labelledby="shareSocialModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h5 className="modal-title" id="shareSocialModal">Partager la page</h5>
                    <button type="button" className="btn btn-light px-2" data-dismiss="modal" aria-label="Close">
                        <HiX/>
                    </button>
                </div>
                <div className="modal-body">
                    <FacebookShareButton onClick={() => {gaEventShare('Facebook')}}  className="mr-2" url={location}><FacebookIcon size={45} round={true} /></FacebookShareButton>
                    <span
                        className="mr-2"
                        onClick={() =>
                            {
                                if (typeof window !== 'undefined') {
                                    window.open('https://www.linkedin.com/shareArticle?mini=true&url='+ location );
                                    gaEventShare('Linkedin');
                                }
                            }
                        }
                    >
                        <LinkedinIcon size={45} round={true} />
                    </span>
                    <TwitterShareButton onClick={() => {gaEventShare('Twitter')}}  className="mr-2" url={location}><TwitterIcon size={45} round={true} /></TwitterShareButton>
                    <WhatsappShareButton onClick={() => {gaEventShare('Whatsapp')}}  className="mr-2" url={location}><WhatsappIcon size={45} round={true} /></WhatsappShareButton>
                    <EmailShareButton onClick={() => {gaEventShare('Email')}} className="mr-2" ><EmailIcon size={45} round={true} /></EmailShareButton>

                </div>
            </div>
        </div>
    </div>
    );
}

const gaEventShare = (method) => {
    if (typeof window !== 'undefined' && window !== undefined) {
        window.gtag('event', 'share', {
            method: method,
        });
    }
}
