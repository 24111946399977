import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';


function CommencerProfil(props) {

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);


    return (
        
        <div className="login">

            <div className="center-content-wiz"> 
            <div className="avatar-valide d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-valide d-lg-none" >1</div>
            <div className="avatar d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar d-lg-none" >2</div>
            <div className="avatar d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar d-lg-none d-none" >3</div>
            <div className="avatar d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar d-lg-none d-none" >4</div>
            <div className="avatar d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar d-lg-none d-none" >5</div>
            <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none d-none" >6</div>
            <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                {/* <div className="avatar-valide" >2</div>
                <div className="avatar" >...</div>
                <div className="avatar">7</div> */}
            </div>
            <div className="center-content-wiz-line pb-4"></div>


            <TitleSimple 
                subtitle={"Choisissez votre profil"}
                text="Nous permettons à des particuliers et des restaurateurs l’accès à des viandes d’exception, de terroir mais aussi respectueux de l’environnement. Allons-y !"
            />
            <div className="mb-3 center-content">
                <div className="btn-group btn-group-toggle btn-group-choix" data-toggle="buttons">
                    <Link to={"/commencer-adresse?profil=particulier"} className={"btn btn-dark py-2 col mr-1 ml-1 commencer-button-width"}>Particulier</Link>
                    {/* <Link to={"/commencer-adresse?profil=restaurateur"} className={"btn btn-dark py-2 col mr-1 ml-1 commencer-button-width"}>Restaurateur</Link> */}
                    <div className={"btn py-2 col mr-1 ml-1 commencer-button-width btn-desactivate"}>Restaurateur</div>
                </div>
            </div>
            <div className="center-content">
                <Link to="/">Annuler</Link>
            </div>

        </div>
    );
}

export default withRouter(CommencerProfil);
