import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";
import LogoMiniImg from '../assets/images/picto-fleche-bouton.svg'
import LogoMiniImgBis from '../assets/images/Cow_beef_set_hand_draw_vintage_engraving_style_black_and_white_clip_art_isolated_on_white_background.png'
import LogoMiniImgCut from '../assets/images/picto-fleche-bouton.svg'
import LogoMiniImgFlame from '../assets/icons/flame.svg'
import LogoMiniImgWine from '../assets/icons/path894.png'
import LogoMiniImgPic from '../assets/icons/g1237.png'

import LogoImg from "../assets/images/La-Coterie-Francaise-dark.jpg";
import LoginImgFleche from '../assets/images/picto-fleche-bouton.svg'


function RestaurantCarte(props) {

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);
    const [Races, setRaces] = useState([]);
    const [Cut_displayed, setCut_displayed] = useState([]);
    // const [isActive_cut, setActive] = useState(props.location.state[0].race.category.libelle);
    const [isActive_cut, setActive] = useState(props.location.state.filter(item => item.enabled == true && item.race.cuts.filter(item_b => item_b.actif == true).length > 0)[0].race.category.libelle);
    const [isTypeSearch, setTypeSearch] = useState('piece');

    const get_firstlibelle = () => {

        props.location.state.filter(item => item.enabled == true && item.race.cuts.filter(item_b => item_b.actif == true).length > 0)
        console.log("props.location.state -> ", props.location.state.filter(item => item.enabled == true && item.race.cuts.filter(item_b => item_b.actif == true).length > 0)[0].race.category.libelle)

    }

    const set_filter = () => {
        console.log("Set setActive, ", isActive_cut)
        console.log("Set setTypeSearch, ", isTypeSearch)
        //get_firstlibelle()
    }

    useEffect(() => {

        set_filter()

    }, [isActive_cut])

    const RetourPage = () => {
        props.history.goBack()
    }

    const displayRaces = () => {
        
        return props.location.state.map((race, index) => {

         //   console.log("Map sur -> ", race)

            if(race.enabled == true && isActive_cut == race.race.category.libelle && isTypeSearch == "race")
            {
            
            return (
                <Link to={{pathname: "/restaurant-race", state: race }}className={"new-link"}>
                    <div className="type_race_top" >
                        <div className='col-3 p-0 image-animal'>
                        <img src={'https://admin.coterie-francaise.fr/uploads/publications/' + race.race.image} className="picto-animal" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                        </div>
                        <div className='col-8 p-0 race-animal'>
                            <div className='flag-name mt-1'>
                                <img src={race.race.country.flag} className="img-flag" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                <div className="type_race">{race.race.category.libelle}</div>
                            </div>
                            <div className="type_race-lis">{race.race.libelle}</div>
                            {/* <div className="type_race_town">{race.race.country.libelle}</div> */}
                            <div className="type_race_town-dec">{race.race.description.slice(0,54) + "..." }</div>
                            {/* <div className="type_race_town-dec mt-1">Naissance : France<br/>Élevage : France<br/>Abattage : France</div> */}
                        </div>
                        <div className='col-1 p-0 image-animal'>
                            {/* <img  className="picto-down" src="assets/pictos/picto-fleche-bouton.svg" /> */}
                            <img src={LogoMiniImg} className="picto-down" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                        </div>
                    </div>
                </Link>
            )

        }
            
        }
    )}

    const displayCutsmenu = () => {  

        var Cut_displayed = []
        var Cut_displayed_Menu = []
        var Cuts = []
        
        props.location.state.map((item, index) => {
            item.race.cuts.map((item_b, index_b) => {
                if(item_b.actif == true) {Cuts.push(item_b.piece)}
            })
        })
        
    //    props.location.state = props.location.state.filter( item => item.enabled == true)
    
        Cuts.map((item_c, index_b) => {
            item_c['races'] = []
            
            props.location.state.filter( item => item.enabled == true).map((item_d, index) => {
                item_d.race.cuts.map((item_e, index) => {
                //  console.log("fdezfzef ", item_e.piece.category.slug,selector)
                    if(item_e.piece.id == item_c.id) {
                    //  console.log("on a )> ", item_c.libelle, "dans ", item_d.race.libelle)
                    item_c['races'].push(item_d)
                    item_c['display'] = false
                    Cut_displayed.push(item_c) 
                    }
                })
            })
        })

        Cut_displayed = Cut_displayed.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
            return acc.concat([current]);
            } else {
            return acc;
            }
        }, []);

        Cut_displayed.map((item_c, index_b) => {
            Cut_displayed_Menu.push(item_c.category.libelle) 
        })

        Cut_displayed_Menu = Cut_displayed_Menu.reduce((acc, current) => {
            const x = acc.find(item => item === current);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
            }, []);
                
        return Cut_displayed_Menu.map((type, index) => {

            return(
                <button className={"btn btn-choice mr-1 ml-1 mb-1 " + (type === isActive_cut ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => {setActive(type);}} >{type}</button>       
            )
        })
    }

    const displayCuts = () => {

        var Cut_displayed = []
        var Cut_displayed_Menu = []
        var Cuts = []
        
        props.location.state.map((item, index) => {
            item.race.cuts.map((item_b, index_b) => {
                if(item_b.actif == true) {Cuts.push(item_b.piece)}
            })
        })
        
        //  props.location.state = props.location.state.filter( item => item.race.category.libelle == isActive_cut && item.enabled == true)
    
        Cuts.map((item_c, index_b) => {
            item_c['races'] = []
            
            props.location.state.filter( item => item.enabled == true).map((item_d, index) => {
            item_d.race.cuts.map((item_e, index) => {
            //  console.log("fdezfzef ", item_e.piece.category.slug,selector)
                if(item_e.piece.id == item_c.id) {
                //  console.log("on a )> ", item_c.libelle, "dans ", item_d.race.libelle)
                item_c['races'].push(item_d)
                item_c['display'] = false
                Cut_displayed.push(item_c) 
                }
            })
            })
        })


    
        Cut_displayed = Cut_displayed.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
            return acc.concat([current]);
            } else {
            return acc;
            }
        }, []);

        Cut_displayed.map((item_c, index_b) => {
            Cut_displayed_Menu.push(item_c.category.libelle) 
        })

        Cut_displayed_Menu = Cut_displayed_Menu.reduce((acc, current) => {
            const x = acc.find(item => item === current);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
            }, []);
    
        Cut_displayed = Cut_displayed.sort(function (a, b) {
            return a.libelle.localeCompare(b.libelle);
        });
    
        console.log("Liste of Cut_displayed -> ", Cut_displayed)
        console.log("Liste of Cut_displayed_Menu -> ", Cut_displayed_Menu)

        

        return Cut_displayed.map((cut, index_b) => {

            if (isActive_cut == cut.category.libelle && isTypeSearch == "piece") {

                return (
                    <div>

                        <div to={{pathname: "/restaurant-race", state: cut.race }} className={"new-link"}>
                            <div className="type_race_top mb-0" >
                                <div className='col-3 p-0 image-animal'>
                                <img src={'https://admin.coterie-francaise.fr/uploads/publications/' + cut.location} className="picto-animal" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                </div>
                                <div className='col-9 p-0 race-animal'>
                                    {cut.category.libelle != "Autres" && <div className="type_race_nom-bis-light-cut">{cut.libelle + " " + cut.category.determinant + cut.category.libelle}</div>}
                                    {cut.category.libelle == "Autres" && <div className="type_race_nom-bis-light-cut">{cut.libelle}</div>}
                                    <div className="type_race_town-dec">{cut.description.slice(0,50) + "..." }</div>
                                </div>
                                {/* <div className='col-1 p-0 image-animal'>
                                    <img src={LogoMiniImg} className="picto-down" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                </div> */}
                            </div>
                        </div>

                        <div className="type_race_top_list mb-4" >
                            <div>
                                {false ? <p>Chargement</p> : displayRacesOfCuts(cut)}
                            </div>
                        </div>

                    </div>
                    

                )
            }

        })




    }

    const displayRacesOfCuts = (Cut_displayed) => {

        //   console.log("console -> ", Cut_displayed)
        
        return Cut_displayed.races.map((race, index_b) => {
            return (
                <div>
                    <div>
                    <Link to={{pathname: "/restaurant-race", state: race }} className={"new-link list_race"}>

                    { Cut_displayed.races.length > 1 && <div className='col-2 p-0 image-animal'>
                        <img src={'https://admin.coterie-francaise.fr/uploads/publications/' + race.race.image} className="picto-animal pb-2" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                    </div>}

                        <div className='pl-1'>

                            <div className='flag-name'>
                                <img src={race.race.country.flag} className="img-flag-light" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                <div className="type_race-lis">{race.race.libelle}</div>
                            </div>
                            {/* <div>
                                <div>
                                   <img src={LogoMiniImgWine} className="picto-down-logo" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                   <div>x 2</div>
                                </div>
                            </div> */}
                            <div className="type_race-des p-0 mr-2 mb-2">{race.race.description.slice(0,100) + "..."}</div>
                            {/* <div className="type_race-ori p-0 mt-1 mr-2 pb-2">Naissance : {race.naissanceCountry.libelle}<br/>Élevage : {race.elevageCountry.libelle}<br/>Abattage : {race.abattageCountry.libelle}</div> */}

                        </div>

                        <div className='col-1 p-0 image-animal'>
                            <img src={LogoMiniImg} className="picto-down" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                        </div>

                    </Link>
                </div>

                    {race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.price != 0 
                    && race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini != 0
                    && <div className={"type_race-des p-0 mr-2 mb-2 pl-1 font-price"}>
                        {race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini < race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMax && <div className={"lightpr"}>À partir de</div>}{(race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.price * race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini / 1000).toFixed(2) + '€ (' + race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini + 'g)'}
                        </div>}

                    {race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.price != 0 
                    && race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini == 0
                    && <div className={"type_race-des p-0 mr-2 mb-2 pl-1 font-price"}>{race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMini < race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammageMax && <div className={"lightpr"}>À partir de</div>}{(race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.price * race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammage / 1000).toFixed(2) + '€ (' + race.race.cuts.filter(item => Cut_displayed.id == item.piece.id)[0].piece.grammage + 'g)'}</div>}


                    { Cut_displayed.races.length != index_b+1 && <div className="line-sep pb-2"></div>}

                </div>

            )  
        })

    }

    return (
        
        <div className="d-block d-lg-none" >
            
            {/* <Link to={{state: props.location.state }} onClick={() => RetourPage()} > */}
            <div >
            <div className='header-style'>
                    {/* <img src={LogoMiniImg} className="picto-down-retour" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" /> */}
                    <p className="title-of-p-dl text-center mb-4 info-retour">La Carte</p>
                </div>
            </div>

            <div className={"pb-3 text_align"}>
                <Link to={{pathname: "/restaurant-conseil", state: props.location.state }} className={"mt-1 btn conseil-btn className-full-widh "}>Mes préférences</Link>
            </div>

            {/* <div className={"inline-button full-widh"}>
                <div className={"full-widh"}>
                    <div className={"full-widh"}>
                        <div className={"btn btn-choice  mb-1 full-widh " + ('piece' === isTypeSearch ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => {setTypeSearch('piece');}}>Par pièce</div>
                    </div>
                </div>
                <div className={"full-widh"}>
                    <div className={"full-widh"}>
                        <div className={"btn btn-choice mb-1 full-widh " + ('race' === isTypeSearch ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => {setTypeSearch('race');}} >Par race</div>
                    </div>
                </div>
            </div> */}
            

            <div className=" display_type pb-3">
                {false ? <p>Chargement</p> : displayCutsmenu()}
            </div>

            <div className="type_race_grid" >

                {false ? <p>Chargement</p> : displayCuts()}
                {false ? <p>Chargement</p> : displayRaces()}

            </div>

            <div className='pb-4'></div>

        </div>

    );
}

export default withRouter(RestaurantCarte); 
