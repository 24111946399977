import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';


function RestaurantSelectCartoucherie(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL + VERIFY_RESET_PASSWORD(account, token));
                console.log("resp", resp);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        if (values.password !== values.password2) {
            onError("Les mots de passe ne sont pas identiques");
        } else {
            handleRequest("post", BASE_URL + RESET_PASSWORD(account, token), values, config, (data, status) => {
                if (status === "Done") {

                    props.history.push("/login")
                } else if (status === "Error") {
                    switch (data.status) {
                        case 401:
                            onError("Votre adresse mail ou mot de passe est éronné.");
                            break;
                        case 400:
                            onError("Veuillez remplir tous les champs nécéssaires.");
                            break;
                        default:
                            onError(`Une erreur de type ${data.status} est survenue...`);
                    }
                    console.error("Error on login", data);
                }
            })
        }
    }

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };

    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            enterLeft: "animated enterLeft",
            exitRight: "animated exitRight",
            exitLeft: "animated exitLeft",
        },
        demo: true, // uncomment to see more
    });

    const setInstance = () => updateState({
        ...state,
    });

    const First = props => { return null}
    const Second = props => {return null}
    const Third = props => {return null}
    const Quatre = props => {return null}
    const Cinq = props => {return null}
    const Six = props => {return null}
    const Last = props => {return null}

    const SetConfessionTrad = () => {
        window.gtag('set', 'user_properties', {
            Confession: 'traditionnelle',
        });
    }

    const SetConfessionHalal = () => {
        window.gtag('set', 'user_properties', {
            Confession: 'halal',
        });
    }

    return (
        
        <div className="login">

            <TitleSimple 
                subtitle={"500"}
                text="Découvrez les origines de nos produits"
            />

            <div className="mb-3 center-content">
                <div className="btn-group btn-group-toggle btn-group-choix" data-toggle="buttons">
                <div >
                    <a href="https://app.coterie-francaise.fr/restaurant-accueil?id=430" className={"mt-1 btn btn-dark btn-primary thegoodwid btn-nodark"}>TATA la pizza</a>
                </div>
                <div >
                    <a href="https://app.coterie-francaise.fr/restaurant-accueil?id=430" className={"mt-1 btn btn-dark btn-primary thegoodwid btn-nodark"}>Les Mecs Au Camion</a>
                </div> 
                <div >
                    <a href="https://app.coterie-francaise.fr/restaurant-accueil?id=430" className={"mt-1 btn btn-dark btn-primary thegoodwid btn-nodark"}>Les Filous</a>
                </div> 
                <div >
                    <a href="https://app.coterie-francaise.fr/restaurant-accueil?id=430" className={"mt-1 btn btn-dark btn-primary thegoodwid btn-nodark"}>Azterren</a> 
                </div> 
                </div>
            </div>


        </div>
    );
}

export default withRouter(RestaurantSelectCartoucherie);
