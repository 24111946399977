import React, { useState } from 'react';
import Pagination from './Pagination/Pagination';
import useContextApp from '../hooks/useContextApp';
import { withRouter } from 'react-router-dom';
import ModalReview from './ModalReview';
import moment from "moment";

export default withRouter(function Reviews(props) {
    const [user] = useContextApp();
    const [ratingData, setRatingData] = useState({
        notes: {
            1: 0,
            2: 1,
            3: 2,
            4: 2,
            5: 0,
        },
        rateComments: [
            {name: "1"},
            {name: "2"},
            {name: "3"},
            {name: "4"},
            {name: "5"},
            {name: "6"},
            {name: "7"},
            {name: "8"},
            {name: "9"},
            {name: "10"},
            {name: "11"},
            {name: "12"},
            {name: "13"},
            {name: "14"},
            {name: "15"},
            {name: "16"},
            {name: "17"}
        ]
    })
    const [IpP, setIpP] = useState(0);
    const [showCreateReview, setShowCreateReview] = useState(false);

    React.useEffect(() => {
        const notes = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
        };
        const rateComments = [];

        if (props.reviews) {
            props.reviews.map((review) => {
                notes[review.note] += 1;
                const date = moment(review.date);
                rateComments.push({
                    name: review.displayName,
                    comment: review.content,
                    date: date.format("DD/MM/YYYY à HH:mm"),
                    note: review.note,
                    id: review.id,
                });
            });
        }
        setRatingData({notes, rateComments})
    }, [props.reviews, props.nbrNotes])

    const handleAddReview = () => {
        if (user.connected) {
            setShowCreateReview(true);
        } else {
            props.history.push("/login")
        }
    }

   return (
        <div className="reviews">
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3 left-part">
                    <div className="review-title mb-2">
                        AVIS CLIENTS {props.nbrNotes !== 0 && <span className="font-weight-light">({props.nbrNotes})</span>}
                    </div>
                    <div className="review-number mb-2">
                        <span className="font-weight-light">{props.avgNotes ? props.avgNotes?.toFixed(1) : "-.-"}/</span>5
                    </div>
                    <div className="review-average mb-1">
                        <Progressbar rate={1} percent={props.nbrNotes !== 0 ? (ratingData?.notes?.[1] / props.nbrNotes) * 100 : 0} />
                    </div>
                    <div className="review-average mb-1">
                        <Progressbar rate={2} percent={props.nbrNotes !== 0 ? (ratingData?.notes?.[2] / props.nbrNotes) * 100 : 0}/>
                    </div>
                    <div className="review-average mb-1">
                        <Progressbar rate={3} percent={props.nbrNotes !== 0 ? (ratingData?.notes?.[3] / props.nbrNotes) * 100 : 0}/>
                    </div>
                    <div className="review-average mb-1">
                        <Progressbar rate={4} percent={props.nbrNotes !== 0 ? (ratingData?.notes?.[4] / props.nbrNotes) * 100 : 0}/>
                    </div>
                    <div className="review-average mb-1">
                        <Progressbar rate={5} percent={props.nbrNotes !== 0 ? (ratingData?.notes?.[5] / props.nbrNotes) * 100 : 0}/>
                    </div>
                    <button className="btn btn-dark w-100 mt-2 text-medium" onClick={handleAddReview}>Je donne mon avis</button>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    {
                        props.nbrNotes !== 0 ?
                        <>
                            {
                                ratingData?.rateComments?.slice(IpP, IpP + 3)?.map(el => <ReviewItem
                                    name={el.name}
                                    description={el.comment}
                                    date={el.date}
                                    rate={el.note}
                                    key={el.id}
                                />)
                            }
                            <div className="mt-3">
                                <Pagination itemsPerPage={3} list={ratingData.rateComments} onChange={(d) => setIpP(d * 3)} />
                            </div>
                        </>
                        :
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <p>Je suis le premier à donner mon avis sur ce produit !</p>
                            <button className="btn btn-dark mt-2 text-medium" onClick={handleAddReview}>Je donne mon avis</button>
                        </div>
                    }
                </div>
            </div>
            <ModalReview show={showCreateReview} onHide={() => setShowCreateReview(false)} />
        </div>
    );
})


function Progressbar(props) {
    return (
        <div className="row no-gutters align-items-center">
            <div className="col-auto pr-2">
                <strong>{props.rate}</strong>
            </div>
            <div className="col">
            <div className="progress">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{width: props.percent + '%'}}
                    aria-valuenow={props.percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                />
            </div>
            </div>

        </div>
    );
}

function ReviewItem(props){
    return (
        <div className="review-item py-2">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="review-name text-medium mr-2">{props.name}</div>
                    <Rating value={props.rate}/>
                </div>
                <div className="review-date">
                    {props.date}
                </div>
            </div>
            <p className="mb-0 mt-2">
                {props.description}
            </p>
        </div>
    );
}

function Rating(props) {
    const rating = Math.round(props.value);
    var rates = [];
    for (var i = 0; i < rating; i++) {
        rates.push(<span className='rate-circle rate-circle-md mr-s1' key={i}></span>);
    }
    if (rating < 5) {
        for (var j = rating; j < 5; j++) {
            rates.push(<span className='rate-circle-empty rate-circle-md mr-s1' key={j}></span>);
        }
    }
    return rates;
}
