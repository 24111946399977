import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import StepWizard from 'react-step-wizard';


function CommencerProfil(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    const [addressCoodinates, setAddressCoodinates] = useState({
        latitude: null,
        longitude: null,
    });
    const [addressData, setAddressData] = useState(null)


    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        //console.log("selected address", description);

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setAddressCoodinates({
                    latitude: lat,
                    longitude: lng,
                })

                props.history.push("/commencer-confession"+window.location.search+"&lat="+lat+"&lng="+lng);

            })
            .catch((error) => {

                //console.log("😱 Error: ", error);
            
            });
    };

    const renderSuggestions = () =>
    data.map((suggestion) => {
        //console.log("suggestion", suggestion);
        const {
            place_id,
            structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
            <li key={place_id} onClick={handleSelect(suggestion)}>
                <strong>{main_text}</strong> <small>{secondary_text}</small>
            </li>
        );
    });

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: ["fr"]}
        },
        debounce: 300,
    });

    const [addressVerified, isAddressVerified] = useState(false);


    const handleInput = (e) => {
        // Update the keyword of the input element
        isAddressVerified(false);
        setValue(e.target.value);
    };

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL + VERIFY_RESET_PASSWORD(account, token));
                console.log("resp", resp);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };


    return (
        
        <div className="login">

            <div className="pt-3 pr-5 pl-5 center-content-wiz"> 
                <div className="avatar-passe d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-passe d-lg-none" >1</div>
                <div className="avatar-valide d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar-valide d-lg-none" >2</div>
                <div className="avatar d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar d-lg-none d-none" >3</div>
                <div className="avatar d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar d-lg-none d-none" >4</div>
                <div className="avatar d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar d-lg-none d-none" >5</div>
                <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none d-none" >6</div>
                <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                    {/* <div className="avatar-valide" >2</div>
                    <div className="avatar" >...</div>
                    <div className="avatar">7</div> */}
                </div>
            <div className="center-content-wiz-line pb-4"></div>

            <TitleSimple 
                subtitle={"Choisissez adresse de livraison"}
                text="Vous avez des producteurs et artisans géniaux à côté de chez vous ! Dites-nous dans quel coin de la France vous habitez, et nous vous proposerons des produits  locaux en priorité."
            />

            <div className="form-group mb-2 commencer-adresse-tmp">
                {/* <label htmlFor="payment-address">Entrez votre adresse</label> */}
                <div className="address-search-wrapper" ref={ref} >
                    <input
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        className="form-control"
                        placeholder="ex. 15 Rue de la paix 31000 Toulouse"
                        type="text"
                        id="payment-address"
                        autoComplete="off"
                    />
                    {status === "OK" && <ul className="result-list commencer-adresse-prop">{renderSuggestions()}</ul>}
                </div>
            </div>
            <div className="center-content">
                <Link to="/">Annuler</Link>
            </div>

        </div>
    );
}

export default withRouter(CommencerProfil);
