//import { Button } from 'bootstrap';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Checkout from '../components/Checkout';
import Title from '../components/Title';
import useCart from '../hooks/Cart/useCart';
import useContextApp from '../hooks/useContextApp';
import useQuantity from '../hooks/useQuantity';
import Faq from "../components/Faq";

import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';

export default withRouter(function Cart(props) {

    const [state, actions] = useCart();

    useLayoutEffect(() => {
        console.log('PANIER', state.cart)

        if (typeof window !== 'undefined' && window !== undefined) {
            const gaItems = [];
            state.cart.map((product) => {
                gaItems.push({
                    item_id: product.id,
                    item_name: product.product.title,
                    affiliation: product.shop.name,
                    item_brand: product.product?.brand?.name,
                    item_category: product?.product?.category?.libelle,
                    item_variant: product.product.grammage,
                    price: product.price,
                    currency: 'EUR',
                    quantity: product.quantity,
                });
            })

            window.gtag('event', 'view_cart', {
                currency: 'EUR',
                items: gaItems,
                value: 0
            });
        }

    });

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };

    function Rating(props) {
        const rating = Math.round(props.value);
        var rates = [];
        for (var i = 0; i < rating; i++) {
            rates.push(<span className='rate-circle mr-s1' key={i}></span>);
        }
        if (rating < 5) {
            for (var j = rating; j < 5; j++) {
                rates.push(<span className='rate-circle-empty mr-s1' key={j}></span>);
            }
        }
        return rates;
    }

    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const [statebis, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            enterLeft: "animated enterLeft",
            exitRight: "animated exitRight",
            exitLeft: "animated exitLeft",
        },
        demo: true, // uncomment to see more
    });

    const setInstance = () => updateState({
        ...state,
    });

    const First = props => { return null}
    const Second = props => {return null}
    const Third = props => {return null}
    const Quatre = props => {return null}
    const Cinq = props => {return null}
    const Six = props => {return null}
    const Last = props => {return null}

    const handleOrder = () => {
        if (state.cart?.length) {
            props.history.push("/paiement");
        }
    }

    const linkToShop = React.useMemo(() => {
        let link = "/";

        if (state?.shop?.company?.seller?.type?.id === 2) {
            link = "/eleveur/";
        } else {
            link = "/boucherie/";
        }

        if (state?.shop?.city) {
            link += state.shop.city.slug +"/"+ state.shop.slug;
        }

        if (state.shop.specificity && state.shop.specificity.id !== 1) {
            link = "/" + state.shop.specificity.slug + link;
        }

        return link;
    }, [state.cart])

    return (
        <div className="cart">

            <div className="pt-3 pr-5 pl-5 center-content-wiz"> 
                <div className="avatar-passe d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-passe d-lg-none d-none" >1</div>
                <div className="avatar-passe d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar-passe d-lg-none d-none" >2</div>
                <div className="avatar-passe d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar-passe d-lg-none d-none" >3</div>
                <div className="avatar-passe d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar-passe d-lg-none" >4</div>
                <div className="avatar-valide d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar-valide d-lg-none" >5</div>
                <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none  d-none" >6</div>
                <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                    {/* <div className="avatar-valide" >2</div>
                    <div className="avatar" >...</div>
                    <div className="avatar">7</div> */}
                </div>
            <div className="center-content-wiz-line pb-4"></div>

            <TitleSimple 
                subtitle={"Ajustez vos quantités"}
                text={state.shop?.name || "Vous n'avez pas encore choisi de produits"}
            />

            <div className="center-content pb-3 ">
                <Link to="/">Annuler</Link>
            </div>

            <div className="container pb-4">
                {/* <div className="my-4">
                    <Title
                        title="VOTRE PANIER"
                        subtitle={state.shop?.name || "Vous n'avez pas encore choisi de produits"}
                        noMargin
                    />
                </div> */}
                <div className="row">
                    <div className="col-12 col-lg-8 mb-3">
                        {state.cart?.length > 0 &&
                            <Link to={linkToShop} className="btn py-1 mb-3 btn-outline-dark d-none d-lg-none">Retour à la boutique</Link>
                        }
                        <hr className="dark-thin mt-0 mb-2" />
                        {
                            state.cart?.length ?
                            state.cart?.map(el => {
                                return (
                                    <React.Fragment key={el._uid}>
                                        <CartItem product={el} CartActions={actions} />
                                        <hr className="dark-thin mb-0 mt-2" />
                                    </React.Fragment>
                                )
                            })
                            :
                            <p>Pas de produits trouvés</p>
                        }

                    </div>
                    <div className="col-12 col-lg-4">
                        <Checkout />
                        <button className="btn py-2 mb-3 btn-primary w-100" onClick={handleOrder}>COMMANDER</button>
                    </div>
                </div>
            </div>
        </div>
    );
})



function CartItem(props) {
    const [config] = useContextApp();
    const {quantity, price, priceWithoutPromotion, setQuantity} = useQuantity(props.product);
    const [remove, setRemove] = useState(false);
    const prices = React.useMemo(() => {
        if (config?.user?.customer?.professional) {
            return {
                price: price * (1 - props.product?.product?.tvaRate?.rate),
                priceWithoutPromotion: priceWithoutPromotion / (1 + props.product?.product?.tvaRate?.rate)
            }
        }
        return {
            price: price,
            priceWithoutPromotion: priceWithoutPromotion
        }
    }, [price, priceWithoutPromotion, config?.user?.customer?.professional])

    const handleQuantity = (data) => {
        const ret = setQuantity(data);

        if (!ret) {
            setRemove(true);
            return
        } else if (remove) {
            setRemove(false);
        }

        props.CartActions.updateItem({
            ...props.product,
            ...ret
        })
    }

    const handleDelete = () => {
        props.CartActions.removeItem(props.product?._uid);
    }

    return (
        <div className="product-cart-row">
            <div className="row py-2">
                <div className="order-1 order-md-1 col-8 col-md-5 border-right-col">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="min80">
                            <div className="d-inline-block">
                                {
                                    props.product?.product?.brand &&
                                    <span className="brand">{props.product?.product?.brand?.name}</span>
                                }
                                <span className="title">{props.product?.product?.title} • {props.product?.product?.category?.libelle}</span>
                            </div>
                            <div className="d-flex align-items-center mb-1">
                                <div className="weight mr-1">
                                    {
                                        props.product?.product?.unitProduct ?
                                        `${props.product?.product?.unitPersonsNumber * props.product?.parts} (${props.product?.product?.unitProductWeight * props.product?.parts} kg)`
                                        :
                                        `${props.product?.product?.grammage * props.product?.parts} kg`
                                    }
                                </div>
                                <div className="people">
                                    {props.product?.product?.unitPersonsNumber * props.product?.parts} pers.
                                </div>
                            </div>
                            {props.product?.preparationTime > 0 &&
                                <p>Temps de préparation : {props.product?.preparationTime} jour(s)</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="order-3 order-md-2 col-12 col-md-4 border-right-col mt-2 mt-md-0">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="h-100 d-flex justify-content-between align-items-center px-xl-2 w-100">
                            <button className="btn btn-outline-dark more-or-less" onClick={() => handleQuantity(-1)}>
                                -
                            </button>
                            <div className="btn">
                                {quantity}
                            </div>
                            <button className="btn btn-outline-dark more-or-less" onClick={() => handleQuantity(1)}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="order-2 order-md-3 col-4 col-md-3">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        {
                            !remove ?
                            <div className="text-center">
                                <div className="text-primary font-weight-medium">
                                    {prices.price?.toFixed(2)} €
                                    {
                                        config?.user?.customer?.professional &&
                                        <span className="font-weight-light">&nbsp;HT</span>
                                    }
                                </div>
                                {/*{
                                    props.promotion &&
                                    <div className="font-weight-medium">
                                        <del>
                                            {prices.priceWithoutPromotion.toFixed(2)} €
                                            {
                                                config?.user?.customer?.professional &&
                                                <span className="font-weight-light">&nbsp;HT</span>
                                            }
                                        </del>
                                    </div>
                                }*/}
                            </div>
                            :
                            <button className="btn btn-primary" onClick={handleDelete}>Supprimer</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
