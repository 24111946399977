import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from "react-input-mask";
import { withRouter } from 'react-router-dom';
import useContextApp from '../../hooks/useContextApp';
import useRequest from '../../hooks/useRequest';
import { API_ACCOUNT, API_UPDATE_ACCOUNT } from '../../utils/api';

export default withRouter(function Informations(props) {
    const password = useRef({});
    const [handleRequest] = useRequest();
    const [config, {setUser}] = useContextApp();
    const { register, handleSubmit, errors, watch } = useForm();
    password.current = watch("password", "");

    const onSubmit = data => {
        handleRequest("put", API_UPDATE_ACCOUNT, data, config, (data, status) => {
            if (status === "Done") {
                setUser(data);
            } else if (status === "Error") {
                console.error(data);
            }
        })
    };
    //console.log(errors);

    React.useEffect(() => {
        handleRequest("get", API_ACCOUNT, {}, config, (data, status) => {
            if (status === "Done") {
                setUser(data);
                //console.log(data.customer)
            } else if (status === "Error") {
                //console.error(data);
            }
        })
        // eslint-disable-next-line
    }, [])

    const isProfessional = config.user?.customer?.professional;

    return(
        <>
            <h3>Modifier mon compte</h3>
            <hr className="mt-2 mb-3"/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-md-6 form-group">
                        <label for="firstname">Prénom</label>
                        <input className="form-control" type="text" defaultValue={config.user?.customer?.firstName} placeholder="Prénom" id="firstname" name="firstname" ref={register({required: true})} />
                        {
                            errors.firstname &&
                            <small className="text-danger">{errors.firstname?.message}</small>
                        }
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label for="lastname">Nom</label>
                        <input className="form-control" type="text" defaultValue={config.user?.customer?.lastName} placeholder="Nom" id="lastname" name="lastname" ref={register({required: true})} />
                        {
                            errors.lastname &&
                            <small className="text-danger">{errors.lastname?.message}</small>
                        }
                    </div>
                </div>

                <div className={"form-group"}>
                    <label htmlFor="phone">Numéro de téléphone</label>
                    <input mask="99 99 99 99 99" className="form-control" id="phone" name="phone" defaultValue={config.user?.customer?.phone} placeholder="0000000000" ref={register({required: true})}/>
                    {
                        errors.phone &&
                        <small className="text-danger">{errors.phone?.message}</small>
                    }
                </div>

                <div className={"form-group" + (!isProfessional ? " mb-3" : "")}>
                    <label for="username">Adresse mail (Identifiant de connexion)</label>
                    <input className="form-control" type="email" defaultValue={config.user?.email} placeholder="mail@exemple.fr" id="username" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                    {
                        errors.username &&
                        <small className="text-danger">{errors.username?.message}</small>
                    }
                </div>

                {
                isProfessional && (
                    <>
                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <label for="occupation">Type de profession</label>
                                <select className="form-control" id="occupation" defaultValue={config.user?.customer?.occupation?.id} name="occupation" ref={register({ required: true })}>
                                    <option value="1">Restauration</option>
                                    <option value="2">Hotêlerie</option>
                                    <option value="3">Épicerie</option>
                                    <option value="4">Traiteur</option>
                                    <option value="5">Café / Bar / Pub</option>
                                    <option value="6">Autre</option>
                                </select>
                                {
                                    errors.occupation &&
                                    <small className="text-danger">{errors.occupation?.message}</small>
                                }
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label for="companyName">Raison social</label>
                                <input className="form-control" type="text" defaultValue={config.user?.customer?.companyName} placeholder="Raison sociale" name="companyName" ref={register({required: true})} />
                            </div>
                                {
                                    errors.companyName &&
                                    <small className="text-danger">{errors.companyName?.message}</small>
                                }
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <div className="form-group">
                                    <label for="edit-pro-companySiret">Numéro Siret</label>
                                    <InputMask
                                        mask="999 999 999 99999"
                                        className="form-control"
                                        id="edit-pro-companySiret"
                                        name="siret"
                                        placeholder="000 000 000 00000"
                                        type="text"
                                        defaultValue={config.user?.customer?.siret}
                                        ref={register({required: true})}
                                    />
                                    {
                                        errors.siret &&
                                        <small className="text-danger">{errors.siret?.message}</small>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <div className="form-group">
                                    <label for="edit-pro-companyVAT">Numéro de TVA (optionnel)</label>
                                    <InputMask
                                        mask="FR99 999999999"
                                        className="form-control"
                                        id="edit-pro-companyVAT"
                                        name="tvaIntra"
                                        defaultValue={config.user?.customer?.tvaIntra}
                                        placeholder="FR00 000000000"
                                        type="text"
                                        ref={register({required: false})}
                                    />
                                    {
                                        errors.tvaIntra &&
                                        <small className="text-danger">{errors.tvaIntra?.message}</small>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="form-group text-right">
                    <button className="btn btn-primary" type="submit">Enregistrer les modifications</button>
                </div>
            </form>
        </>
    );
})
