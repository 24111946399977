import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";
import {HOME_API_URL, BASE_URL_PHOTO_HOME, BASE_URL_PHOTO_VENDEUR,BASE_URL_NEARSHOP } from '../utils/api'

import HowStep from "../components/HowStep";
import SliceItemVendors from '../components/SliceItemVendors';
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';

import PL1Img from "../assets/images/Photo-du-fondateur-de-La-Coterie-Francaise-1024x587.jpeg";
import PL2Img from "../assets/images/rencontre-eleveur.jpeg";
import PL3Img from "../assets/images/La-Coterie-Francaise-Une-prise-de-conscience.jpeg"; 
import PL4Img from "../assets/images/boucher-partenaire.jpeg"; 
import HomeImg from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-instagram-16-31052021.jpg";
import Footer from '../components/Footer';
import Instakot from '../components/Instakot';
import HomeImg2 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-instagram-60-01062021.jpg";


function CommencerHistoire(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL_NEARSHOP+window.location.search);
                console.log("resp", resp);
                setShops(resp.data);

            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        if (values.password !== values.password2) {
            onError("Les mots de passe ne sont pas identiques");
        } else {
            handleRequest("post", BASE_URL + RESET_PASSWORD(account, token), values, config, (data, status) => {
                if (status === "Done") {

                    props.history.push("/login")
                } else if (status === "Error") {
                    switch (data.status) {
                        case 401:
                            onError("Votre adresse mail ou mot de passe est éronné.");
                            break;
                        case 400:
                            onError("Veuillez remplir tous les champs nécéssaires.");
                            break;
                        default:
                            onError(`Une erreur de type ${data.status} est survenue...`);
                    }
                    console.error("Error on login", data);
                }
            })
        }
    }

    var imageBgStyle2 = {
        backgroundImage: 'url(' + HomeImg2 + ')',
    };

    const index = 1
    const link = "#"
    const brand = {
            picture : "La-Coterie-Francaise-Boucherie-en-ligne-instagram-10-31052021.jpg",
        name : "Boucherie de la ruche",
        description : "A votre adresse saisie, nous vous avons sélectionné les partenaires pouvant vous livrer en quelques heures. Faites-vous plaisir en soutenant un partenaire connecté.",
    }

    var imageBgStyle = {
        backgroundImage: 'url(' + HomeImg + ')',
    };

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };

    function Rating(props) {
        const rating = Math.round(props.value);
        var rates = [];
        for (var i = 0; i < rating; i++) {
            rates.push(<span className='rate-circle mr-s1' key={i}></span>);
        }
        if (rating < 5) {
            for (var j = rating; j < 5; j++) {
                rates.push(<span className='rate-circle-empty mr-s1' key={j}></span>);
            }
        }
        return rates;
    }

    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            enterLeft: "animated enterLeft",
            exitRight: "animated exitRight",
            exitLeft: "animated exitLeft",
        },
        demo: true, // uncomment to see more
    });

    const setInstance = () => updateState({
        ...state,
    });

    const First = props => { return null}
    const Second = props => {return null}
    const Third = props => {return null}
    const Quatre = props => {return null}
    const Cinq = props => {return null}
    const Six = props => {return null}
    const Last = props => {return null}

    const displayShops = () => {
        return shops.map((shop, index) => {

            // let link = "/"
            // if (confession !== undefined && confession !== null && confession.id !== 1) {
            //     link += confession.slug + "/label/";
            // } else {
            //     link += "label/";
            // }

            // link += brand.regions[0].slug + "/" + brand.slug;

            let link = "";
                        let imgLink = "";

                        if (shop[0].company?.seller?.type?.id === 2) {
                            link = "/eleveur/";
                          //  imgLink = EleveurDefaultImg;
                        } else {
                            link = "/boucherie/";
                          //  imgLink = BoucherieDefaultImg;
                        }
                        link += shop[0].city.slug +"/"+ shop[0].slug;

                        if (shop[0].specificity && shop[0].specificity.id !== 1) {
                            link = "/" + shop[0].specificity.slug + link;
                        }

            console.log("shop.name ->", shop[0])

            return(
                <div className="col-12 col-md-4 mb-4 VendorListCardHead">
                <div className="VendorListCard">
                    <div className="ConfessionDeBoutique">Boucherie {shop[0].specificity.libelle} à {parseInt(shop.distance).toFixed(1)+"km"}</div>
                    {/* <div><div>Boeuf</div><div>Agneau</div></div> */}
                    <div className="title-shop"><div className="name-shop">{shop[0].name}</div>
                    </div>
                    <div className="product-subline">
                        <div rel="schema:aggregateRating">
                            <div typeof="schema:AggregateRating">
                                    <span property="schema:ratingValue" content={shop[0].avgNotes} className="mr-1">
                                        <Rating value={shop[0].avgNotes}/>
                                    </span>
                                    <span property="schema:reviewCount" content={shop[0].notesCounter}>{shop[0].notesCounter} avis</span>
                            </div>
                        </div>
                    </div>
                    <div>{shop[0].address}</div>
                    {/* <div>31000 Toulouse - Occitanie</div> */}
                    <div>{shop[0].city.code+" "+shop[0].city.libelleAcheminement+" - "+shop[0].city.department.region.name}</div>
                    <div className="voir-shop">
                        <Link to={link} className={"mt-1 btn btn-primary class-full-widh"}>Choisir</Link>
                    </div>
                </div> 
            </div>
            )
        })
    }

    return (
        
        <>

            <div style={imageBgStyle} className="commencer-accueil-image-hist">
                <div className="commencer-accueil-text-liv">
                    <div>
                        <h1 className="title-of-h1">Une histoire simple</h1>
                        <p className="title-of-p">Certaines rencontres changent nos vies ! La confiance, l'entraide et l'envie d'être juste nous mênent vers un monde où le vivre ensemble nous enrichit.
                        Ci-dessous les réels raisons aboutissant à la naissance de La Coterie Française. Pourquoi ne pas soutenir la démarche ? Passez commande.</p>
                    </div>
                </div>
            </div>

            <section className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5 order-2 order-md-1">
                        <img loading="lazy" src={PL1Img} alt="temps" className="w-100"/>
                    </div>
                    <div className="col-12 col-md-6 mb-5 order-1 order-md-2">
                        <div className="mb-3">
                            <h2 className="generic-title">L’environnement familial</h2>
                            <h3 className="generic-subtitle">Rien n'arrive pas hasard</h3>
                            <hr className="generic-hr ml-0"/>
                        </div>
                        <p>J’étais sur le point d’être papa d’un premier petit garçon, mon regard sur le monde commençait à changer, et l’envie de donner du sens à ma vie devenait de plus en plus évidente. Je suis le dernier d’une grande famille, je vivais, et vis toujours très simplement. Mes parents étaient artisans à Toulouse, mon père était bijoutier et ma mère tenait un restaurant. Ils sont aujourd’hui à la retraite, en tout cas ils essaient. A cette époque-là, mon enfance était centrée sur ma scolarité et sur de fortes valeurs culturelles. Je dois beaucoup à mes parents, frères et sœurs. Je pense avoir trouvé ma résilience au travail et l’envie d’aider les autres de ces racines.</p>
                        <div >
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5">
                        <div className="mb-3 text-md-right">
                            <h2 className="generic-title">Circonstance & Rencontres</h2>
                            <h3 className="generic-subtitle">Une écoute attentive</h3>
                            <hr className="generic-hr ml-0 ml-md-auto mr-md-0"/>
                        </div>
                        <p className="text-md-right">Juin 2017, je travaillais au sein de la direction d’une multinationale, leader dans l’ingénierie et l’innovation. Après une longue journée de travail, direction ma boucherie de proximité pour quelques courses. Ce n’était plus une surprise de devoir patienter 45 minutes avant d’être servi. Je voyais les personnes âgées, des femmes enceintes, et des enfants patienter debout, cela me gênait ! C’est à ce moment-là que je me suis intéressé au métier de l’artisan boucher et pris conscience de leurs difficultés au quotidien. Les activités du boucher sont méconnues du grand public. J’ai au travers de centaines de rencontres tendu l’oreille et compris de nombreuses attentes. Par la suite, j’ai eu l’opportunité de remonter la filière jusqu’à rencontrer des éleveurs d’ovins et de bovins de la région Occitanie. Des hommes et des femmes de valeurs. Les écouter touche au cœur et ne laisse personne insensible à leur passion, à leur engagement, à leur savoir-faire, et à leur respect envers leurs animaux. J’ai ainsi voulu quitter ma zone de confort pour promouvoir leurs compétences et passions exceptionnelles.</p>
                        <div className={"custom-side"}> 
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-5">
                        <img loading="lazy" src={PL2Img} alt="temps" className="w-100"/>
                    </div>
                </div>
            </section>
            <section className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5 order-2 order-md-1">
                        <img loading="lazy" src={PL3Img} alt="temps" className="w-100"/>
                    </div>
                    <div className="col-12 col-md-6 mb-5 order-1 order-md-2">
                        <div className="mb-3">
                            <h2 className="generic-title">Une prise de conscience</h2>
                            <h3 className="generic-subtitle">Faire quelque chose à son niveau</h3>
                            <hr className="generic-hr ml-0"/>
                        </div>
                        <p>La viande fait débat ! Le manque d’information et de connaissance en est, pour moi, la principale cause. Il est dit que l’élevage est aujourd’hui responsable de une grande partie des émissions de gaz à effet de serre. Quelle planète allons-nous laisser à nos enfants ? Se mobiliser c’est apporter sa pierre au mouvement du « mieux vivre ». Cela passe par transformer nos modes de production, de transformation, de distribution, de promotion et de consommation de ces biens et services. C’est tout une chaîne ! Sensibiliser le consommateur sur les bons réflexes du « mieux vivre » ensemble, c’est transmettre de la bienveillance. J’aime m’assurer de la bonne alimentation de mes parents, de mon fils et partager des moments culinaires entre amis sans détruire ma planète.</p>
                        <div >
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5">
                        <div className="mb-3 text-md-right">
                            <h2 className="generic-title">Une ambition</h2>
                            <h3 className="generic-subtitle">Être utile</h3>
                            <hr className="generic-hr ml-0 ml-md-auto mr-md-0"/>
                        </div>
                        <p className="text-md-right">Ensemble, nous pouvons facilement aider nos agriculteurs et artisans à mieux vivre de leur héritage, et faire un geste pour notre planète. La Coterie permet à chacun de s’engager à son niveau tout en se faisant plaisir.</p>
                        <div className={"custom-side"}> 
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-5">
                        <img loading="lazy" src={PL4Img} alt="temps" className="w-100"/>
                    </div>
                </div>
            </section>

            {/* <div className="news-banner" style={imageBgStyle}>
                <div className="commencer-accueil-text-banner">
                    <div>
                        <h2 className="title-of-h1-banner">Abonnez-vous à la newsletter !</h2>
                        <p className="title-of-p-banner">Ne manquez pas nos actualités et promos de nos artisans, envoyés 2 fois par mois.</p>
                        <div className="form-group">
                            <input className="form-control" type="email" placeholder="mail@exemple.fr" id="edit-mail" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                        </div>
                        <div >
                            <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Valider</Link>
                        </div>
                    </div>
                    
                </div>
            </div> */}

            <div style={imageBgStyle2} className="commencer-accueil-image-def d-lg-none mb-5 mt-5">
                <div className="commencer-accueil-text-def">
                    <div>
                    <h2 className="title-of-h2-def">Vous êtes un artian ou producteur engagè et passionné ? Portez-nous candidat !</h2>
                    <p className="title-of-p">Simplifiez l'accès à vos produits en livrant vos clients (et de nouveaux) à leur adresse.</p>
                        {/* <p className="title-of-p">Aspirez à une alimentation saine, de qualité et soucieuse de l’environnement avec de bons artisans et producteurs.</p> */}
                        <div >
                        <a href="https://info.coterie-francaise.fr/" className={"mt-1 btn btn-dark btn-primary"}>Devenir partenaire</a>
                        </div>
                    </div>
                </div>
            </div>

            <Instakot/>


            <Footer/>

        </>
    );
}

export default withRouter(CommencerHistoire);
