import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest from '../hooks/useRequest';
import { LOGIN_USER } from '../utils/api';

function Login(props) {
    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        values.username = values.username?.toLowerCase();

        handleRequest("post", LOGIN_USER, values, config, (data, status) => {
            if (status === "Done") {
                if (typeof window !== 'undefined' && window !== undefined) {
                    window.gtag('event', 'login', {
                        method: 'mail'
                    });
                }
                setToken(data.token);
                setUser(data.account);
                props.history.push("/")
            } else if (status === "Error") {
                switch (data.status) {
                    case 401:
                        onError("Votre adresse mail ou mot de passe est éronné.");
                        break;
                    case 400:
                        onError("Veuillez remplir tous les champs nécéssaires.");
                        break;
                    default:
                        onError(`Une erreur de type ${data.status} est survenue...`);
                }
                console.error("Error on login", data);
            }
        })
    }

    return (
        <div className="login">
            <div className="row no-gutters h-100">
                <div className="col-12 col-lg-6 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3">
                        <form className="col-12 col-xl-8" onSubmit={handleSubmit(toSubmit)}>
                            <section className="pb-3 text-left">
                                <h2 className="generic-title">CONNEXION</h2>
                                <hr className="generic-hr ml-0"/>
                            </section>
                            {
                                error &&
                                <div className="p-2 bg-danger mb-2">
                                    <p className="m-0 text-white">{error}</p>
                                </div>
                            }
                            <div className="mb-3">
                                <label htmlFor="login-mail" className="form-label">Adresse mail</label>
                                <input type="email" className="form-control" id="login-mail" placeholder="exemple@mail.fr" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="login-password" className="form-label">Mot de passe</label>
                                <input type="password" className="form-control" id="login-password" placeholder="••••••••••••" name="password" ref={register({required: true})} />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                <button type="submit" className="btn btn-primary">Connexion</button>
                                <div className="py-2">
                                    <Link to="/forgot">Mot de passe oublié ?</Link>
                                </div>
                            </div>
                            <div>
                                <span className="pr-1">Vous n'avez pas encore de compte ?</span><Link to="/signup">Inscrivez-vous !</Link>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-none d-lg-block h-100 right-side" style={imageBgStyle}>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Login);
