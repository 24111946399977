import React from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

import {stockData} from "../documents/faq"

export default function Faq(props) {


    const listItems = stockData.map((items, index) => {
        return(
        <div key={index} className="card" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <div className="card-header" id={"heading" + index} data-toggle="collapse" data-target={"#" + "collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                <h2 className="mb-0" itemProp="name">
                    {items.title}
                </h2>
                <RiArrowRightSLine className="arrow"/>
            </div>
            <div id={"collapse" + index} className="collapse" aria-labelledby={"heading" + index} data-parent="#accordionExample" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                <div className="card-body" itemProp="text" dangerouslySetInnerHTML={{__html: items.description}} />
            </div>
        </div>
        )

    })

    return (
        <>
            <div className="faq-title">
                FAQ
            </div>
            <div className="accordion" id="accordionExample">
                {listItems}
            </div>
        </>
    );
}
