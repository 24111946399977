import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ModalGuidePopup = (props) => {
    return (
        <Modal
            show={props.show}
            centered
            size="lg"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered my-0">
                <div className="modal-content border-0">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title" id="shareSocialModal">Le produit a été ajouté au panier</h5>
                    </div>
                    <div className="modal-body px-5 py-3 row justify-content-between">
                        <div className="col-12 col-md-5">
                            <Link className="btn btn-primary mb-2 w-100" to={props.link || "/"}>
                                Compléter mes achats
                            </Link>
                            <p className="text-center">Je complète ma commande chez {props.seller} et je réduis mes frais de livraison.</p>
                        </div>
                        <div className="col-12 col-md-5 d-flex flex-column align-items-end">
                            <Link className="btn btn-dark mb-2 w-100" to="/panier">
                                Aller au panier
                            </Link>
                            <p className="text-center">J’ai terminé, je passe au paiement de mon produit.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalGuidePopup;