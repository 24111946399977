import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD, BASE_URL_PHOTO_VENDEUR} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import HomeImg from "../assets/images/Livraisonprofessionnel2.jpg";
import HomeImg2 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-instagram-60-01062021.jpg";
import HomeImg3 from "../assets/images/La-Coterie-Francaise-Boucherie-en-ligne-gigot-agneau.jpg";
import PhoneImg from "../assets/images/La-Coterie-Francaise-Image-de-application.png";
import Title from '../components/Title';
import OrderStepsInfo from "../components/OrderStepsInfo";
import Footer from '../components/Footer';
import SliceItemVendors from '../components/SliceItemVendors';
import Instakot from '../components/Instakot';
import LogoLightImgGoogle from '../assets/images/lacoteriefrancaise_google-play.png';
import LogoLightImgApple from '../assets/images/lacoteriefrancaise_apple-store.png';
import Faq from "../components/Faq";
import {HOME_API_URL } from '../utils/api'

import PL1Img from "../assets/images/Photo-du-fondateur-de-La-Coterie-Francaise-1024x587.jpeg";


function CommencerLivraison(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);
    const [randShops, setRandShops] = useState([]);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(HOME_API_URL);
                console.log("resp", resp);
                setRandShops(resp.data.randShops);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + HomeImg + ')',
    };

    var imageBgStyle2 = {
        backgroundImage: 'url(' + HomeImg2 + ')',
    };

    var imageBgStyle3 = {
        backgroundImage: 'url(' + HomeImg3 + ')',
    };

    var imagePhone = {
        backgroundImage: 'url(' + PhoneImg + ')',
    };

    const index = 1
    const link = "/commencer-profil"
    const brand = {
            picture : "La-Coterie-Francaise-Boucherie-en-ligne-instagram-11-31052021.jpg",
        name : "Boucherie de la ruche",
        description : "A votre adresse saisie, nous vous avons sélectionné les partenaires pouvant vous livrer en quelques heures. Faites-vous plaisir en soutenant un partenaire connecté.",
    }


    return (
        
        <div className="">

            <div style={imageBgStyle} className="commencer-accueil-image-liv">
                <div className="commencer-accueil-text-liv">
                    <div>
                        <h1 className="title-of-h1">La livraison en 5h/24h sous température dirigée</h1>
                        <p className="title-of-p">Avec Chronofresh et nos autres partenaires, la livraison est assurée en quelques heures partout en France dans le respect de la chaîne du froid. Nos transporteurs 
                        attache une importance capitale aux colis qu'ils transportent. Les déplacements font effectué par camion frigorifique et à vélo pour les petites distances.</p>
                    </div>
                </div>
            </div>

            <section className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5 order-2 order-md-1">
                        <img loading="lazy" src={HomeImg2} alt="temps" className="w-100"/>
                    </div>
                    <div className="col-12 col-md-6 mb-5 order-1 order-md-2">
                        <div className="mb-3">
                            <h2 className="generic-title">Respect de la chaîne de froid</h2>
                            <h3 className="generic-subtitle">Délais express en 5h/24h</h3>
                            <hr className="generic-hr ml-0"/>
                        </div>
                        <p>Grâce à notre partenariat avec Chronofresh, l’offre de transport express alimentairesous température dirigée, bénéficiez du meilleur du service de livraison en 24hpour vos produits secs, fraisetsurgelésdans le respect de la chaîne du froid !</p>
                        <div >
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                </div>
            </section>

            <div style={imageBgStyle3} className="commencer-accueil-image">
                <div className="commencer-accueil-text">
                    <div>
                        <h2 className="title-of-h2">Nous avons choisit les meilleurs</h2>
                        <p className="title-of-p">En choisissant Chronofresh, pour les commandes avant 16h, vos produits sont livrés dès le lendemain de la préparation de commande.
                        L’ensemble des produits vous sont remis en main propre directement à domicile, ou au bureau. Ce service innovant assure l’enregistrement de la température de la prise en charge des colis à la livraison pour une dégustation réussie ! </p>
                        <div >
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="commencer-accueil-image-def-liv">
                <div className="commencer-accueil-text-def-liv">
                    <div>
                    <h2 className="title-of-h2-def">Une réception réussie</h2>
                    <p className="title-of-p">Grâce à l’outil Predict, vous êtes informé de l’avancée de votre livraison par mail ou par sms. En cas d’absence, vous pouvez reprogrammer votre livraison ou la mettre à disposition dans l’une de nos agences. Celles-ci sont toutes équipées de chambres froides pour conserver vos produits à la température exigée.</p>
                        {/* <p className="title-of-p">Aspirez à une alimentation saine, de qualité et soucieuse de l’environnement avec de bons artisans et producteurs.</p> */}
                        <div >
                            {/* <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Commander</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div style={imageBgStyle2} className="commencer-accueil-image">
                <div className="commencer-accueil-text">
                    <div>
                        <h2 className="title-of-h2">Téléchargez l'application mobile</h2>
                        <p className="title-of-p">Vivez une expérience unique sur mobile. Ne ratez plus jamais l’occasion de vous faire plaisir !</p>
                        <div className="center-content-appstore">
                            <picture >
                                <a href={"https://play.google.com/store/apps/details?id=com.bwade.MyMeatApp&hl=fr&gl=US"}><img src={LogoLightImgGoogle} alt=" La Coterie Française sur Google Play" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                            <picture >
                                <a href={"https://apps.apple.com/fr/app/la-coterie-fran%C3%A7aise/id1385886926#?platform=iphone"}><img src={LogoLightImgApple} alt="La Coterie Française sur Apple Store" loading="lazy" className="logo-store-img"/></a>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>

            <div style={imageBgStyle2} className="commencer-accueil-image-def d-lg-none mb-5">
                <div className="commencer-accueil-text-def">
                    <div>
                    <h2 className="title-of-h2-def">Vous êtes un artian ou producteur engagè et passionné ? Portez-nous candidat !</h2>
                    <p className="title-of-p">Simplifiez l'accès à vos produits en livrant vos clients (et de nouveaux) à leur adresse.</p>
                        {/* <p className="title-of-p">Aspirez à une alimentation saine, de qualité et soucieuse de l’environnement avec de bons artisans et producteurs.</p> */}
                        <div >
                        <a href="https://info.coterie-francaise.fr/" className={"mt-1 btn btn-dark btn-primary"}>Devenir partenaire</a>
                        </div>
                    </div>
                </div>
            </div>

            <section className="container mb-4">
                <Faq/>
            </section>

            {/* <div className="news-banner" style={imageBgStyle}>
                <div className="commencer-accueil-text-banner">
                    <div>
                        <h2 className="title-of-h1-banner">Abonnez-vous à la newsletter !</h2>
                        <p className="title-of-p-banner">Ne manquez pas nos actualités et promos de nos artisans, envoyés 2 fois par mois.</p>
                        <div className="form-group">
                            <input className="form-control" type="email" placeholder="mail@exemple.fr" id="edit-mail" name="username" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
                        </div>
                        <div >
                            <Link to={"/commencer-profil"} className={"mt-1 btn btn-primary"}>Valider</Link>
                        </div>
                    </div>
                    
                </div>
            </div> */}
            
            <Instakot/>

            <Footer/>

        </div>
    );
}

export default withRouter(CommencerLivraison);
