import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';

const ModalReview = ({show, onHide}) => {
    const { register, handleSubmit } = useForm();

    const handleAddReview = (v) => {
        console.log(v);

        onHide();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered w-100 my-0">
                <div className="modal-content border-0">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title" id="addReview">Ajouter un avis</h5>
                        <button className="icon btn p-0" onClick={onHide}>
                            <HiX size="1.1rem" />
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(handleAddReview)}>
                        <div className="modal-body py-3 px-5">
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea name="message" placeholder="Message..." className="form-control" ref={register({required: true})} />
                                </div>
                                <div className="form-group">
                                    <label>Note</label>
                                    <div>
                                        <AddRating register={register} />
                                    </div>
                                </div>
                        </div>
                        <div className="px-2 pb-2 d-flex justify-content-end">
                            <button className="btn btn-secondary" onClick={onHide}>
                                Annuler
                            </button>
                            <button type="submit" className="btn btn-primary ml-2">
                                Ajouter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};


function AddRating(props) {
    const [noteSelected, setNoteSelected] = useState(1);

    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label onClick={() => setNoteSelected(1)} style={{width: 40, height: 40}} className={`p-0 d-flex justify-content-center align-items-center btn btn-${noteSelected >= 1 ? "primary" : "light"} rounded-circle mr-1 mb-1 ${noteSelected === 1 ? "active" : ""}`}>
                <input type="radio" name="note" autoComplete="off"
                    defaultChecked
                    ref={props.register({required: true})}
                    value="1"
                />
                1
            </label>
            <label onClick={() => setNoteSelected(2)} style={{width: 40, height: 40}} className={`p-0 d-flex justify-content-center align-items-center btn btn-${noteSelected >= 2 ? "primary" : "light"} rounded-circle mr-1 mb-1 ${noteSelected === 2 ? "active" : ""}`}>
                <input type="radio" name="note" autoComplete="off"
                    ref={props.register({required: true})}
                    value="2"
                />
                2
            </label>
            <label onClick={() => setNoteSelected(3)} style={{width: 40, height: 40}} className={`p-0 d-flex justify-content-center align-items-center btn btn-${noteSelected >= 3 ? "primary" : "light"} rounded-circle mr-1 mb-1 ${noteSelected === 3 ? "active" : ""}`}>
                <input type="radio" name="note" autoComplete="off"
                    ref={props.register({required: true})}
                    value="3"
                />
                3
            </label>
            <label onClick={() => setNoteSelected(4)} style={{width: 40, height: 40}} className={`p-0 d-flex justify-content-center align-items-center btn btn-${noteSelected >= 4 ? "primary" : "light"} rounded-circle mr-1 mb-1 ${noteSelected === 4 ? "active" : ""}`}>
                <input type="radio" name="note" autoComplete="off"
                    ref={props.register({required: true})}
                    value="4"
                />
                4
            </label>
            <label onClick={() => setNoteSelected(5)} style={{width: 40, height: 40}} className={`p-0 d-flex justify-content-center align-items-center btn btn-${noteSelected >= 5 ? "primary" : "light"} rounded-circle mr-1 mb-1 ${noteSelected === 5 ? "active" : ""}`}>
                <input type="radio" name="note" autoComplete="off"
                    ref={props.register({required: true})}
                    value="5"
                />
                5
            </label>
        </div>
    );
}

export default ModalReview;