import React from 'react';
import ReactDOM from 'react-dom';

import './assets/style/scss/kot.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import { hotjar } from 'react-hotjar';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './utils/context';
import CartProvider from './hooks/Cart/CartProvider';
import AddressProvider from './hooks/Address/AddressProvider';

hotjar.initialize(2227748, 6);

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <AppProvider> 
                <CartProvider>
                    <AddressProvider>
                        <App />
                    </AddressProvider>
                </CartProvider>
            </AppProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

/*ReactDOM.hydrate(
    <React.StrictMode>
        <HelmetProvider>
            <AppProvider>
                <CartProvider>
                    <AddressProvider>
                        <App />
                    </AddressProvider>
                </CartProvider>
            </AppProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
