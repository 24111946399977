import React from 'react';
import { Link } from "react-router-dom";

import LogoLightImg from '../assets/images/La-Coterie-Francaise-light.png';

import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import CartOnProduct from "./CartOnProduct";
import useCart from "../hooks/Cart/useCart";

export default function Footer(props) {
    const [state, actions] = useCart();

    return (
        <>
            <footer className={state.cart?.length > 0 ? "pb-5" : null}>
                <div className="container overflow-hidden">
                    <div className="row justify-content-between new-footer">
                        <div className="col-12 col-md-12 col-lg my-2 my-lg-4 text-center text-lg-left">
                            <picture className="footer-logo">
                                <img className="new-footer-logo" src={LogoLightImg} alt="Logo de La Coterie Française sur fond noir" loading="lazy" />
                            </picture>
                            <p className="mt-3 mb-0 pr-lg-4 mr-2">
                                Ensemble, nous pouvons facilement aider nos agriculteurs et artisans bouchers à mieux vivre de leur héritage, et faire un geste pour notre planète. La Coterie Française permet à chacun de s’engager à son niveau tout en se faisant plaisir.
                                <ul className="socials">
                                <li><a href="https://www.facebook.com/LaCoterieFrancaise/"><AiOutlineFacebook className="social-icons"/></a></li>
                                <li><a href="https://www.instagram.com/lacoteriefrancaise/?hl=fr"><AiOutlineInstagram className="social-icons"/></a></li>
                                <li><a href="https://www.linkedin.com/company/lacoteriefrancaise/?viewAsMember=true"><AiOutlineTwitter className="social-icons"/></a></li>
                            </ul></p>
                            
                        </div>
                        {/* <div className="col-12 col-md-4 col-lg-auto my-2 my-lg-4">
                            <h5>ACCÈS BOUTIQUE</h5>
                            <Link to="/viande/boeuf">Viande de boeuf</Link>
                            <Link to="/viande/agneau">Viande d'agneau</Link>
                            <Link to="/viande/cheval">Viande de cheval</Link>
                            <Link to="/viande/dinde">Viande de dinde</Link>
                            <Link to="/viande/porc">Viande de porc</Link>
                            <Link to="/viande/poulet">Viande de poulet</Link>
                            <Link to="/viande/veau">Viande de veau</Link>
                        </div> */}
                        {/* <div className="col-12 col-md-4 col-lg-auto my-2 my-lg-4">
                            <h5>EN SAVOIR PLUS</h5>
                            <a href="https://info.coterie-francaise.fr/contact/">Contact</a>
                            <a href="https://info.coterie-francaise.fr/histoire-coterie-francaise/">L'histoire</a>
                        </div> */}
                    </div>
                    <hr/>
                    <div>
                        <ul className="sub-footer">
                            <li>
                                <p>©2021 La Coterie Française, tous droits réservés</p>
                            </li>
                            <li>
                                <a href="https://info.coterie-francaise.fr/politique-de-confidentialite/">Confidentialité</a>
                            </li>
                            <li>
                                <a href="https://info.coterie-francaise.fr/conditions-generales-utilisation/">Conditions</a>
                            </li>
                            <li>
                                <a href="https://info.coterie-francaise.fr/mentions-legales/">Mentions légales</a>
                            </li>
                            <li>
                                <a href="https://info.coterie-francaise.fr/politique-de-cookies/">Cookies</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <CartOnProduct/>
        </>
    );
}
