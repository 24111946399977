import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";
import LogoMiniImg from '../assets/images/picto-fleche-bouton.svg'
import LogoMiniImgBis from '../assets/images/Cow_beef_set_hand_draw_vintage_engraving_style_black_and_white_clip_art_isolated_on_white_background.png'

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';

import LogoImg from "../assets/images/La-Coterie-Francaise-dark.jpg";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import Title from '../components/Title';

function RestaurantConseil(props) {

    const [Races, setSetting] = useState([]);
    const [RacesFilted, setRacesFilted] = useState([]);
    const [SetCritere, setSetCritere] = useState([{"set":1},{"set":1},{"set":1},{"set":1},{"set":1},{"set":1},{"set":1}]);
    const [urlVerify, setUrlVerify] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);

   // SetCritere = [];

    var AllCritere = []
   // var SetCritere = []
    var Races_displayed = []

    
    const setValueCrit = (disp) => {

        SetCritere[disp.index].set = Number(disp.value)
        console.log("SetCritere -> ", SetCritere)
        filterRaces()

    }

    const filterRaces = () => {

        Races_displayed = props.location.state.filter(item => CalculFilter(item))
        console.log("Displayed Races", Races_displayed)
        setRacesFilted(Races_displayed)
    }

    const CalculFilter = (item) => {

        var restult = true;
        // item.race.critere.map((item_a, index_a) => {
        // if(item_a.value < SetCritere[index_a].set) {restult = false}
        // });
        // console.log("il sort en ", restult, item.race.libelle, )
        // return restult
        return restult

    }

    const ResetSet = () => {

        SetCritere.map((item_a, index_a) => {
            item_a.set = 1
        })
        filterRaces()
    //    console.log("Set to 1 -> ", SetCritere)

    }

    const displayAllCritere = () => {

        props.location.state.map((item_a, index_a) => {
            item_a.race.critere.map((item_b, index_b) => {
                AllCritere.push(item_b)
            })
        })

        var temp = AllCritere.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
      
        // temp.map((item_c, index_c) => {
        // SetCritere.push({"set":1}) ;
        // })

        AllCritere = temp
        
        console.log("AllCritere -> ", AllCritere)


        return AllCritere.map((crit, index) => {
            
            return (
                <div >
                    <div className='LineCrit pb-0'>
                        <div className='LineCritLib col-4 pl-0'>{AllCritere[index].libelle}</div>
                        <div className='col-8'>
                        <RangeSlider
                            value={SetCritere[index].set}
                            min={1}
                            step={1}
                            max={10}
                            tooltip='off'
                            variant='dark'
                            onChange={changeEvent => setValueCrit({"index":index,"value":Number(changeEvent.target.value)})}
                        />
                        </div>
                    </div>
                </div>
            )
        
        })

        setSetCritere(SetCritere)
        
    }

    const RetourPage = () => {
        props.history.goBack()
    }
    
    const displayRaces = () => {

        
        
        return RacesFilted.map((race, index) => {

            if(race.enabled == true) {

                return (
                    <Link to={{pathname: "/restaurant-race", state: race }}className={"new-link"}>
                        <div className="type_race_top" >
                            <div className='col-3 p-0 image-animal'>
                            <img src={'https://admin.coterie-francaise.fr/uploads/publications/' + race.race.image} className="picto-animal" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                            </div>
                            <div className='col-8 p-0 race-animal'>
                                <div className='flag-name'>
                                    <img src={race.race.country.flag} className="img-flag" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                    <div className="type_race">{race.race.category.libelle}</div>
                                </div>
                                <div className="type_race_nom-bis">{race.race.libelle}</div>
                                {/* <div className="type_race_town">{race.country.libelle}</div> */}
                                <div className="type_race_town-dec">{race.race.description.slice(0,50) + "..." }</div>
                            </div>
                            <div className='col-1 p-0 image-animal'>
                                {/* <img  className="picto-down" src="assets/pictos/picto-fleche-bouton.svg" /> */}
                                <img src={LogoMiniImg} className="picto-down" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                            </div>
                        </div>
                    </Link>
                )

            }
            
        }
    )}

    const displayCuts = () => {

        return RacesFilted.map((race, index) => {

            if(race.enabled == true ) {

            
            return race.race.cuts.map((cut, index) => {

                console.log("Displ -> ", cut)

                if(cut.actif == true && CutCritereOk(cut,race)) {
    
                    return (
                        <Link to={{pathname: "/restaurant-race", state: race }}className={"new-link"}>
                            <div className="type_race_top" >
                                <div className='col-3 p-0 image-animal'>
                                <img src={'https://admin.coterie-francaise.fr/uploads/publications/' + cut.piece.location} className="picto-animal" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                </div>
                                <div className='col-8 p-0 race-animal'>
                                    <div className='flag-name'>
                                        <img src={race.race.country.flag} className="img-flag-light" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                        <div className="type_race">{race.race.libelle}</div>
                                    </div>
                                    <div className="type_race_nom-bis-light">{cut.piece.libelle + " " + cut.piece.category.determinant + cut.piece.category.libelle}</div>
                                    {/* <div className="type_race_town">{race.country.libelle}</div> */}
                                    <div className="type_race_town-dec">{cut.piece.description.slice(0,50) + "..." }</div>
                                </div>
                                <div className='col-1 p-0 image-animal'>
                                    {/* <img  className="picto-down" src="assets/pictos/picto-fleche-bouton.svg" /> */}
                                    <img src={LogoMiniImg} className="picto-down" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                </div>
                            </div>
                        </Link>
                    )
                    
                }
            
            }
            )}
        }

    )}
 
    const CutCritereOk = (cut,race) => {

        console.log("Controle")
        var result = false
        var tab = []

        SetCritere.map((item, index) => {
            console.log(SetCritere[index].set,Math.max((race.race.critere[index].value * cut.piece.taux).toFixed(0),1))
            tab.push(SetCritere[index].set <= Math.max((race.race.critere[index].value * cut.piece.taux).toFixed(0),1))
        })

        console.log("tab -> ", tab, tab.indexOf(false))
        
        if(tab.indexOf(false) > -1 ){return false}
        else {return true}

    }


    return (
         
        <div className="d-block d-lg-none mb-5" >

            <div className='header-style-l text_align'>
                {/* <img src={LogoMiniImg} className="picto-down-retour" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" /> */}
                <p className="title-of-p-dl text-center mb-4 info-retour">Ajustez vos préférences</p>
            </div>

            {/* <div className="type_race p-0 pt-1 text_align"><b></b>Ajustez vos préférences</div> */}
            <div className="type_race_desc-title pb-3 p-0 pt-1 style-paraph text_align">Pour un niveau de cuisson de la viande à point.</div>

            <div className="type_race_grid pb-2" >{false ? <p>Chargement</p> : displayAllCritere()}</div>

            <div className={"mr-4 ml-4 mb-3"}>
                <button onClick={() => ResetSet()} className={"mt-1 btn btn-choice bg-bouton-actif-noir color-noir class-full-widh"}>Réinitialiser</button>
            </div>

            <div className="type_race p-0 pt-1 ml-1 pb-2"><b>Nos suggestions :</b></div>
            
            <div className="type_race_grid" >{false ? <p>Chargement</p> : displayCuts()}</div>

        </div>

    );
}

export default withRouter(RestaurantConseil); 
