import React, { createContext } from 'react';

export const addressContext = createContext();

const AddressProvider = (props) => {
    const [address, onAddress] = React.useState({});

    React.useEffect(() => {
        const add = localStorage.getItem("address");

        if (add) {
            onAddress(JSON.parse(add));
            //console.log(add)
        }
    }, [])

    const setAddress = (d) => {
        localStorage.setItem("address", JSON.stringify(d));
        onAddress(d);
    }

    return (
        <addressContext.Provider value={{
            address,
            setAddress
        }}>
            {props.children}
        </addressContext.Provider>
    );
};

export default AddressProvider;
