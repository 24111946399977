import React from 'react';
import {Helmet} from "react-helmet-async";

export default function GlobalHeaderTags(props) {
    return (
        <Helmet>
            <title>La Coterie Française</title>
            <meta name="description" content="Livraison à domicile de viande française en quelques heures. Achetez auprès d’un producteur de viande ou chez une boucherie en ligne, partenaire de La Coterie Française."/>
        </Helmet>
    );
}
