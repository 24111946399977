import React, {useState} from 'react';
import useCart from '../hooks/Cart/useCart';
import {useForm} from "react-hook-form";
import useRequest from "../hooks/useRequest";
import useContextApp from "../hooks/useContextApp";
import {DISCOUNT_CODE_API_URL, FORGOT_PASSWORD} from "../utils/api";
import {Link} from "react-router-dom";
import axios from "axios";

export default function Checkout(props) {
    const [state, cartActions] = useCart();

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [errorDiscount, onErrorDiscount] = useState(null);
    const [successDiscount, onSuccessDiscount] = useState(null);

    const toSubmitDiscountForm = async (values) => {
        onErrorDiscount(null);
        onSuccessDiscount(null);

        const resp = await axios.post(
            DISCOUNT_CODE_API_URL,
            {
                code: values.discountCode
            }
        );

        if (resp.data.success === false) {
            cartActions.setCodePromo({codepromo: null});
            onErrorDiscount(resp.data.message);
        } else if (resp.data.success === true) {
            cartActions.setCodePromo({codepromo: resp.data.data});
            //onSuccessDiscount("Code promo appliqué : "+values.discountCode);
        }
    }

    const checkoutData = React.useMemo(() => {
        let stht = 0;
        let tva = 0;
        // let stttcs = 0;
        let delivery = null;
        let discount = state.discount.amount;
        let promotion = 0;
        let total = 0;

        state.cart?.forEach(el => {
            //console.log(el);
            const price = el.price
            const calcTVA = price / (1 + el.product?.tvaRate?.rate);

            total += price;
            stht += calcTVA;
            tva += price - calcTVA;

            if (el.promotion !== undefined && el.promotion !== null) {
                if (price >= el.promotion.minPrice) {
                    promotion += el.promotion.discount;
                }
            }
        })

        if (state.payment?.getting?.type === "shipping") {
            if (state.deliveryServices !== undefined && state.deliveryServices !== null && state.deliveryServices.length > 0) {
                if (state.payment?.getting?.data?.delay !== undefined && state.payment?.getting?.data?.delay !== null && state.payment?.getting?.data?.service !== undefined && state.payment?.getting?.data?.service !== null) {
                    delivery = state.deliveryServices[state.payment?.getting?.data?.delay]?.data?.price;
                }
            }
        }

        return {
            stht: stht,
            tva: tva,
            total: total,
            discount: discount,
            promotion: promotion,
            delivery: delivery,
            calcTotal: total - promotion + (delivery ? delivery : 0)
        }
    }, [state.cart, state.payment, state.payment?.getting, state.payment?.getting?.type, state.payment?.getting?.data])

    return (
        <div className="checkout">
            <div className="checkout-side mb-2">
                <p className="mb-2"><strong>PAIEMENT SÉCURISÉ PAR CARTE</strong></p>
                <div className="creditcard mastercard"/>
                <div className="creditcard cb"/>
                <div className="creditcard visa"/>
                <div className="creditcard amex"/>
            </div>
            <div className="checkout-side mb-2">
                {checkoutData.stht !== undefined && checkoutData.stht !== null &&
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Sous-total HT</p>
                        <p className="mb-1">{checkoutData.stht.toFixed(2)} €</p>
                    </div>
                }
                {checkoutData.tva !== undefined && checkoutData.tva !== null &&
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">T.V.A.</p>
                        <p className="mb-1">{checkoutData.tva.toFixed(2)} €</p>
                    </div>
                }
                {/*{checkoutData.stttcs !== undefined && checkoutData.stttcs !== null &&
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Sous-total TTC</p>
                        <p className="mb-1">{checkoutData.stttcs.toFixed(2)} €</p>
                    </div>
                }*/}
                {checkoutData.promotion !== undefined && checkoutData.promotion !== null && checkoutData.promotion > 0 &&
                <div className="d-flex justify-content-between d-none">
                    <p className="mb-1">Promotion</p>
                    <p className="mb-1">-{checkoutData.promotion.toFixed(2)} €</p>
                </div>
                }
                {checkoutData.delivery !== undefined && checkoutData.delivery !== null &&
                    <div className="d-flex justify-content-between d-none">
                        <p className="mb-1">Livraison</p>
                        <p className="mb-1">{checkoutData.delivery.toFixed(2)} €</p>
                    </div>
                }
                {checkoutData.total !== undefined && checkoutData.total !== null &&
                    <>
                        <hr className="my-2"/>
                        <div className="d-flex justify-content-between align-items-center text-strong">
                            <p className="mb-0">TOTAL<br/><small className="text-muted">(T.V.A. Incluse)</small></p>
                            <p className="mb-0">{checkoutData.calcTotal.toFixed(2)} €</p>
                        </div>
                    </>
                }
                {checkoutData.total !== undefined && checkoutData.total !== null && checkoutData.discount !== undefined && checkoutData.discount !== null && checkoutData.discount > 0 &&
                    <>
                        <hr className="my-2"/>
                        <div className="d-flex justify-content-between">
                            <p className="mb-1">Code promo</p>
                            <p className="mb-1">-{checkoutData.discount.toFixed(2)} €</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-strong">
                            <p className="mb-0">TOTAL<br/><small className="text-muted">(T.V.A. Incluse)</small></p>
                            <p className="mb-0">{(checkoutData.calcTotal - checkoutData.discount).toFixed(2)} €</p>
                        </div>
                    </>
                }
                <div className={"mt-3"}>
                    {state.discount.code
                        ?
                        <div>
                            <p className={"text-primary"}>Code promo appliqué : {state.discount.code}</p>
                            <button type={"button"} className={"btn btn-secondary"} onClick={() => cartActions.setCodePromo(null)}>Retirer</button>
                        </div>
                        :
                        <form onSubmit={handleSubmit(toSubmitDiscountForm)}>
                            {errorDiscount &&
                            <div className="p-2 bg-danger mb-2">
                                <p className="m-0 text-white">{errorDiscount}</p>
                            </div>
                            }
                            {successDiscount &&
                            <div className="p-2 bg-success mb-2">
                                <p className="m-0 text-white">{successDiscount}</p>
                            </div>
                            }
                            <div className={"d-flex w-100"}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="discountCode" name="discountCode" ref={register({required: true})} />
                                </div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                    <button type="submit" className="btn btn-dark">Appliquer</button>
                                </div>
                            </div>
                        </form>
                    }
                </div>


            </div>
        </div>
    );
}
