import React from 'react';

export default function TitleSimple(props) {

    return (
        <>
            <section className={"container text-center " + (props.noMargin ? '' : 'pb-3')}>
                <h1 className="generic-subtitle-commencer">{props.subtitle}</h1>
                <p className="description-commencer">{props.text}</p>
                <hr className="generic-hr"/>
            </section>
        </>
    );
}