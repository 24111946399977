import React, { useState, useEffect, useMemo} from 'react';
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import { BASE_URL_PHOTO_HOME, BASE_URL_PHOTO_PRODUCT, CONFIRM_TOKEN_FORGOT_PASSWORD } from '../utils/api'


import Footer from '../components/Footer';
import ProductItem from '../components/ProductItem';
import Reviews from '../components/Reviews';
import DeliveryTime from '../components/DeliveryTime';
import ProductHeader from '../components/ProductHeader';
import ProductDescription from '../components/ProductDescription';
import CartOnProduct from '../components/CartOnProduct';
import { stockProduct } from '../documents/product';
import { stockSeller } from '../documents/seller'
import { v4 as uuidv4 } from 'uuid';
import useCart from '../hooks/Cart/useCart';
import ModalResetCart from '../components/ModalResetCart';
import useAddress from '../hooks/Address/useAddress';
import ModalAddress from '../components/ModalAddress';
import useConfession from "../hooks/Confession/useConfession";
import useQuantity from "../hooks/useQuantity";
import Faq from "../components/Faq";
import {Helmet} from "react-helmet-async";
import ModalGuidePopup from '../components/ModalGuidePopup';


export default withRouter(function Product(props) {
    const [config, actions] = useCart();
    const [clearCart, setClearCart] = useState(false);
    const [askAddress, setAskAddress] = useState(false);
    const [address, setAddress] = useAddress();
    const [displayMinPriceChoice, setDisplayMinPriceChoice] = useState(true);
    const [showGuidePopup, setShowGuidePopup] = useState({
        show: false,
        link: null
    });

    const [confession, setConfession, confessions] = useConfession();
    const [requestedConfession, setRequestedConfession] = useState(null);
    const [resultsConfession, setResultsConfession] = useState(null);

    const [breadcrumbs, setBreadcrumbs] = useState([]);

    let history = useHistory();
    let location = useLocation();

    React.useState(() => {
        //console.log(address);
        if (!address?.lat) {
            setTimeout(() => {
                setAskAddress(true)
            }, 1000)
        }
    }, [address])

    //GET WEIGHT PARAMS
    const params = new URLSearchParams(props.location.search);
    const poids = params.get('poids'); // bar
    //console.log('poids',poids)


// GET INFO FROM API
    const [mainProduct, setMainProduct] = useState(null);
    const [otherProducts, setOtherProducts] = useState([]);
    const [requested, setRequested] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [needRequestingAgain, setNeedRequestingAgain] = useState(false);

    // HANDLE SELECT CHOICE
    const [isSelect, setSelect] = useState(null);
    const [quantitySelect, setQuantitySelect] = useState(poids ? parseInt(poids) : 1);
    const [showSelect, setShowSelect] = useState(null);
    const [withAddress, setWithAddress] = useState(false);

    //console.log("quantitySelect",quantitySelect)
    const routeType = props.routeType;

    const brandLink = useMemo(() => {
        let link = "/";

        if (!showSelect) {
            return null;
        }

        if (config?.shop && config?.shop?.slug) {
            if (config?.shop?.company?.seller?.type?.id === 2) {
                link = "/eleveur/";
            } else {
                link = "/boucherie/";
            }
            link += config.shop.city.slug +"/"+ config.shop.slug;

            if (config.shop.specificity && config.shop.specificity.id !== 1) {
                link = "/" + config.shop.specificity.slug + link;
            }
        } else {
            if (routeType === "viande" || routeType === "label") {
                if (showSelect?.shop?.company?.seller?.type?.id === 2) {
                    link = "/eleveur/";
                } else {
                    link = "/boucherie/";
                }
                link += showSelect.shop.city.slug +"/"+ showSelect.shop.slug;

                if (showSelect.shop.specificity && showSelect.shop.specificity.id !== 1) {
                    link = "/" + showSelect.shop.specificity.slug + link;
                }
            } else {
                if (mainProduct.data.product?.shop?.company?.seller?.type?.id === 2) {
                    link = "/eleveur/";
                } else {
                    link = "/boucherie/";
                }
                link += mainProduct.data.product.shop.city.slug +"/"+ mainProduct.data.product.shop.slug;

                if (mainProduct.data.product.shop.specificity && mainProduct.data.product.shop.specificity.id !== 1) {
                    link = "/" + mainProduct.data.product.shop.specificity.slug + link;
                }
            }
        }

        return link;
    }, [mainProduct, showSelect, config, routeType]);

    // SET ALL SLUG
    const isMatch =  props.match.params;
    const slug_label = isMatch.slug_label;
    const slug_region = isMatch.slug_region;
    const slug_marque = isMatch.slug_marque;
    const slug_confession = isMatch.slug_confession;
    const slug_category = isMatch.slug_category;
    const slug_product = isMatch.slug_product;
    const slug_city = isMatch.slug_city;
    const slug_seller = isMatch.slug_seller;

    //SET PRODUCT HEADER PROP
    let header_key = '';
    let header_picture = '';
    let header_brand = '';
    let header_confession = '';
    let header_category = '';
    let header_title = '';
    let header_description = '';
    let header_seller = '';
    let header_notice = '';
    let header_value = '';
    // comments = listData.pro
    let header_priceActual = '';
    let header_unitProduct = false;
    // priceOld = listData.pro
    let header_quantity = '';
    let header_nbr_person = '';

    // SET URL_API_PRODUCT

    let URL_API_PRODUCT = '';

    switch (routeType) {
        case 'viande':
            //console.log("slug_confession", slug_confession);
            if (slug_confession !== undefined && slug_confession !== null && slug_confession !== "traditionnelle") {
                URL_API_PRODUCT = slug_confession + '/viande/' + slug_category + '/' + slug_product;
            } else {
                URL_API_PRODUCT = 'viande/'+ slug_category +'/'+ slug_product;
            }
            break;
        case 'eleveur':
            URL_API_PRODUCT = 'eleveur/' + slug_city + '/' + slug_seller + '/' + slug_category + '/' + slug_product;
            break;
        case 'boucherie':
            //console.log("case boucherie");
            URL_API_PRODUCT = 'boucherie/' + slug_city + '/' + slug_seller + '/' + slug_category + '/' + slug_product;
            break;
        case 'label':
            if (slug_confession !== undefined && slug_confession !== null && slug_confession !== "traditionnelle") {
                URL_API_PRODUCT = 'label/'+ slug_region + '/'+ slug_marque + '/'+ slug_category + '/'+ slug_product+ '/'+ slug_confession;
            } else {
                URL_API_PRODUCT = 'label/'+ slug_region + '/'+ slug_marque + '/'+ slug_category + '/'+ slug_product;
            }
            break;
        default:
            //console.log(`Sorry, we are out of ${routeType}.`);
    }

    const handleAddCart = (data) => {

        let currentShop = null;
        if (routeType === "viande" || routeType === "label") {
            currentShop = showSelect.shop

            //console.log("data", data);

            let i = {
                ...showSelect,
                _uid: uuidv4(),
                basePrice: data.price,
                basePriceWithoutPromotion: data.oldPrice,
                parts: data.quantity,
                promotion: data.promotion,
                product: {
                    ...showSelect.product,
                },
            }

            const quantityData = {
                quantity: i?.quantity ?? 1,
                price: i?.basePrice ?? 0,
                priceWithoutPromotion: i?.basePriceWithoutPromotion ?? i?.basePrice
            };

            let newQuantityData = quantityData;

            const q = 1

            const price = (i?.basePrice ?? 0) * q;
            const promotionP = (i?.basePriceWithoutPromotion ?? 0) * q

            newQuantityData = {
                quantity: q,
                price: price,
                priceWithoutPromotion: promotionP
            }

            i = {
                ...i,
                ...newQuantityData
            };

            if (!config.shop?.id) {
                actions.setCart({
                    ...config,
                    shop: currentShop,
                    cart: [
                        i
                    ],
                    checkout: null,
                })
            } else {
                if (config.shop?.id !== currentShop?.id) {
                    setShowSelect(i)
                    setClearCart(true);
                    return;
                }
                actions.addItem(i);
            }
        } else {
            //console.log("mainProduct", mainProduct);
            currentShop = mainProduct.data.product.shop

            let i = {
                ...mainProduct.data.product,
                _uid: uuidv4(),
                basePrice: data.price,
                basePriceWithoutPromotion: data.oldPrice,
                parts: data.quantity,
                promotion: data.promotion,
                product: {
                    ...mainProduct.data.product.product,
                },
            }

            const quantityData = {
                quantity: i?.quantity ?? 1,
                price: i?.basePrice ?? 0,
                priceWithoutPromotion: i?.basePriceWithoutPromotion ?? i?.basePrice
            };

            let newQuantityData = quantityData;

            const q = 1

            const price = (i?.basePrice ?? 0) * q;
            const promotionP = (i?.basePriceWithoutPromotion ?? 0) * q

            newQuantityData = {
                quantity: q,
                price: price,
                priceWithoutPromotion: promotionP
            }

            i = {
                ...i,
                ...newQuantityData
            };

            if (!config.shop?.id) {
                actions.setCart({
                    ...config,
                    shop: currentShop,
                    cart: [
                        i
                    ],
                    checkout: null,
                })
            } else {
                if (config.shop?.id !== currentShop?.id) {
                    setShowSelect(i)
                    setClearCart(true);
                    return;
                }
                actions.addItem(i);
            }
        }
        //console.log(config, showSelect, currentShop)

        let link = "/";

        if (config?.shop && config?.shop?.slug) {
            if (config?.shop?.company?.seller?.type?.id === 2) {
                link = "/eleveur/";
            } else {
                link = "/boucherie/";
            }
            link += config.shop.city.slug +"/"+ config.shop.slug;

            if (config.shop.specificity && config.shop.specificity.id !== 1) {
                link = "/" + config.shop.specificity.slug + link;
            }
        } else {
            if (routeType === "viande" || routeType === "label") {
                if (showSelect?.shop?.company?.seller?.type?.id === 2) {
                    link = "/eleveur/";
                } else {
                    link = "/boucherie/";
                }
                link += showSelect.shop.city.slug +"/"+ showSelect.shop.slug;

                if (showSelect.shop.specificity && showSelect.shop.specificity.id !== 1) {
                    link = "/" + showSelect.shop.specificity.slug + link;
                }
            } else {
                if (mainProduct.data.product?.shop?.company?.seller?.type?.id === 2) {
                    link = "/eleveur/";
                } else {
                    link = "/boucherie/";
                }
                link += mainProduct.data.product.shop.city.slug +"/"+ mainProduct.data.product.shop.slug;

                if (mainProduct.data.product.shop.specificity && mainProduct.data.product.shop.specificity.id !== 1) {
                    link = "/" + mainProduct.data.product.shop.specificity.slug + link;
                }
            }
        }

        if (!config?.shop?.id) {
            setShowGuidePopup({
                show: true,
                link: link
            });
        } else {
            props.history.push(link);
        }
    }

    const handleClear = () => {
        const d = {
            ...config,
            shop: showSelect.shop,
            cart: [
                {
                    _uid: uuidv4(),
                    basePrice: showSelect.price,
                    basePriceWithoutPromotion: showSelect.priceWithoutPromotion,
                    ...showSelect,
                }
            ]
        }

        actions.setCart(d);

        const link = "/boucherie/"+ showSelect.shop.city.slug +"/"+ showSelect.shop.slug;

        props.history.push(link);
    }

    useEffect(() => {
        //console.log("address", address);
        let forceRequestingAgain = false;
        if (needRequestingAgain) {
            forceRequestingAgain = true;
        }
        if ((!requested && !requesting) || forceRequestingAgain) {
            setRequestedConfession(confession);
            setRequesting(true);
            const fetchData = async() =>{
                let requestWithAddress = false;
                const confessionToRequest = confession;
                if (address !== null && address.lat !== undefined) {
                    URL_API_PRODUCT += "?near=1&latitude="+address.lat+"&longitude="+address.lng;
                    requestWithAddress = true;
                }
                const resp = await axios.get(BASE_URL_PHOTO_PRODUCT + URL_API_PRODUCT);

                if (routeType === "viande") {
                    if (resp.data.shops[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))] === undefined || resp.data.shops[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))] === null) {
                        history.push('/'+confessionToRequest.slug);
                        return;
                    }
                }

                //console.log("res", resp);

                setMainProduct(resp);
                setOtherProducts(resp.data.otherProducts);
                setRequesting(false);
                setRequested(true);
                setResultsConfession(confessionToRequest);
                setWithAddress(requestWithAddress);

                if (window !== undefined && !forceRequestingAgain) {
                    let affiliation = "";
                    if (routeType === "viande" || routeType === "label") {
                        affiliation = "La Coterie Française";
                        window.gtag('event', 'view_item', {
                            currency: 'EUR',
                            items: [{
                                item_id: resp.data.product.id,
                                item_name: resp.data.product.title,
                                index: 1,
                                affiliation: affiliation,
                                item_brand: resp.data.product?.brand?.name,
                                item_category: resp.data.product.category?.libelle,
                                item_variant: resp.data.product.grammage,
                                price: resp.data.product.price,
                                currency: 'EUR',
                                quantity: 1,
                            }],
                            value: 0
                        });
                    } else {
                        affiliation = resp.data.product.shop?.name
                        window.gtag('event', 'view_item', {
                            currency: 'EUR',
                            items: [{
                                item_id: resp.data.product?.product?.id,
                                item_name: resp.data.product?.product?.title,
                                index: 1,
                                affiliation: affiliation,
                                item_brand: resp.data.product?.product?.brand?.name,
                                item_category: resp.data.product?.product?.category?.libelle,
                                item_variant: resp.data.product?.product?.grammage,
                                price: resp.data.product.price,
                                currency: 'EUR',
                                quantity: 1,
                            }],
                            value: 0
                        });
                    }
                }

                let displayMinPrice = true;
                switch (routeType) {
                    case 'viande':
                        if (confession !== null && confession.id !== 1) {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"+confession.slug
                                },
                                {
                                    "value": resp?.data.product.category.menuName,
                                    "url": "/"+confession.slug + "/viande/" + resp?.data.product.category.slug,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.title + " (" + confession.libelle + ")",
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        } else {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"
                                },
                                {
                                    "value": resp?.data.product.category.menuName,
                                    "url": "/viande/" + resp?.data.product.category.slug,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.title,
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        }

                        break;
                    case 'eleveur':
                        if (confession !== null && confession.id !== 1) {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"+confession.slug
                                },
                                {
                                    "value": resp?.data.product.shop.name,
                                    "url": "/"+confession.slug + "/eleveur/" + resp?.data.product.shop.city.slug + "/" + resp?.data.product.shop.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.product.title + " (" + confession.libelle + ")",
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        } else {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"
                                },
                                {
                                    "value": resp?.data.product.shop.name,
                                    "url": "/eleveur/" + resp?.data.product.shop.city.slug + "/" + resp?.data.product.shop.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.product.title,
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        }

                        break;
                    case 'boucherie':
                        if (confession !== null && confession.id !== 1) {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"+confession.slug
                                },
                                {
                                    "value": resp?.data.product.shop.name,
                                    "url": "/"+confession.slug + "/boucherie/" + resp?.data.product.shop.city.slug + "/" + resp?.data.product.shop.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.product.title + " (" + confession.libelle + ")",
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        } else {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"
                                },
                                {
                                    "value": resp?.data.product.shop.name,
                                    "url": "/boucherie/" + resp?.data.product.shop.city.slug + "/" + resp?.data.product.shop.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.product.title,
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        }

                        break;
                    case 'label':
                        displayMinPrice = false;
                        if (confession !== null && confession.id !== 1) {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"+confession.slug
                                },
                                {
                                    "value": resp?.data.product.brand.regions[0].name,
                                    "url": "/"+confession.slug + "/label/" + resp?.data.product.brand.regions[0].slug
                                },
                                {
                                    "value": resp?.data.product.brand.name,
                                    "url": "/"+confession.slug + "/label/" + resp?.data.product.brand.regions[0].slug + "/" + resp?.data.product.brand.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.title,
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        } else {
                            const bc = [
                                {
                                    "value": "Accueil",
                                    "url": "/"
                                },
                                {
                                    "value": resp?.data.product.brand.regions[0].name,
                                    "url": "/label/" + resp?.data.product.brand.regions[0].slug
                                },
                                {
                                    "value": resp?.data.product.brand.name,
                                    "url": "/label/" + resp?.data.product.brand.regions[0].slug + "/" + resp?.data.product.brand.slug,
                                    "type": "shop_url"
                                },
                                {
                                    "value": resp?.data.product.category.menuName,
                                    "url": null,
                                    "type": "category_breadcrumb"
                                },
                                {
                                    "value": resp?.data.product.title,
                                    "url": null
                                }
                            ]

                            const breadCbs = [];
                            breadCbs.push(bc);

                            setBreadcrumbs(breadCbs);
                        }

                        break;
                    default:
                        displayMinPrice = false;
                        break;
                }

                setDisplayMinPriceChoice(displayMinPrice);

                if (isSelect === null) {
                    if (displayMinPrice) {
                        setSelect("minPrice");
                    } else {
                        setSelect("bestNote");
                    }
                } else {
                    if (isSelect === "minPrice" && !displayMinPrice) {
                        setSelect("bestNote");
                    }
                }

                if (forceRequestingAgain) {
                    setNeedRequestingAgain(false);
                }
            };
            fetchData();
        } else {
            if (requestedConfession !== null) {
                if (requestedConfession.id !== confession.id) {
                    switch (routeType) {
                        case 'viande':
                            //console.log("confession", confession);
                            setRequesting(false);
                            setRequested(false);
                            if (confession !== undefined && confession !== null && confession.id !== 1) {
                                URL_API_PRODUCT = '/' + confession.slug + '/viande/' + slug_category +'/'+ slug_product;
                            } else {
                                URL_API_PRODUCT = '/viande/'+ slug_category +'/'+ slug_product;
                            }
                            history.push(URL_API_PRODUCT);
                            break;
                        case 'eleveur':
                        case 'boucherie':
                        case 'label':
                            if (confession.id === 1) {
                                history.push('/');
                            } else {
                                history.push('/'+confession.slug);
                            }
                            break;
                        default:
                            //console.log(`Sorry, we are out of ${routeType}.`);
                            break;
                    }
                }
            }
        }
    }, [requested, confession])

    useEffect(() => {
        if((requested === true) && ((routeType === 'viande') || (routeType === 'label'))){
            if (address !== null && address.lat !== undefined) {
                if (!withAddress) {
                    setRequesting(false);
                    setRequested(false);
                    setNeedRequestingAgain(true);
                }
            }
        }
    }, [address])

    useEffect(() => {
        setRequesting(false);
        setRequested(false);
    }, [location])

    useEffect(() => {
        if((requested === true) && ((routeType === 'viande') || (routeType === 'label'))){
            setShowSelect(mainProduct?.data.shops[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))]);
        }
    }, [isSelect, resultsConfession, mainProduct])

    if (requested === true){
        // console.log("showSelect", showSelect);
        // console.log("mainProduct", mainProduct);
        switch (routeType) {
            case 'viande':
                header_key = mainProduct?.data.product.id;
                header_seller = showSelect?.shop.name
                header_picture = BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture;
                header_brand = mainProduct?.data.product.brand;
                header_confession = showSelect?.specificity;
                header_category = mainProduct?.data.product.category.libelle;
                header_title = mainProduct?.data.product.title;
                header_description = mainProduct?.data.product.description;
                // seller = listData.product.seller
                header_notice = showSelect?.notesCounter;
                header_value = showSelect?.avgNotes;
                // comments = listData.product.comments
                header_priceActual = showSelect?.price;
                header_unitProduct = mainProduct?.data.product.unitProduct;
                // priceOld = listData.product.priceOld
                header_quantity = mainProduct?.data.product.unitProduct ? mainProduct?.data.product.unitProductWeight : mainProduct?.data.product.grammage;
                header_nbr_person = mainProduct?.data.product.unitPersonsNumber;

                break;

            case 'eleveur':
                header_key = mainProduct?.data.product.id;
                header_picture = BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture;
                header_brand = mainProduct?.data.product.product.brand;
                header_confession = requestedConfession;
                header_category = mainProduct?.data.product.product.category.libelle;
                header_title = mainProduct?.data.product.product.title;
                header_description = mainProduct?.data.product.product.description;
                header_seller = mainProduct?.data.product.shop.name;
                header_notice = mainProduct?.data.product.shop.notesCounter;
                //console.log('mainProduct?.data.product.shop.notesCounter', mainProduct?.data.product.shop.notesCounter)
                header_notice = '';
                header_value = '';
                header_priceActual = mainProduct?.data.product.price;
                header_unitProduct = mainProduct?.data.product.product.unitProduct;
                header_quantity = mainProduct?.data.product.product.unitProduct ? mainProduct?.data.product.product.unitProductWeight : mainProduct?.data.product.product.grammage;
                header_nbr_person = mainProduct?.data.product.product.unitPersonsNumber;

            break;
            case 'boucherie':
                header_key = mainProduct?.data.product.id;
                header_picture = BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture;
                header_brand = mainProduct?.data.product.product.brand;
                header_confession = requestedConfession;
                header_category = mainProduct?.data.product.product.category.libelle;
                header_title = mainProduct?.data.product.product.title;
                header_description = mainProduct?.data.product.product.description;
                header_seller = mainProduct?.data.product.shop.name;
                header_notice = mainProduct?.data.product.shop.notesCounter;
                header_value = mainProduct?.data.product.shop.avgNotes;
                header_priceActual = mainProduct?.data.product.price;
                header_unitProduct = mainProduct?.data.product.product.unitProduct;
                header_quantity = mainProduct?.data.product.product.unitProduct ? mainProduct?.data.product.product.unitProductWeight : mainProduct?.data.product.product.grammage;
                header_nbr_person = mainProduct?.data.product.product.unitPersonsNumber;

                break;
            case 'label':
                //URL_API_PRODUCT = slug_label + '/'+ slug_region + '/'+ slug_marque + '/'+ slug_category + '/'+ slug_product

                header_key = mainProduct?.data.product.id;
                header_seller = showSelect?.shop.name
                header_picture = BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture;
                header_brand = mainProduct?.data.product.brand;
                header_confession = showSelect?.specificity;
                header_category = mainProduct?.data.product.category.libelle;
                header_title = mainProduct?.data.product.title;
                header_description = mainProduct?.data.product.description;
                // seller = listData.product.seller
                header_notice = showSelect?.notesCounter;
                header_value = showSelect?.avgNotes;
                // comments = listData.product.comments
                header_priceActual = showSelect?.price
                header_unitProduct = mainProduct?.data.product.unitProduct;
                // priceOld = listData.product.priceOld
                header_quantity = mainProduct?.data.product.unitProduct ? mainProduct?.data.product.unitProductWeight : mainProduct?.data.product.grammage;
                header_nbr_person = mainProduct?.data.product.unitPersonsNumber;
                break;

            default:
                //console.log(`Sorry, we are out of ${routeType}.`);
            }

    }

    //console.log('requested', requested)

    //DISPLAY MAIN PRODUCT FUNCTION
    const displayMainProduct = () => {
        /*let confession = 'Traditionnelle';
        switch (slug_confession) {
            case 'halal':
                confession = 'Halal';
                break;
            case 'cacher':
                confession = 'Cacher';
                break;
            default:
                confession = 'Traditionnelle';
            }*/

        let promotion = null;

        let productData = null;
        if (routeType === "viande" || routeType === "label") {
            productData = mainProduct.data.product;
            promotion = mainProduct.data.promotions[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))];
        }else {
            productData = mainProduct.data.product.product;
            promotion = mainProduct.data.promotion;
        }

        let currentShop = null;

        if (routeType === "viande" || routeType === "label") {
            currentShop = showSelect?.shop
        } else {
            //console.log("mainProduct", mainProduct);
            currentShop = mainProduct?.data?.product?.shop
        }

        return(
            <ProductHeader
                key={header_key}
                seller={header_seller}
                routeType={routeType}
                picture={header_picture}
                brand={header_brand}
                confession={header_confession}
                category={header_category}
                title={header_title}
                notice={header_notice}
                value={header_value}
                // comments={listData.product.comments}
                lowerPrice={header_priceActual ? (header_unitProduct ? header_priceActual : header_priceActual*header_quantity).toFixed(2) : "??"}
                priceActual={header_priceActual ? (header_unitProduct ? header_priceActual*quantitySelect : header_priceActual*quantitySelect*header_quantity).toFixed(2) : "??"}
                // priceOld={listData.product.priceOld}
                quantity={header_quantity}
                nbr_person={header_nbr_person}
                setSelect={setSelect}
                isSelect={isSelect}
                quantitySelect={quantitySelect}
                setQuantitySelect={setQuantitySelect}
                onAddCart={handleAddCart}
                breadcrumbs={breadcrumbs}
                displayMinPriceChoice={displayMinPriceChoice}
                productData={productData}
                brandLink={brandLink}
                currentShop={currentShop}
                promotion={promotion}
            />
        )
    }



    // const listData = stockProduct.find(({slug_product}) => slug_product === props.match.params.slug_product);
    // const listDataSeller = stockSeller.find(({slug_seller}) => slug_seller === props.match.params.slug_seller);

    // console.log('stockSeller', stockSeller)
    // console.log('listDataSeller', listDataSeller)

    // const listDataSellerProduct = () => listDataSeller.products.slice(0,4).map((items, index) =>{
    //     return(
    //         <div className="col-6 col-lg-4 col-xl-3 p-1 mb-3" key={index} id={index}>
    //                     <ProductItem
    //                         product={items.product}
    //                         confession={items.confession}
    //                         rating={items.rating}
    //                         priceMin={items.priceMin}
    //                         priceMax={items.priceMax}
    //                         category={items.category}
    //                         notice={items.notice}
    //                         quantity_1={items.quantity_1}
    //                         quantity_2={items.quantity_2}
    //                         quantity_3={items.quantity_3}
    //                         nbr_person_1={items.nbr_person_1}
    //                         nbr_person_2={items.nbr_person_2}
    //                         nbr_person_3={items.nbr_person_3}
    //                         image={items.img}

    //                     />
    //                 </div>
    //     )

    // });

    const displayDelivery = () => {
        let product = null;
        if (routeType === "viande" || routeType === "label") {
            product = mainProduct?.data?.product;
        } else {
            product = mainProduct?.data?.product?.product;
        }
        if (mainProduct?.data?.shops !== undefined && mainProduct?.data?.shops[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))] !== undefined) {
            //console.log(mainProduct?.data.shops);
            return (
                <DeliveryTime
                    preparationTime={mainProduct?.data.shops[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))]?.preparationTime}
                    deliveryTime={1}
                    product={product}
                    confession={requestedConfession}
                />
            );
        }
        if (mainProduct?.data?.product !== undefined) {
            return (
                <DeliveryTime
                    preparationTime={mainProduct?.data.product.preparationTime}
                    deliveryTime={1}
                    product={product}
                    confession={requestedConfession}
                />
            );
        }
        return (<></>);
    }

    const displayTitleOtherProducts = () => {
        switch (routeType) {
            case 'viande':
                return (<h3>LES AUTRES PRODUITS</h3>);
            case 'eleveur':
                return (<h3>LES AUTRES PRODUITS DE L'ÉLEVEUR</h3>);
            case 'boucherie':
                return (<h3>LES AUTRES PRODUITS DE LA BOUCHERIE</h3>);
            case 'label':
                return (<h3>LES AUTRES PRODUITS DE LA MARQUE</h3>);
            default:
                return (<h3>LES AUTRES PRODUITS</h3>);
        }
    }

    const listOtherProducts = () => {
        return otherProducts.map((product, index) => {
            //console.log('product', product)
            let slug = "";

            switch (routeType) {
                case 'viande':
                    if (confession !== null && confession.id !== 1) {
                        slug = '/' + confession.slug + '/viande/' + product.data.category.slug + '/' + product.data.slug;
                    } else {
                        slug = '/viande/' + product.data.category.slug + '/' + product.data.slug;
                    }
                    break;
                case 'eleveur':
                    if (confession !== null && confession.id !== 1) {
                        slug = '/' + confession.slug + "/eleveur/"+slug_city+"/"+slug_seller+"/"+product.data.category.slug+"/"+product.data.slug;
                    } else {
                        slug = "/eleveur/"+slug_city+"/"+slug_seller+"/"+product.data.category.slug+"/"+product.data.slug;
                    }
                    break;
                case 'boucherie':
                    if (confession !== null && confession.id !== 1) {
                        slug = '/' + confession.slug + "/boucherie/"+slug_city+"/"+slug_seller+"/"+product.data.category.slug+"/"+product.data.slug;
                    } else {
                        slug = "/boucherie/"+slug_city+"/"+slug_seller+"/"+product.data.category.slug+"/"+product.data.slug;
                    }
                    break;
                case 'label':
                    if (confession !== null && confession.id !== 1) {
                        slug = '/' + confession.slug + "/label/" + slug_region + "/" +slug_marque + "/" + product.data.category.slug + "/" + product.data.slug;
                    } else {
                        slug = "/label/" + slug_region + "/" +slug_marque + "/" + product.data.category.slug + "/" + product.data.slug;
                    }
                    break;
                default:
                    slug = '/viande/' + product.data.category.slug + '/' + product.data.slug;
                    if (resultsConfession && resultsConfession.id !== 1) {
                        slug = "/"+resultsConfession.slug + slug;
                    }
                    break;
            }

            return(
                <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3 p-1 mb-3">
                    <ProductItem
                        product={product.data.title}
                        slug={slug}
                        confession={resultsConfession}
                        priceMin={product.data.unitProduct ? parseFloat(product.minPrice) : parseFloat(product.minPrice * product.data.grammage)}
                        priceMax={product.data.unitProduct ? parseFloat(product.maxPrice) : parseFloat(product.maxPrice * product.data.grammage)}
                        discount={product.data.tvaRate.libelle}
                        category={product.data.category.libelle}
                        rating={product.note}
                        notice={product.noteCounter}
                        quantity={product.data.unitProduct ? product.data.unitProductWeight : product.data.grammage}
                        nbr_person={product.data.unitPersonsNumber}
                        image={BASE_URL_PHOTO_HOME + product.data.picture}
                        /*linkTo={(link) => {
                            setRequesting(false);
                            setRequested(false);
                            history.push(link);
                        }}*/
                    />
                </div>
            )
        })
    }

    const seoTags = () => {
        if (mainProduct) {
            let confessionLibelle = "";
            let slug = "";
            switch (routeType) {
                case 'viande':
                    if (confession !== null && confession.id !== 1) {
                        confessionLibelle = " " + "(" + confession.libelle + ")";
                        slug = '/' + confession.slug + '/viande/' + mainProduct?.data.product.category.slug + '/' + mainProduct?.data.product.slug;
                    } else {
                        slug = '/viande/' + mainProduct?.data.product.category.slug + '/' + mainProduct?.data.product.slug;
                    }
                    return (
                        <Helmet>
                            <title>{mainProduct?.data.product.title} {mainProduct?.data.product.category.determinant}{mainProduct?.data.product.category.libelle.toLowerCase()}{confessionLibelle} | La Coterie Française</title>
                            <meta name="description" content={"Drive et Livraison de "+mainProduct?.data.product.title+" "+mainProduct?.data.product.category.determinant+mainProduct?.data.product.category.libelle.toLowerCase()+confessionLibelle+". Livraison viande en ligne. Boucherie en ligne et vente directe producteur des viandes."}/>
                            <link rel="canonical" href={"https://www.coterie-francaise.fr" + slug} />

                            <meta property="og:title" content={mainProduct?.data.product.title + " " + mainProduct?.data.product.category.determinant + mainProduct?.data.product.category.libelle.toLowerCase() + confessionLibelle + " | La Coterie Française"} />
                            <meta property="og:description" content={"Drive et Livraison de "+mainProduct?.data.product.title+" "+mainProduct?.data.product.category.determinant+mainProduct?.data.product.category.libelle.toLowerCase()+confessionLibelle+". Livraison viande en ligne. Boucherie en ligne et vente directe producteur des viandes."}/>
                            <meta property="og:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture} />
                            <meta property="og:locale" content="fr_FR" />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />

                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={mainProduct?.data.product.title + " " + mainProduct?.data.product.category.determinant + mainProduct?.data.product.category.libelle.toLowerCase() + confessionLibelle + " | La Coterie Française"} />
                            <meta name="twitter:description" content={"Drive et Livraison de "+mainProduct?.data.product.title+" "+mainProduct?.data.product.category.determinant+mainProduct?.data.product.category.libelle.toLowerCase()+confessionLibelle+". Livraison viande en ligne. Boucherie en ligne et vente directe producteur des viandes."}/>
                            <meta name="twitter:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture} />
                        </Helmet>
                    );
                case 'eleveur':
                    if (confession !== null && confession.id !== 1) {
                        confessionLibelle = " " + "(" + confession.libelle + ")";
                        slug = '/' + confession.slug + '/viande/' + mainProduct?.data.product.product.category.slug + '/' + mainProduct?.data.product.product.slug;
                    } else {
                        slug = '/viande/' + mainProduct?.data.product.product.category.slug + '/' + mainProduct?.data.product.product.slug;
                    }
                    return (
                        <Helmet>
                            <title>{mainProduct?.data.product.product.title} {mainProduct?.data.product.product.category.determinant}{mainProduct?.data.product.product.category.libelle.toLowerCase()}{confessionLibelle} - {mainProduct?.data.product.shop.name.toUpperCase()} | Producteur en ligne</title>
                            <meta name="description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, un éleveur à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <link rel="canonical" href={"https://www.coterie-francaise.fr" + slug} />

                            <meta property="og:title" content={mainProduct?.data.product.product.title + " " + mainProduct?.data.product.product.category.determinant + mainProduct?.data.product.product.category.libelle.toLowerCase() + confessionLibelle + " - " + mainProduct?.data.product.shop.name.toUpperCase() + "| Éleveur à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet} />
                            <meta property="og:description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, un éleveur à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <meta property="og:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture} />
                            <meta property="og:locale" content="fr_FR" />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />

                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={mainProduct?.data.product.product.title + " " + mainProduct?.data.product.product.category.determinant + mainProduct?.data.product.product.category.libelle.toLowerCase() + confessionLibelle + " - " + mainProduct?.data.product.shop.name.toUpperCase() + "| Éleveur à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet} />
                            <meta name="twitter:description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, un éleveur à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <meta name="twitter:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture} />
                        </Helmet>
                    );
                case 'boucherie':
                    if (confession !== null && confession.id !== 1) {
                        confessionLibelle = " " + "(" + confession.libelle + ")";
                        slug = '/' + confession.slug + '/viande/' + mainProduct?.data.product.product.category.slug + '/' + mainProduct?.data.product.product.slug;
                    } else {
                        slug = '/viande/' + mainProduct?.data.product.product.category.slug + '/' + mainProduct?.data.product.slug;
                    }
                    return (
                        <Helmet>
                            <title>{mainProduct?.data.product.product.title} {mainProduct?.data.product.product.category.determinant}{mainProduct?.data.product.product.category.libelle.toLowerCase()}{confessionLibelle} - {mainProduct?.data.product.shop.name.toUpperCase()} | Boucherie en ligne</title>
                            <meta name="description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, une boucherie à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <link rel="canonical" href={"https://www.coterie-francaise.fr" + slug} />

                            <meta property="og:title" content={mainProduct?.data.product.product.title + " " + mainProduct?.data.product.product.category.determinant + mainProduct?.data.product.product.category.libelle.toLowerCase() + confessionLibelle + " - " + mainProduct?.data.product.shop.name.toUpperCase() + "| Boucherie à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet} />
                            <meta property="og:description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, une boucherie à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <meta property="og:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture} />
                            <meta property="og:locale" content="fr_FR" />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />

                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={mainProduct?.data.product.product.title + " " + mainProduct?.data.product.product.category.determinant + mainProduct?.data.product.product.category.libelle.toLowerCase() + confessionLibelle + " - " + mainProduct?.data.product.shop.name.toUpperCase() + "| Boucherie à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet} />
                            <meta name="twitter:description" content={"Drive et Livraison de "+mainProduct?.data.product.product.title+" "+mainProduct?.data.product.product.category.determinant+mainProduct?.data.product.product.category.libelle.toLowerCase()+confessionLibelle+" à votre adresse. Produits de "+mainProduct?.data.product.shop.name.toUpperCase()+" en ligne, une boucherie à "+mainProduct?.data.product.shop?.city?.nomCommuneComplet+". Commander et payer en ligne."}/>
                            <meta name="twitter:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.product.picture} />
                        </Helmet>
                    );
                case 'label':
                    if (confession !== null && confession.id !== 1) {
                        confessionLibelle = " " + "(" + confession.libelle + ")";
                        slug = '/' + confession.slug + '/viande/' + mainProduct?.data.product.category.slug + '/' + mainProduct?.data.product.slug;
                    } else {
                        slug = '/viande/' + mainProduct?.data.product.category.slug + '/' + mainProduct?.data.product.slug;
                    }
                    return (
                        <Helmet>
                            <title>{mainProduct?.data.product.title} {mainProduct?.data.product.category.determinant}{mainProduct?.data.product.category.libelle.toLowerCase()}{confessionLibelle} | {mainProduct?.data.product.brand.name.toUpperCase()}</title>
                            <meta name="description" content={"Drive et Livraison de "+mainProduct?.data.product.title+confessionLibelle+" de "+mainProduct?.data.product.brand.name.toUpperCase()+". Viande en ligne. Boucherie en ligne et vente directe producteur."}/>
                            <link rel="canonical" href={"https://www.coterie-francaise.fr" + slug} />

                            <meta property="og:title" content={mainProduct?.data.product.title + " " + mainProduct?.data.product.category.determinant + mainProduct?.data.product.category.libelle.toLowerCase() + confessionLibelle + " | " + mainProduct?.data.product.brand.name.toUpperCase()} />
                            <meta property="og:description" content={"Drive et Livraison de "+mainProduct?.data.product.title+confessionLibelle+" de "+mainProduct?.data.product.brand.name.toUpperCase()+". Viande en ligne. Boucherie en ligne et vente directe producteur."}/>
                            <meta property="og:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture} />
                            <meta property="og:locale" content="fr_FR" />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />

                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={mainProduct?.data.product.title + " " + mainProduct?.data.product.category.determinant + mainProduct?.data.product.category.libelle.toLowerCase() + confessionLibelle + " | " + mainProduct?.data.product.brand.name.toUpperCase()} />
                            <meta name="twitter:description" content={"Drive et Livraison de "+mainProduct?.data.product.title+confessionLibelle+" de "+mainProduct?.data.product.brand.name.toUpperCase()+". Viande en ligne. Boucherie en ligne et vente directe producteur."}/>
                            <meta name="twitter:image" content={BASE_URL_PHOTO_HOME+mainProduct?.data.product.picture} />
                        </Helmet>
                    );
                default:
                    return (
                        <Helmet></Helmet>
                    );
            }
        }
        return (
            <Helmet></Helmet>
        );

        /*
        <ProductHeader
                key={header_key}
                seller={header_seller}
                routeType={routeType}
                picture={header_picture}
                brand={header_brand}
                confession={header_confession}
                category={header_category}
                title={header_title}
                notice={header_notice}
                value={header_value}
                // comments={listData.product.comments}
                priceActual={header_priceActual ? (header_priceActual*quantitySelect*header_quantity).toFixed(2) : "??"}
                // priceOld={listData.product.priceOld}
                quantity={header_quantity}
                nbr_person={header_nbr_person}
                setSelect={setSelect}
                isSelect={isSelect}
                quantitySelect={quantitySelect}
                setQuantitySelect={setQuantitySelect}
                onAddCart={handleAddCart}
                breadcrumbs={breadcrumbs}
                displayMinPriceChoice={displayMinPriceChoice}
            />
         */
    }

    let reviews = [];

    if (routeType === "viande" || routeType === "label") {
        if (mainProduct?.data?.reviews !== undefined) {
            reviews = mainProduct.data.reviews[(isSelect ? isSelect : (displayMinPriceChoice ? "minPrice" : "bestNote"))];
        }
    } else {
        if (mainProduct?.data?.reviews !== undefined) {
            reviews = mainProduct.data.reviews;
        }
    }

    return (
        <>

            <div className="pt-3 pr-5 pl-5 center-content-wiz"> 
                <div className="avatar-passe d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-passe d-lg-none d-none" >1</div>
                <div className="avatar-passe d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar-passe d-lg-none d-none" >2</div>
                <div className="avatar-passe d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar-passe d-lg-none" >3</div>
                <div className="avatar-valide d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar-valide d-lg-none" >4</div>
                <div className="avatar d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar d-lg-none d-none" >5</div>
                <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none d-none" >6</div>
                <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                    {/* <div className="avatar-valide" >2</div>
                    <div className="avatar" >...</div>
                    <div className="avatar">7</div> */}
                </div>
            <div className="center-content-wiz-line pb-4"></div>

            {seoTags()}
            <article itemScope itemType="http://schema.org/Product">
                <section className="container mt-2 mt-lg-4">
                    {requesting ?
                        <p>Chargement</p>
                        :
                        [requested &&
                        displayMainProduct()
                        ]

                    }
                </section>
                <section className="container mt-4 mb-2">
                    {requesting ?
                        <p>Chargement</p>
                        :
                        [requested &&
                        displayDelivery()
                        ]
                    }
                </section>
                <section className="container mb-4">
                    <ProductDescription
                        description={header_description}
                        delaiPreparation="blaceptati ipsam nam, volest ero magni delis aut as maiorerferum ra eseni nus pa consevolum quist ut reprehendi sunt Olup- tatem. Epe ipis dus suntur, con et qui volorum eos eostio odita sumquis mollaceario. Ellendi blaboreiur, con perum fugia eaqui nessinv enihitatio quatum quis si unt labor alia aperchi lignatquid estiat.
                    Consequisqui cum alique velent."
                        livraison="blaceptati ipsam nam, volest ero magni delis aut as maiorerferum ra eseni nus pa consevolum quist ut reprehendi sunt Olup- tatem. Epe ipis dus suntur, con et qui volorum eos eostio odita sumquis mollaceario. Ellendi blaboreiur, con perum fugia eaqui nessinv enihitatio quatum quis si unt labor alia aperchi lignatquid estiat.
                    Consequisqui cum alique velent."
                    />
                </section>
                <section className="container mb-4">
                    <Reviews nbrNotes={header_notice} avgNotes={header_value} reviews={reviews} />
                </section>
                <aside className="container mb-4">
                    <div className="">
                        {displayTitleOtherProducts()}
                    </div>
                    <div className="row px-1">
                        {listOtherProducts()}
                    </div>

                </aside>
                <section className="container mb-4">
                </section>
                <Footer/>
                <ModalAddress show={!address?.lat && isSelect === "near"} onSelect={setAddress} onHide={() => setSelect("minPrice")} />
                <ModalResetCart show={clearCart} onHide={() => setClearCart(false)} onClear={handleClear} />
                <ModalGuidePopup show={showGuidePopup.show} link={showGuidePopup.link} onHide={() => setShowGuidePopup({show: false, link: null})} seller={header_seller} />
            </article>
        </>
    );
})
