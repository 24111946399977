import React from 'react';
import useRequest from '../hooks/useRequest';
import { API_ACCOUNT } from './api';

const state = {
    config: {}
}

export const appContext = React.createContext(state);

export const APP_REDUX_TYPES = {
    ADD_TOKEN: "ADD_LOGIN_TOKEN",
    REMOVE_TOKEN: "REMOVE_LOGIN_TOKEN",
    ADD_USER: "ADD_USER_DATA",
    REMOVE_USER: "REMOVE_USER_DATA"
}

const apiData = {
    headers: {
        'Content-type': 'application/json',
    },
};

function reducer(state, action) {
    switch (action.type) {
        case APP_REDUX_TYPES.ADD_TOKEN:
            if (localStorage.getItem('Authorization') !== action.payload) {
                localStorage.setItem('Authorization', action.payload);
            }
            if (!action.payload)
                return state;
            return {
                headers: {
                    ...state.headers,
                    'Authorization': "Bearer " + action.payload,
                },
                connected: true
            };
        case APP_REDUX_TYPES.REMOVE_TOKEN:
            localStorage.removeItem('Authorization');
            return {
                ...apiData,
                connected: false
            };
        case APP_REDUX_TYPES.ADD_USER:
            return {
                ...state,
                user: action.payload
            }
        case APP_REDUX_TYPES.REMOVE_USER:
            return {
                ...state,
                user: undefined
            }
        default:
            return state;
    }
}

export const AppProvider = (props) => {
    const [handleRequest] = useRequest();
    const [state, dispatch] = React.useReducer(reducer, apiData)

    React.useEffect(() => {
        const token = localStorage.getItem("Authorization");

        if (token) {
            handleRequest("get", API_ACCOUNT, {}, {headers: {Authorization: `Bearer ${token}`}}, (data, status) => {
                if (status === "Done") {
                    dispatch({type: APP_REDUX_TYPES.ADD_TOKEN, payload: token})
                    dispatch({type: APP_REDUX_TYPES.ADD_USER, payload: data})
                } else if (status === "Error") {
                    dispatch({type: APP_REDUX_TYPES.REMOVE_TOKEN})
                    console.error(data)
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    return <appContext.Provider value={{
        app: {
            state,
            dispatch
        }
    }}>
        {props.children}
    </appContext.Provider>
}
