import React from 'react';
import LogoMiniImg from '../assets/images/logo-application-lacoteriefrancaise-1024.png'
import { Link, withRouter } from 'react-router-dom';

export default function Instakot(props) {

    return (
        <div className="fixed-bottom d-lg-none d-none">
            <row className="app-banner">
                <div className="col-2 p-0"><img className="img-app" src={LogoMiniImg} property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" /></div>
                <div className="col-7 cta-app">Gagnez du temps !<div className="cta-bis-app">Téléchargez votre appli.</div></div>
                <div className="col-3 btn-app"><a href="https://info.coterie-francaise.fr/telechargez-lapplication-mobile/" className={"mt-1 btn btn-primary"}>Go!</a></div>
            </row>
        </div>
    );
}