import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";

function ResetPassword(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL + VERIFY_RESET_PASSWORD(account, token));
                console.log("resp", resp);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        if (values.password !== values.password2) {
            onError("Les mots de passe ne sont pas identiques");
        } else {
            handleRequest("post", BASE_URL + RESET_PASSWORD(account, token), values, config, (data, status) => {
                if (status === "Done") {

                    props.history.push("/login")
                } else if (status === "Error") {
                    switch (data.status) {
                        case 401:
                            onError("Votre adresse mail ou mot de passe est éronné.");
                            break;
                        case 400:
                            onError("Veuillez remplir tous les champs nécéssaires.");
                            break;
                        default:
                            onError(`Une erreur de type ${data.status} est survenue...`);
                    }
                    console.error("Error on login", data);
                }
            })
        }
    }

    return (
        <div className="login">
            <div className="row no-gutters h-100">
                <div className="col-12 col-lg-6 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3">
                        {urlVerified === true
                            ?
                            [urlValid
                                ?
                                <form className="col-12 col-xl-8" onSubmit={handleSubmit(toSubmit)}>
                                    <section className="pb-3 text-left">
                                        <h2 className="generic-title">RÉINITIALISATION DE VOTRE MOT DE PASSE</h2>
                                        <hr className="generic-hr ml-0"/>
                                    </section>
                                    {
                                        error &&
                                        <div className="p-2 bg-danger mb-2">
                                            <p className="m-0 text-white">{error}</p>
                                        </div>
                                    }
                                    <div className="mb-3">
                                        <label htmlFor="login-password" className="form-label">Mot de passe</label>
                                        <input type="password" className="form-control" id="login-password" placeholder="••••••••••••" name="password" ref={register({required: true})} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="login-password-2" className="form-label">Confirmez le mot de passe</label>
                                        <input type="password" className="form-control" id="login-password-2" placeholder="••••••••••••" name="password2" ref={register({required: true})} />
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                                        <button type="submit" className="btn btn-primary">Réinitialiser</button>
                                    </div>
                                </form>
                                :
                                <div className="p-2 bg-danger mb-2">
                                    <p className="m-0 text-white">Cette URL a expirée ou n'est pas valide</p>
                                </div>
                            ]
                            :
                            <p>Chargement...</p>
                        }
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-none d-lg-block h-100 right-side" style={imageBgStyle}>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ResetPassword);
