import React from 'react';
import { Link } from 'react-router-dom';
import { IoCloseSharp } from "react-icons/io5";
import { DeliveryConditions } from '../documents/prices';
import useCart from "../hooks/Cart/useCart";
import {BASE_URL_PHOTO_HOME} from "../utils/api";

// import axios from 'axios';

export default function CartOnProduct(props) {
    const [state, actions] = useCart();

    const deliveryCondition = DeliveryConditions;

    const handleOnClick = (uid) => {
        //console.log("delete", uid);
        actions.removeItem(uid);
    }

    const displayProductIncart = () => {
        if (state.cart?.length) {
            if (state.cart.length <= 3) {
                return state.cart.map((item, index) => {
                    //console.log("item", item);
                    return (
                        <MiniCartProduct key={index} item={item} index={index} shop={state.shop} handleOnClick={(uid) => handleOnClick(uid)} />
                    )
                })
            }
            if (state.cart.length === 4) {
                return state.cart.slice(0, 4).map((item, index) => {
                    return (
                        <MiniCartProduct key={index} item={item} index={index} shop={state.shop} handleOnClick={(uid) => handleOnClick(uid)} />
                    )
                })
            }
            if (state.cart.length >= 4) {
                return state.cart.slice(0, 3).map((item, index) => {
                    return (
                        <MiniCartProduct key={index} item={item} index={index} shop={state.shop} handleOnClick={(uid) => handleOnClick(uid)} />
                    )
                })
            }
        }

    }

    const hiddenProducts = () => {
        if (state.cart.length >= 5) {
            return (
                <div className="col-3">
                    <div className="product-in-cart-wrapper">
                        <Link to='/' className="even-more">
                            <span>+{state.cart.length - 3}</span>
                        </Link>
                    </div>
                </div>
            )
        }
    }

    const DisplayProgressBar = (props) => {

        let stht = 0;
        let tva = 0;
        let total = 0;

        state.cart?.forEach(el => {
            const calcTVA = el.woTVAPrice || el.price / (1 + el.product?.tvaRate?.rate);

            total += el.price;
            stht += calcTVA;
            tva += el.price - calcTVA;
        });

        const price = total;
        const deliveryRange = DeliveryConditions.filter(el => price < el.minPrice)[0]
        const nextDeliveryRange = deliveryRange?.minPrice;
        const nextDeliveryPrice = deliveryRange?.deliveryPrice;
        const percentageforNextRange = (price / nextDeliveryRange) * 100;

        if (nextDeliveryRange)
            return (
                <>
                    <p className="title">Pour une livraison {nextDeliveryPrice === 0 ? "Gratuite" : "à " + nextDeliveryPrice.toFixed(2) +"€"}</p>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: percentageforNextRange + '%' }} aria-valuenow={percentageforNextRange} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div className="amount-left-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="192" height="14" viewBox="0 0 192 14">
                            <polyline fill="none" stroke="#8D1003" strokeWidth="3" points="1375.84 2323.165 1289.508 2322.927 1279.953 2313.372 1270.488 2322.837 1184.168 2322.595" transform="translate(-1184 -2311)" />
                        </svg>
                        <p className="amount-left mb-0">Plus que {(nextDeliveryRange - price).toFixed(2)}€</p>
                    </div>
                </>
            );
        return null;
    }

    return (
        <>
            {state.cart?.length > 0 &&
                <div className="cart-on-product active">
                    <div className="container-fluid container-xl py-2">
                        <div className="row align-items-center">
                            <div className="px-xl-4 col-12 col-md-6 col-lg-4 d-none d-lg-block">
                                <div className="row flex-nowrap products-in-cart">
                                    {displayProductIncart()}
                                    {hiddenProducts()}
                                </div>
                            </div>
                            <div className="px-xl-4 col-12 col-md-6 col-lg-4 mb-2 mb-md-0 mb-lg-0">
                                <div className="amount-left-column text-center">
                                    {/*<DisplayProgressBar pricesRanges={deliveryCondition} />*/}
                                </div>
                            </div>
                            <div className="px-xl-4 col-12 col-md-6 col-lg-4">
                                <div className="text-right">
                                    <Link to="/panier" className="btn btn-primary py-2">
                                        <div className="text">Voir mon panier</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

function MiniCartProduct(props) {
    const {item, index, shop} = props;

    const link = "/boucherie/"+ shop.city.slug +"/"+ shop.slug +"/" + item.product.category.slug + "/" + item.product.slug

    return (
        <div className="col-3" key={item.product.id}>
            <div className="product-in-cart-wrapper">
                <button className="delete-product" onClick={() => props.handleOnClick(item._uid)}><IoCloseSharp className="delete-icon" /></button>
                <Link to={link}>
                    <img
                        src={BASE_URL_PHOTO_HOME+item.product.picture}
                        alt={item.product.description}
                    />
                </Link>
            </div>
        </div>
    )
}
