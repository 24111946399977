import { CartTypes } from "./CartTypes";

export const cartDefaultState = {
    shop: {},
    cart: [],
    delay: 0,
    payment: {
        information: {},
        getting: {},
    },
    checkout: null,
    discount: {
        code: null,
        amount: 0,
    },
    codepromo: null,
    deliveryServices: [],
}

const CartReducer = (state = cartDefaultState, action) => {
    const calcPreparationTime = (items) => {
        let delay = 0;
        items.map(item => {
            if (item.preparationTime > delay) {
                delay = item.preparationTime;
            }
        });
        return delay;
    }

    const calcDiscount = (cart) => {
        if (cart.codepromo !== undefined && cart.codepromo !== null) {
            console.log(cart.codepromo);
            const code = cart.codepromo.code;
            let amount = 0;
            const minPrice = cart.codepromo.minPrice;
            let currentPrice = 0;
            cart.cart.map(item => {
                if (cart.codepromo.product !== null) {
                    if (cart.codepromo.product.id === item.product.id) {
                        currentPrice += item.price;
                    }
                } else if (cart.codepromo.brand !== null) {
                    if (item.product.brand !== null) {
                        if (cart.codepromo.brand.id === item.product.brand.id) {
                            currentPrice += item.price;
                        }
                    }
                } else if (cart.codepromo.category !== null) {
                    if (item.product.category !== null) {
                        if (cart.codepromo.category.id === item.product.category.id) {
                            currentPrice += item.price;
                        }
                    }
                } else if (cart.codepromo.shop !== null) {
                    if (cart.codepromo.shop.id === item.shop.id) {
                        currentPrice += item.price;
                    }
                }
            });

            if (currentPrice >= minPrice) {
                amount = cart.codepromo.discount;
            }

            return {
                code: code,
                amount: amount,
            };
        }

        return {
            code: null,
            amount: 0,
        };
    }

    let newState = state;
    //console.log("begin state", state);
    switch (action.type) {
        case CartTypes.ADD_ITEM:
            newState = {
                ...newState,
                payment: cartDefaultState.payment,
                cart: [
                    ...newState.cart,
                    action.payload
                ]
            };

            if (typeof window !== 'undefined' && window !== undefined) {
                const gaItems = [];
                newState.cart.map((product) => {
                    gaItems.push({
                        item_id: product.id,
                        item_name: product.product.title,
                        affiliation: product.shop.name,
                        item_brand: product.product?.brand?.name,
                        item_category: product?.product?.category?.libelle,
                        item_variant: product.product.grammage,
                        price: product.price,
                        currency: 'EUR',
                        quantity: product.quantity
                    });
                })
                window.gtag('event', 'add_to_cart', {
                    currency: 'EUR',
                    items: gaItems,
                    value: 0
                });
            }

            break;

        case CartTypes.REMOVE_ITEM:
            newState = {
                ...newState,
                payment: cartDefaultState.payment,
                cart: newState.cart?.filter(el => el._uid !== action.payload)
            };
            if (newState.cart?.length === 0) {
                newState = cartDefaultState
            }

            if (typeof window !== 'undefined' && window !== undefined) {
                const gaItems = [];
                newState.cart.map((product) => {
                    gaItems.push({
                        item_id: product.id,
                        item_name: product.product.title,
                        affiliation: product.shop.name,
                        item_brand: product.product?.brand?.name,
                        item_category: product?.product?.category?.libelle,
                        item_variant: product.product.grammage,
                        price: product.price,
                        currency: 'EUR',
                        quantity: product.quantity
                    });
                })
                window.gtag('event', 'remove_from_cart', {
                    currency: 'EUR',
                    items: gaItems,
                    value: 0
                });
            }

            break;

        case CartTypes.UPDATE_ITEM:
            newState = {
                ...newState,
                payment: cartDefaultState.payment,
                cart: newState.cart?.map(el => {
                    if (el._uid === action.payload?._uid) {
                        return {
                            ...el,
                            ...action.payload
                        }
                    }
                    return el
                })
            };
            //console.log("update item state", newState);
            break;

        case CartTypes.REMOVE_CART:
            newState = cartDefaultState;
            //console.log("remove cart state", newState);
            break;

        case CartTypes.SET_CART:
            newState = action.payload;
            //console.log("set cart state", newState);
            break;

        case CartTypes.CHANGE_CART:
            newState = {
                ...newState,
                shop: action.payload,
                cart: []
            };
            //console.log("change cart state", newState);
            break;

        case CartTypes.CHANGE_PAYMENT:
            const payment = {...newState.payment};
            payment[action.payload?.type] = action.payload?.value;
            newState = {
                ...newState,
                payment
            };
            //console.log("change payment state", newState);
            break;

        case CartTypes.SET_CHECKOUT:
            newState = {
                ...newState,
                checkout: action.payload?.checkout
            };
            break;

        case CartTypes.SET_DISCOUNT:
            newState = {
                ...newState,
                discount: action.payload?.discount
            };
            break;

        case CartTypes.SET_DELIVERY_SERVICES:
            newState = {
                ...newState,
                deliveryServices: action.payload?.deliveryServices
            };
            break;

        case CartTypes.SET_CODE_PROMO:
            newState = {
                ...newState,
                codepromo: action.payload?.codepromo
            };
            break;

        default:
            return newState;
    }
    newState = {
        ...newState,
        delay: calcPreparationTime(newState.cart),
        discount: calcDiscount(newState)
    };
    //console.log("localStorage store", newState);

    localStorage.setItem("Cart", JSON.stringify(newState));
    return newState;
}

export default CartReducer;
