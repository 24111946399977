import {env} from "./env";

export const BASE_URL = env === "prod" ? "https://admin.coterie-francaise.fr" : "https://lcf-bo.slapp.me";

// Auth

export const LOGIN_USER = "/auth/login";

export const REGISTER_USER = "/auth/register";
export const REGISTER_ORDER_USER = "/auth/orderregister";

export const FORGOT_PASSWORD = "/auth/password/forgot";

export const CONFIRM_TOKEN_FORGOT_PASSWORD = "/auth/password/check_validity";

export const RESET_PASSWORD = (accountID, token) => `/auth/password/reset/${accountID}/${token}`;
export const VERIFY_RESET_PASSWORD = (accountID, token) => `/auth/password/resetverify/${accountID}/${token}`;

export const EMAIL_CONFIRMATION = (accountID, token) => `/auth/email_confirmation/${accountID}/${token}`;


// Account

export const API_ACCOUNT = "/api/account";
export const API_CHECKOUT = "/api/checkout";
export const API_ORDERS = "/api/orders";
export const API_POST_CART = "/api/cart";
export const API_DELETE_CART = "/api/emptycart";

export const API_UPDATE_ACCOUNT = "/api/account";


// Constants

export const HOME_API_URL = BASE_URL + "/publicapi/home";
export const DELIVERIES_API_URL = BASE_URL + "/publicapi/deliveries";
export const DRIVE_HOURS_API_URL = BASE_URL + "/publicapi/drivehours";
export const SEARCH_API_URL = BASE_URL + "/publicapi/search";
export const BOUCHERIES_API_URL = BASE_URL + "/publicapi/boucheries";
export const ELEVEURS_API_URL = BASE_URL + "/publicapi/eleveurs";
export const LABELS_API_URL = BASE_URL + "/publicapi/label";
export const MARQUES_API_URL = BASE_URL + "/publicapi/brand";
export const VIANDES_API_URL = BASE_URL + "/publicapi/viandes";
export const VIANDE_CATEGORY_API_URL = BASE_URL + "/publicapi/viandecategory";
export const DISCOUNT_CODE_API_URL = BASE_URL + "/publicapi/usepromotion";

export const BASE_URL_PHOTO_HOME = BASE_URL + "/uploads/products/";
export const BASE_URL_PHOTO_VENDEUR = BASE_URL + "/uploads/vendeurs/";

export const  BASE_URL_PHOTO_PRODUCT = BASE_URL + "/publicapi/product/"

export const  BASE_URL_SHOP = BASE_URL + "/publicapi/shop/"
export const  BASE_URL_NEARSHOP = BASE_URL + "/publicapi/shop/nearshops/"

export const  BASE_URL_RESTOSET = BASE_URL + "/publicapi/settingsmenu/"
//     publicapi_settingmenu ="https://admin.coterie-francaise.fr/publicapi/settingsmenu/"; 
