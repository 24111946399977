const selectUnity = (obj) => {
    if (obj === undefined || obj === null) {
        return "0";
    }
    if(obj < 0.9){
        return(
            (obj*1000).toFixed(0) + ' g'
        )
    } else {return(obj.toFixed(1) +' kg')}
};

export default selectUnity;
