import { useState } from 'react';

const useQuantity = (product) => {
    //console.log(product)
    const [data, setData] = useState({
        quantity: product?.quantity ?? 1,
        price: (product?.basePrice ?? 0) * (product?.quantity ?? 1),
        priceWithoutPromotion: product?.oldPrice ?? product?.basePrice
    });

    const setQuantity = (d) => {
        let NewData = data;

        const newQty = d + data.quantity;
        if (product?.maxWeightOrderable === undefined || product?.maxWeightOrderable === 0 || (product?.maxWeightOrderable > 0 && ((newQty * product.parts * ((product?.product?.unitProduct === true)?(product?.product?.unitProductWeight):(product?.product?.grammage))) <= product.maxWeightOrderable))) {
            const q = d + data.quantity
            if (q <= 0) {
                setData(oD => ({
                    ...oD,
                    quantity: 0
                }));
                return undefined;
            }

            const price = (product?.basePrice ?? 0) * q;
            const promotionP = (product?.basePriceWithoutPromotion ?? 0) * q

            NewData = {
                quantity: q,
                price: price,
                priceWithoutPromotion: promotionP
            }
            setData(oD => ({
                ...oD,
                ...NewData
            }));
        }

        return NewData
    }

    return {
        quantity: data.quantity,
        price: data.price,
        priceWithoutPromotion: data.priceWithoutPromotion,
        setQuantity
    };
};

export default useQuantity;
