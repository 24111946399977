import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import {
  HiMenuAlt1,
  HiX,
} from "react-icons/hi";
import debounce from 'lodash.debounce';

import LogoImg from "../assets/images/La-Coterie-Francaise-dark.jpg";
import LogoMobileImg from "../assets/images/mini-coterie.png";
import useContextApp from "../hooks/useContextApp";
import useConfession from "../hooks/Confession/useConfession";
import useCart from "../hooks/Cart/useCart";
import {SEARCH_API_URL} from "../utils/api";
import axios from "axios";
import LogoSearch from '../assets/icons/recherche.png';
import LogoCart from '../assets/icons/panier.png';
import LogoProfile from '../assets/icons/profil.png';
import Instakot from '../components/Instakot';

export default function Navigation() {
  const [config] = useContextApp();
  const [confession, setConfession, confessions] = useConfession();
  const [state] = useCart();

  const [showSearchModal, setShowSearchModal] = useState(false);

  let history = useHistory();
  let location = useLocation();

  const isHome = useMemo(() => {
    if (
      history.location?.pathname === "/" ||
      history.location?.pathname === ""
    ) {
      return true;
    }

    return false;
  }, [history.location?.pathname]);

  const [requestedConfession, setRequestedConfession] = useState(null);
  const [resultsConfession, setResultsConfession] = useState(null);

  const [results, setResults] = useState({
    products: [],
    shops: [],
    brands: [],
  });
  const [requesting, setRequesting] = useState(false);

  const [terms, setTerms] = useState("");
  const [termsToSearch, setTermsToSearch] = useState("");

  const debouncedSave = useCallback(debounce(nextValue => setTermsToSearch(nextValue), 1000), []);

  const handleChange = event => {
    const { value: nextValue } = event.target;
    setTerms(nextValue);
    debouncedSave(nextValue);
  };

  const showMenu = useMemo(() => {
    const excludes = ["boucherie", "eleveur", "panier", "paiement", "label"];

    console.log("location tmp ", location);
    const locationPaths = location.pathname.split("/");
    locationPaths.splice(0,1);

    return !excludes.includes(locationPaths[0]);
  }, [location])

  const showNav = useMemo(() => {
    const excludes = ["boucherie", "eleveur", "panier", "paiement", "label", "commencer-profil", "commencer-adresse", "commencer-confession", "commencer-vendeur","halal"];

    console.log("location tmp ", location);
    const locationPaths = location.pathname.split("/");
    locationPaths.splice(0,1);

    return !excludes.includes(locationPaths[0]);
  }, [location])


  useEffect(() => {
    if (termsToSearch !== null && termsToSearch.length) {
      if (!requesting) {
        setRequestedConfession(confession);
        setRequesting(true);
        const fetchData = async() =>{
          const confessionToRequest = confession;
          let apiUrl = SEARCH_API_URL;
          if (confession.id !== 1) {
            apiUrl += "?"+confession.slug+"=1";
          }
          //console.log("apiUrl", apiUrl);
          try {
            if (typeof window !== 'undefined' && window !== undefined) {
              window.gtag('event', 'search', {
                search_term: termsToSearch
              });
            }

            const resp = await axios.post(apiUrl, {
              terms: termsToSearch
            });
            console.log("search results", resp.data);
            setResults(resp.data);
            setRequesting(false);
            setResultsConfession(confessionToRequest);
          } catch (e) {
            setRequesting(false);
          }
        };
        fetchData();
      } else {
        if (requestedConfession !== null) {
          if (requestedConfession.id !== confession.id) {
            setRequesting(false);
          }
        }
      }
    } else {
      setResults({
        products: [],
        shops: [],
        brands: [],
      });
    }
  }, [termsToSearch]);

  const displayProducts = () => {
    if (results.products.length) {
      return (
          <>
              {results.products.map((product, index) => {
                let link = '/viande/' + product.data.category.slug + '/' + product.data.slug;
                if (resultsConfession && resultsConfession.id !== 1) {
                  link = "/"+resultsConfession.slug + link;
                }
                return (
                    <div key={index}>
                      <hr className="m-0 p-0" />
                      <Link to={link} className="result-product p-2">
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto media">
                            {/*<img
                                alt="product-img"
                                src="https://lcf-bo.slapp.me/uploads/products/kot/Boeuf/boeuf-queue-la-viande.jpg"
                                loading="lazy"
                            />*/}
                          </div>
                          <div className="col content px-3">
                            <div className="d-flex justify-content-between align-items-center mt-1">
                              <div className="product">{product.data.title}</div>
                              <div className="product-category">{product.data.category.libelle}</div>
                            </div>
                            {/*<div className="product-price-range">
                              3.23 € - 3.73 € pc.
                            </div>*/}
                          </div>
                        </div>
                      </Link>
                    </div>
                );
              })}
          </>
      );
    }
    return (
        <></>
    )
  }

  const displayShops = () => {
    if (results.shops.length) {
      return (
          <>
              {results.shops.map((shop, index) => {
                let link = "/";

                if (shop.company.seller.type.id === 2) {
                  link = "/eleveur/";
                } else {
                  link = "/boucherie/";
                }
                link += shop.city.slug +"/"+ shop.slug;

                if (shop.specificity && shop.specificity.id !== 1) {
                  link = "/" + shop.specificity.slug + link;
                }

                return (
                    <div key={index}>
                      <hr className="m-0 p-0" />
                      <Link to={link} className="result-seller p-2">
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto media">
                            {/*<img
                                alt="product-img"
                                src="https://lcf-bo.slapp.me/uploads/products/kot/Boeuf/boeuf-queue-la-viande.jpg"
                                loading="lazy"
                            />*/}
                          </div>
                          <div className="col content px-3">
                            <div className="d-flex justify-content-between align-items-center mt-1">
                              <div className="product">{shop.name}</div>
                            </div>
                            <div className="product-price-range">
                              <small>{shop.address}, {shop.city?.zipCode} {shop.city?.libelleAcheminement}</small>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                );
              })}
          </>
      );
    }
    return (
        <></>
    )
  }

  const displayBrands = () => {
    if (results.brands.length) {
      return (
          <>
              {results.brands.map((brand, index) => {
                let link = "/"
                if (confession !== undefined && confession !== null && confession.id !== 1) {
                  link += confession.slug + "/label/";
                } else {
                  link += "label/";
                }

                link += brand.regions[0].slug + "/" + brand.slug;

                return (
                    <div key={index}>
                      <hr className="m-0 p-0"/>
                      <Link to={link} className="result-brand p-2">
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto media">
                            {/*<img
                                alt="product-img"
                                src="https://lcf-bo.slapp.me/uploads/products/kot/Boeuf/boeuf-queue-la-viande.jpg"
                                loading="lazy"
                            />*/}
                          </div>
                          <div className="col content px-3">
                            <div className="d-flex justify-content-between align-items-center mt-1">
                              <div className="product">{brand.name}</div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                );
              })}
          </>
      );
    }
    return (
        <></>
    )
  }

  if (!showNav) {
    return (<></>);
  } else {
    return (
      <>

        {/*<ModalSearch show={showSearchModal} closeSearch={() => setShowSearchModal(false)}/>*/}
        <div className="navbar fixed-top">
        {/* <Instakot/> */}

          {/* <div className="confession-selector d-none d-lg-block">
            {isHome ? (
              <ul>
                {confessions.map((el) => (
                  <li
                    className={`confession-selector-item ${
                      el.id === confession.id ? "active" : ""
                    }`}
                    key={el.id}
                  >
                    <Link to={"/" + el.slug}>{el.libelle}</Link>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                {confessions.map((el) => (
                  <li
                    className={`confession-selector-item ${
                      el.id === confession.id ? "active" : ""
                    }`}
                    key={el.id}
                    onClick={() => setConfession(el.id)}
                  >
                    {el.libelle}
                  </li>
                ))}
              </ul>
            )}
          </div> */}

          <div className="sidemenu-selector">
          <ul>

          <li>
          <Link className="navbar-brand" to="/">
            <picture className="d-none d-lg-block position-logo">
              <img className="position-logo"
                src={LogoImg}
                data-img={"url("+LogoImg+")"}
                alt="Logo de La Coterie Française sur fond blanc"
                loading="lazy"
              />
            </picture>
            {/* <picture className="d-block d-lg-none logo-mobile">
              <img className="position-logo"
                src={LogoMobileImg}
                alt="Logo de La Coterie Française sur fond blanc"
                loading="lazy"
              />
            </picture> */}
          </Link> 
          </li>
          <Link to="/histoire-de-la-coterie-francaise">
            <li className="d-none d-lg-block sidemenu-selector-item-new menuinfo">
          Découvrez l’histoire
          </li></Link>
          <Link to="/livraison-de-la-coterie-francaise">
            <li className="d-none d-lg-block sidemenu-selector-item-new menuinfo">
          La livraison
          </li></Link>     
          <li className="d-lg-block sidemenu-selector-item-new">
          </li>
          <li className="d-lg-block sidemenu-selector-item-new">
          </li>       
          </ul>
          </div>
          <div className="sidemenu-selector">
          {/* <div className='flag-top'></div> */}
            <ul>
              {/* <li className="sidemenu-selector-item d-none d-lg-block">
                <Link to="/">
                  <div className="icon">
                    <img
                      src={LogoSearch}
                      data-toggle="modal"
                      data-target="#searchModal"
                      style={{width:"1rem"}}
                    />
                  </div>
                </Link>
              </li> */}


              {/* <a href="https://info.coterie-francaise.fr/"><div className="center-content-nav">
                  <div className="btn-group btn-group-toggle-inv" data-toggle="buttons">
                      <label className={"btn btn-dark btn-partenaire-label col mr-1 ml-1 commencer-button-width"}>
                          <input defaultChecked={true} type="radio" name="payment2-deliverymode" autoComplete="off"/>Devenir partenaire
                      </label>
                  </div>
              </div></a> */}


              {/* {state.cart?.length > 0 && (<li className="sidemenu-selector-item">
                <Link to="/panier">
                {state.cart?.length > 0 && (<div className="icon">
                    <img src={LogoCart} style={{width:"1rem"}} />
                  </div>)}
                  {state.cart?.length > 0 && (
                    <span className={"ml-1 text-primary"}>
                      {state.cart?.length}
                    </span>
                  )}
                </Link>
              </li>)}
              <li className="sidemenu-selector-item">
                <Link to={config.connected ? "/compte" : "/login"}>
                  <div className="d-none d-lg-block icon mr-lg-1 align-items-center">
                    <img src={LogoProfile} style={{width:"1rem"}} />
                  </div>
                  <span className="d-none d-lg-block">
                    {!config.connected
                      ? "Se connecter"
                      : `${config.user?.customer?.firstName} ${config.user?.customer?.lastName[0]}.`}
                  </span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        {/* <div className="confession-selector-mobile d-block d-lg-none">
          <ul className="row no-gutters">
            {confessions.map((el) => (
              <li
                className={`col-4 confession-selector-item ${
                  el.id === confession.id ? "active" : ""
                }`}
                key={el.id}
                onClick={() => setConfession(el.id)}
              >
                {el.libelle}
              </li>
            ))}
          </ul>
        </div> */}

        {
        // <nav className="navbar navbar-menu navbar-expand-lg navbar-light">
        //   <div className="navigation-mobile d-lg-none d-flex">
        //     <div
        //         className="mobile-toggler"
        //         data-toggle="collapse"
        //         data-target="#navbarNav"
        //         aria-controls="navbarNav"
        //         aria-expanded="false"
        //         aria-label="Toggle navigation"
        //     >
        //       <HiMenuAlt1 className="icon" />
        //     </div>
        //     <div className="mobile-search">
        //       {/*<button
        //         type="button"
        //         className="icon-search-wrapper"
        //         onClick={() => setShowSearchModal(!showSearchModal)}
        //       >
        //         <img
        //             src={LogoSearch}
        //             className="icon-search"
        //         />
        //       </button>*/}
        //     </div>
        //   </div>
        //   <div className="collapse navbar-collapse" id="navbarNav">
        //     {confession === undefined ||
        //     confession === null ||
        //     confession.id === 1 ? (
        //         <ul className="navbar-nav justify-content-around">

        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/boeuf"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Boeuf
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/veau"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Veau
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/agneau"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Agneau
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/porc"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Porc
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/poulet"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Poulet
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/dinde"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Dinde
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to="/viande/cheval"
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Cheval
        //             </NavLink>
        //           </li>

        //         </ul>
        //     ) : (
        //         <ul className="navbar-nav justify-content-around">
        //           <li className="nav-item ">
        //             <NavLink
        //                 to={"/" + confession.slug + "/viande/boeuf"}
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Boeuf
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to={"/" + confession.slug + "/viande/veau"}
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Veau
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to={"/" + confession.slug + "/viande/agneau"}
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Agneau
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to={"/" + confession.slug + "/viande/poulet"}
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Poulet
        //             </NavLink>
        //           </li>
        //           <li className="nav-item ">
        //             <NavLink
        //                 to={"/" + confession.slug + "/viande/dinde"}
        //                 className="nav-link"
        //                 activeClassName="active"
        //             >
        //               Dinde
        //             </NavLink>
        //           </li>
        //         </ul>
        //     )}
        //   </div>
        // </nav>
        }

        <div
          className="modal fade search-modal"
          tabIndex="-1"
          id="searchModal"
          aria-labelledby="SearchModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <input type="search" className="search-input" placeholder="Rechercher un produit, un vendeur..." value={terms} onChange={(e) => handleChange(e)}/>
                <button
                  type="button"
                  className="btn px-1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <HiX />
                </button>
              </div>
              <div className="modal-body p-0">
                {displayProducts()}
                {displayShops()}
                {displayBrands()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
