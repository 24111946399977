import React from 'react';
import CartReducer, { cartDefaultState } from './_reducer/CartReducer';
import { CartTypes } from './_reducer/CartTypes';

export const cartContext = React.createContext();

const CartProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(CartReducer, cartDefaultState);

    React.useEffect(() => {
        if (localStorage.getItem("Cart")) {
            const cart = JSON.parse(localStorage.getItem("Cart"));
            //console.log("Retrieve cart", cart);
            dispatch({type: CartTypes.SET_CART, payload: cart})
        }
    }, [])

    return (
        <cartContext.Provider value={{
            state,
            dispatch
        }}>
            {children}
        </cartContext.Provider>
    );
};

export default CartProvider;
