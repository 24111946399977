/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useMemo, useState} from 'react';
import Breadcrumb from './Breadcrumb';
import selectUnity from '../hooks/selectUnity'

import LogoMiniImg from '../assets/images/mini-coterie.png'
//import LogoMiniImgWebp from '../assets/images/mini-coterie.webp'
import { Link } from 'react-router-dom';
import {BASE_URL_PHOTO_HOME} from "../utils/api";

export default function ProductHeader(props) {

    const [selectedChoice, setSelectedChoice] = useState(props.isSelect);
    const categoryLink = useMemo(() => {
        const breadcrumbEl = props.breadcrumbs[0]?.filter(el => el.type === "category_breadcrumb")[0];

        if (breadcrumbEl?.url)
            return breadcrumbEl.url;
        return null
    }, [props.breadcrumbs]);
    /*const props.brandLink = useMemo(() => {
        const breadcrumbEl = props.breadcrumbs[0]?.filter(el => el.type === "shop_url")[0];

        if (!breadcrumbEl) {
            return props.brandLink;
        }
        if (breadcrumbEl?.url)
            return breadcrumbEl.url;
        return null
    }, [props.breadcrumbs, props.brandLink]);*/

    useEffect(() => {
        setSelectedChoice(props.isSelect);
    }, [props.isSelect]);

    const person = (obj) => {
        if(obj === 1) {
            return "personne"
        } else{
            return "personnes"
        }
    }

    //console.log("props display", props.displayMinPriceChoice);
    //console.log("props is select", selectedChoice);

    // const selectUnity = (obj) => {
    //     if(obj < 0.9){
    //         return(
    //             obj*1000 + ' g'
    //         )
    //     } else {return(obj +' kg')}
    // }

    const handleAddCart = () => {
        const price = parseFloat(props.priceActual)
        const oldPrice = parseFloat(props.priceOld)
        const quantity = props.quantitySelect
        const promotion = props.promotion

        props.onAddCart({
            price,
            oldPrice,
            quantity,
            promotion
        })
    }

    let confessionLibelle = "";
    if (props.confession !== undefined && props.confession !== null && props.confession.id !== 1) {
        confessionLibelle = " (" + props.confession.libelle + ")";
    }

    //console.log(props)

    return (
        <header className="product-header">
            <div className="row flex-nowrap align-items-center">
                <div className="col">
                    <Breadcrumb
                    data={props.breadcrumbs}
                    selected={0}
                    />
                </div>
                <div className="col-auto">
                    <button className="btn-outline-primary btn-sm btn" data-toggle="modal" data-target="#shareModal">
                        <strong>Partager</strong>
                    </button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-3 col-xl-4 product-image">
                    <div className="product-image-wrapper">
                        <img src={props.picture} property="schema:image" alt={`Photo de ${props.title} ${props.productData.category.determinant}${props.productData.category.libelle.toLowerCase()} ${confessionLibelle ? `(${confessionLibelle}) ` : ""}vendu par ${props.routeType === "viande" ? "La Coterie Française" : props.seller} ${(props.routeType === "boucherie" || props.routeType === "eleveur") ? " à " + props.currentShop?.city?.nomCommuneComplet : ""}`}/>
                        {(props.confession !== undefined && props.confession !== null && props.confession.id !== 1) &&
                        <span className="confession">{props.confession.libelle}</span>
                        }
                        {(props.discount !== undefined && props.discount !== null) &&
                        <span className="discount">{props.discount}</span>
                        }
                        {props.productData.brand !== null &&
                            <div className="labels">
                                {props.productData.brand.labels.map((label, index) => {
                                    return (<img className="labels-img" key={index} src={BASE_URL_PHOTO_HOME + label.picture} loading={"lazy"} alt={"Logo de " + label.libelle}/>);
                                })}
                            </div>
                        }
                    </div>
                    <div className="my-1">
                        <div className="text-muted">
                            <small><i><span property="schema:slogan" content={props.title + " " + props.productData.category.determinant + props.productData.category.libelle.toLowerCase() + confessionLibelle}>{props.title + " " + props.productData.category.determinant + props.productData.category.libelle + confessionLibelle}</span> de dimensions max. du colis : Hauteur: <span property="schema:height" content={"50cm"}>50cm</span> / largeur: <span property="schema:width" content={"45cm"}>45cm</span></i></small>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-9 col-xl-8 product-details">
                    <div>
                        <hr/>

                            <div className="product-title">
                                {props.brand?.name !== undefined && props.brand?.name !== null && props.brand?.name !== "" &&
                                    <div rel="schema:brand">
                                        <div typeof="schema:Brand">
                                            <div property="schema:name" content={props.brand?.name}>{props.brand?.name}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="product-category">
                                <picture className="mini-logo">
                                    <source srcSet={LogoMiniImg} property="schema:logo" type="image/png" />
                                    <img src={LogoMiniImg} property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                                </picture>
                                <h1 className="h5 d-inline-block mb-0">
                                    <span className="product">
                                        <span property="schema:name" content={props.title}>{props.title}</span>
                                        <span>&nbsp;•&nbsp;</span>
                                        {
                                            categoryLink ?
                                            <Link to={categoryLink} className="text-dark" property="schema:category" content={props.category}>{props.category}</Link>
                                            :
                                            <span property="schema:category" content={props.category}>{props.category}</span>
                                        }
                                    </span>
                                    {
                                        props.seller?
                                            <span className="seller" typeof="schema:Organization"> | <span property="schema:name" content={props.routeType === "viande" ? "La Coterie Française" : props.seller}>{
                                                props.brandLink ?
                                                <Link className="text-dark" to={props.brandLink} rel={(props.routeType === "viande" || props.routeType === "label")?"nofollow":"follow"}>
                                                    {props.seller}
                                                </Link>
                                                :
                                                props.seller
                                                }</span></span>
                                        :null
                                    }
                                </h1>

                            </div>
                            {
                                props.value !== 0 &&
                                <div className="product-subline">
                                    <div rel="schema:aggregateRating">
                                        <div typeof="schema:AggregateRating">
                                            <a href="#rates-anchor" className="product-subline-rates-link">
                                                <span property="schema:ratingValue" content={props.value} className="mr-1">
                                                    <Rating value={props.value}/>
                                                </span>
                                                <span property="schema:reviewCount" content={props.notice}>{props.notice} avis</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="my-2" property="offers" typeof="AggregateOffer">
                                <span className="price-actual"><span property="lowPrice" content={props.lowerPrice}>{props.priceActual ?? "??"}</span> <span property="priceCurrency" content="EUR">€</span></span>
                                &nbsp;
                                {props.priceOld !== undefined && props.priceOld !== null && props.priceOld > 0 &&
                                    <span>
                                        <span className="price-old">{props.priceOld} €</span>
                                        &nbsp;
                                    </span>
                                }
                                {props.promotion !== undefined && props.promotion !== null &&
                                    <p><b>{props.promotion.discount} € offerts dès {props.promotion.minPrice} € d'achats sur ce produit</b></p>
                                }
                                <div rel="schema:url" resource={window.location.href}></div>
                                {/* <span className="price-taxes">HT</span> */}
                            </div>

                        <hr/>
                        <div className="product-wrapper">
                        { ((props.routeType === 'viande') || (props.routeType === 'label')) ?
                                <div className="product-source mb-2">
                                    <div className="step-number">1</div>
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        {((props.displayMinPriceChoice === undefined) || (props.displayMinPriceChoice !== undefined && props.displayMinPriceChoice === true)) &&
                                            <label className={"btn btn-choice mr-2 mb-1 " + (selectedChoice === 'minPrice'?"active":null)}>
                                                <input type="radio" name="options-source" autoComplete="off"
                                                       checked={selectedChoice === 'minPrice'}
                                                       value={'minPrice'}
                                                       onClick={() => props.setSelect('minPrice')}
                                                       onChange={() => {}}
                                                />
                                                Au moins<br/>cher
                                            </label>
                                        }
                                        <label className={"btn btn-choice mr-2 mb-1 " + (selectedChoice === 'near'?"active":null)}>
                                            <input type="radio" name="options-source" autoComplete="off"
                                            checked={selectedChoice === 'near'}
                                            value={'near'}
                                            onClick={() => props.setSelect('near')}
                                           onChange={() => {}}
                                            />
                                            Au plus<br/>proche
                                        </label>
                                        <label className={"btn btn-choice mr-2 mb-1 " + (selectedChoice === 'bestNote'?"active":null)}>
                                            <input type="radio" name="options-source" autoComplete="off"
                                            checked={selectedChoice === 'bestNote'}
                                            value={'bestNote'}
                                            onClick={() => props.setSelect('bestNote')}
                                           onChange={() => {}}
                                            />
                                            Au mieux<br/>noté
                                        </label>
                                    </div>
                                    <p className="text-muted mb-0"><small>{props.seller} vous propose ce produit.</small></p>
                                </div>
                            :
                                null
                        }

                            <div className="product-weights mb-2">
                                <div className="step-number">{ ((props.routeType === 'viande') || (props.routeType === 'label')) ? "2" : "1"}</div>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className={ props.quantitySelect === 1?'btn btn-choice mr-2 mb-1 active':'btn btn-choice mr-2 mb-1'}>
                                        <input type="radio" name="options" autoComplete="off"
                                        checked={props.quantitySelect === 1}
                                        value={'minPrice'}
                                        onClick={() => props.setQuantitySelect(1)}
                                       onChange={() => {}}
                                        />
                                        {selectUnity(props.quantity)}<span>{props.nbr_person}&nbsp;{person(props.nbr_person)}
                                        </span>
                                    </label>
                                    <label className={ props.quantitySelect === 2?'btn btn-choice mr-2 mb-1 active':'btn btn-choice mr-2 mb-1'}>
                                        <input type="radio" name="options" autoComplete="off"
                                        checked={props.quantitySelect === 2}
                                        value={'minPrice'}
                                        onClick={() => props.setQuantitySelect(2)}
                                       onChange={() => {}}
                                        />
                                        {selectUnity(props.quantity*2)}<span>{props.nbr_person*2}&nbsp;{person(props.nbr_person*2)}
                                        </span>
                                    </label>
                                    <label className={ props.quantitySelect === 3?'btn btn-choice mr-2 mb-1 active':'btn btn-choice mr-2 mb-1'}>
                                        <input type="radio" name="options" autoComplete="off"
                                        checked={props.quantitySelect === 3}
                                        value={'minPrice'}
                                        onClick={() => props.setQuantitySelect(3)}
                                       onChange={() => {}}
                                        />
                                        {selectUnity(props.quantity*3)}<span>{props.nbr_person*3}&nbsp;{person(props.nbr_person*3)}
                                        </span>
                                    </label>
                                    <label className={ props.quantitySelect === 4?'btn btn-choice mr-2 mb-1 active':'btn btn-choice mr-2 mb-1'}>
                                        <input type="radio" name="options" autoComplete="off"
                                        checked={props.quantitySelect === 4}
                                        value={'minPrice'}
                                        onClick={() => props.setQuantitySelect(4)}
                                       onChange={() => {}}
                                        />
                                        {selectUnity(props.quantity*4)}<span>{props.nbr_person*4}&nbsp;{person(props.nbr_person*4)}
                                        </span>
                                    </label>
                                    {/* <label className="btn btn-choice definir mr-2 mb-1">
                                        <input type="radio" name="options" autoComplete="off"/>Définir
                                    </label> */}
                                </div>
                            </div>
                            <div className="product-add">
                                <div className="step-number">{ ((props.routeType === 'viande') || (props.routeType === 'label')) ? "3" : "2"}</div>
                                    <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <button className="btn btn-primary" onClick={handleAddCart}>
                                            Ajouter à votre panier
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {/*<div className="text-muted">
                                            <small><i>Produit mis à jour le 22 Décembre 2020</i></small>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

function Rating(props) {
    const rating = Math.round(props.value);
    var rates = [];
    for (var i = 0; i < rating; i++) {
        rates.push(<span className='rate-circle mr-s1' key={i}></span>);
    }
    if (rating < 5) {
        for (var j = rating; j < 5; j++) {
            rates.push(<span className='rate-circle-empty mr-s1' key={j}></span>);
        }
    }
    return rates;
}
