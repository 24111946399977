import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";
import LogoMiniImg from '../assets/images/picto-fleche-bouton.svg'
import LogoMiniImgBis from '../assets/images/Cow_beef_set_hand_draw_vintage_engraving_style_black_and_white_clip_art_isolated_on_white_background.png'
import Title from '../components/Title';

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';

import LogoImg from "../assets/images/La-Coterie-Francaise-dark.jpg";

function RestaurantRace(props) {

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);
    const [isActive_cut, setActive] = useState(props.location.state.race.cuts[0]);

    var PoidsSelected = 0

    const race = props.location.state

    const init_poids = () => {
        
        if (props.location.state.race.cuts[0].piece.grammageMini == 0) {
            return props.location.state.race.cuts[0].piece.grammage
        } else {
            return props.location.state.race.cuts[0].piece.grammageMini
        }

    }

    const set_poids = () => {
        
        if (isActive_cut.piece.grammageMini == 0) {
            return isActive_cut.piece.grammage
        } else {
            return isActive_cut.piece.grammageMini
        }

    }

    const [Poids, setPoids] = useState(init_poids());
    const [Prices, setPrice] = useState(init_poids() / 1000 * props.location.state.race.cuts[0].piece.price);


    
    useEffect(() => {
        
        //CaculPrix(props.location.state.cuts[0].piece.grammage,props.location.state.cuts[0].piece.price)
        setPoids(set_poids())
        console.log("isActive_cut -> ", isActive_cut)
        console.log("isActive_cut -> ", isActive_cut.wines.length)
        // setActive(isActive_cut)
        // CaculPrix(isActive_cut.piece.price * isActive_cut.piece.grammage)
    
    }, [isActive_cut])

    useEffect(() => {
        setPrice(isActive_cut.piece.price*(Poids/1000))
    }, [Poids])


    const displayCuts = () => {        
        return race.race.cuts.map((cut, index) => {

            return(
                <button className={"btn btn-choice mr-1 ml-1 mb-1 " + (cut.piece.id === isActive_cut.piece.id ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => {setActive(cut);}} >{cut.piece.libelle}</button> 
            )
        })
    }

    const RetourPage = () => {
        props.history.goBack()
    }

    const displayConseil = () => {
        
        return race.race.critere.map((cri, index) => {

            return(
                <div>
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 1 && <div className='disp-flex'><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-1"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 2 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-2"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 3 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-3"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 4 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-4"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 5 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-5"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 6 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-6"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 7 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-7"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 8 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-8"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 9 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-9"></div></div></div>}
                    {Math.max((cri.value * isActive_cut.piece.taux).toFixed(0),1)  == 10 && <div className='disp-flex' ><div className='disp-flex col-3'>{cri.libelle}</div><div className="progress-bar-base"><div className="progress-bar-10"></div></div></div>}
                </div>
            )
            
        })
    }

    const displayPoids = () => {
        
        return(
            <div className='disp-flex'>
                <div className="col-8 custom-padding-col">
                {
                    isActive_cut.piece.grammageMini === 0 ? 
                    <div>
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage)} >{isActive_cut.piece.grammage}g</button>}
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax*2 && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage*2 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage*2)} >{isActive_cut.piece.grammage*2}g</button>}
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax*3 && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage*3 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage*3)} >{isActive_cut.piece.grammage*3}g</button>}
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax*4 && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage*4 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage*4)} >{isActive_cut.piece.grammage*4}g</button>}
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax*5 && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage*5 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage*5)} >{isActive_cut.piece.grammage*5}g</button>}
                        {isActive_cut.piece.grammage <= isActive_cut.piece.grammageMax*6 && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammage*6 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammage*6)} >{isActive_cut.piece.grammage*6}g</button>}
                    </div>
                    :
                    <div>
                        {isActive_cut.piece.grammageMini <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini)} >{isActive_cut.piece.grammageMini}g</button>}
                        {isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*2 <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*1 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*1)} >{isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*1}g</button>}
                        {isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*3 <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*2 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*2)} >{isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*2}g</button>}
                        {isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*4 <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*3 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*3)} >{isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*3}g</button>}
                        {isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*5 <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*4 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*4)} >{isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*4}g</button>}
                        {isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*6 <= isActive_cut.piece.grammageMax && <button className={"btn btn-choice bg-bouton-passif custom-padding " + (Poids == isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*5 ? 'bg-bouton-actif' : 'bg-bouton-passif')} onClick={() => setPoids(isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*5)} >{isActive_cut.piece.grammageMini+isActive_cut.piece.grammage*5}g</button>}
                    </div>
                }
                </div>
                <div className="col-4 text-center-class">
                    {Prices.toFixed(2) + "€"}
                </div>

            </div>
        )

    }

    return (
        
        <div className="d-block d-lg-none" >
            

            {/* <Link to={{state: props.location.state }} onClick={() => RetourPage()} >
                <div className='header-style'>
                    <img src={LogoMiniImg} className="picto-down-retour" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                    <p className="title-of-p-dl text-center mb-4 info-retour">{race.race.libelle}</p>
                </div>
            </Link> */}

            <div className="d-block d-lg-none position-logo mt-1">
            <div className='p-0 race-header'>

                <div className='flag-name-add'>
                    <img src={race.race.country.flag} className="img-flag-light-add" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />
                </div>

                <div className="type_race p-0 pt-2 ml-2">{race.race.category.libelle}</div>
                <div className="type_race_nom-bis p-0 ml-2">{race.race.libelle}</div>
                <div className="type_race_town p-0 ml-2">{race.race.country.libelle}</div>
                <div className="type_race p-0 mt-1 ml-2 mr-2">{race.race.description.slice(0,250) + "..." }</div>
                <div className="type_race p-0 mt-1 ml-2 mr-2 pb-2 toright">Naissance : {props.location.state.naissanceCountry.libelle}<br/>Élevage : {props.location.state.elevageCountry.libelle}<br/>Abattage : {props.location.state.abattageCountry.libelle}</div>

            </div>
            </div>

            <div className="type_race_grid" > 

            <div className='stickystyle'>
                        
            <div className="type_race p-0 pt-2 ml-1"><b>Choisissez une pièce</b></div>
            
            <div className="mt-2">
                {false ? <p>Chargement</p> : displayCuts()}
            </div>
            
            
            {isActive_cut.piece.price != 0 && <div className="type_race p-0 pt-2 ml-1"><b>Et son poids approximatif</b></div>}

            { isActive_cut.piece.price != 0 && <div className="mt-2">
                {false ? <p>Chargement</p> : displayPoids()}
            </div>}

            </div>

            {isActive_cut.piece.recipe && <img src={BASE_URL + "/uploads/publications/" + isActive_cut.piece.recipe} className="picto-animal-race p-0 pt-3 pb-3" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />}

            {isActive_cut.wines.length > 0 && <div className="type_race p-0 pt-2 ml-1 pb-1"><b>Accord mets-vins</b></div>}
            {isActive_cut.wines.length > 0 && <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph"><b>{isActive_cut.wines[0].name}</b></div>}
            {isActive_cut.wines.length > 0 && <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph">{isActive_cut.wines[0].description}</div>}
            {isActive_cut.wines.length > 0 && <img src={BASE_URL + "/uploads/publications/" + isActive_cut.wines[0].image} className="picto-animal-race-bis pt-2 pb-3" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />}
            {isActive_cut.wines.length > 1 && <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph"><b>{isActive_cut.wines[1].name}</b></div>}
            {isActive_cut.wines.length > 1 && <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph">{isActive_cut.wines[1].description}</div>}
            {isActive_cut.wines.length > 1 && <img src={BASE_URL + "/uploads/publications/" + isActive_cut.wines[1].image} className="picto-animal-race-bis pt-2 pb-3" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />}

            <div className='race-header m-0 mt-2 pb-2 pt-2'>
                <div className="type_race p-0 pt-1 ml-1"><b>En savoir plus</b></div>
                <div className="type_race_desc-title pb-2 p-0 pt-1 ml-1 style-paraph">Pour un niveau de cuisson de la viande à point, voilà pourquoi choisir cette pièce.</div>

                {false ? <p>Chargement</p> : displayConseil()}

            </div>
            <div>

                <div className="type_race p-0 pt-3 ml-1 pb-1"><b>Représentation</b></div>
                <div>
                {isActive_cut.piece.location == null && <img src={BASE_URL + "/uploads/publications/" + race.image} className="picto-animal-race p-3" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />}
                {isActive_cut.piece.location != null && <img src={BASE_URL + "/uploads/publications/" + isActive_cut.piece.location} className="picto-animal-race p-3" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />}
                {/* <img style="max-width: 70%; object-fit: cover;margin-top: 40px;margin-bottom: 40px;position: absolute;left: 0px;margin-left: 40px;opacity: 0.6;" src="{{'https://admin.coterie-francaise.fr/uploads/publications/' + location_piece}}"/> */}
                </div>

                <div className="type_race p-0 pt-3 ml-1 pb-1"><b>La pièce : {isActive_cut.piece.libelle}</b></div>
                <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph">{isActive_cut.piece.description}</div>



                <div className='race-header m-0 mt-2 pb-2 pt-2'>

                    <div className="type_race p-0 pt-2 ml-1 pb-1"><b>Production et Fournisseur viande</b></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Pays</div><div className="type_race_desc-value">{race.race.country.libelle}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Region</div><div className="type_race_desc-value">{race.race.regions[0].name}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Couleur</div><div className="type_race_desc-value">{race.race.couleur}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Saisonnalité</div><div className="type_race_desc-value">{race.race.saisonnalite}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Poids moyen</div><div className="type_race_desc-value">{race.race.poids}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Poids carcasse</div><div className="type_race_desc-value">{race.race.poidsCarcasse}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Nombre de vaches</div><div className="type_race_desc-value">{race.race.nombreVaches}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Nombre d'éleveurs</div><div className="type_race_desc-value">{race.race.nombreProducteurs}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Téléphone</div><div className="type_race_desc-value">{race.race.telephone}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Gestionnaire</div><div className="type_race_desc-value">{race.race.gestionnaire}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">Adresse</div><div className="type_race_desc-value">{race.race.adresse}</div></div>
                    <div className="specificity pt-0 m-1"><div className="type_race_desc-title">mail</div><div className="type_race_desc-value">{race.race.mail}</div></div>

                </div>

                <div className="type_race p-0 pt-3 ml-1 pb-1"><b>Sélection</b></div>
                <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph">{race.race.histoire}</div>

                <div className='race-header m-0 mt-2 pb-2 pt-2 mb-5'>
                    <div className="type_race p-0 pt-2 ml-1 pb-1"><b>Aptitude</b></div>                
                    <div className="type_race_desc-title p-0 pt-1 ml-1 style-paraph">{race.race.aptitude}</div>
                </div>
                
            </div>
                
        </div>

    </div>




    );
}

export default withRouter(RestaurantRace); 
