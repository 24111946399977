import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD, BASE_URL_RESTOSET} from '../utils/api';
import axios from "axios";
import Title from '../components/Title';
import LogoMiniImg from '../assets/icons/g1397.png'


function RestaurantAccueil(props) {

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);
    const [Races, setSetting] = useState([]);
    const [data, setData] = useState({});
    const [load_ok, setload_ok] = useState(false);
    const [socialLink, setsocialLink] = useState(false);

    const RequestOk = () => {

        setload_ok(true)
        
    }

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{

                const queryParams = new URLSearchParams(window.location.search);
                const id = queryParams.get('id');

                const resp = await axios.get(BASE_URL_RESTOSET + id);
                console.log("resp", resp);

                setData(resp);

                var Races_Request = []
                var Races_RequestSort = []
                Races_Request = resp.data.customer.races
                Races_Request.map((item_a, index_a) => {

                    item_a.race.cuts = []
                    item_a.race.critere = []

                    resp.data.cuts.map((item_b, index_b) => {
                        if(item_a.race.libelle == item_b.race.libelle && item_b.enabled == true) {

                            item_b.cut['grammage'] = item_b.grammage
                            item_b.cut['grammageMini'] = item_b.grammageMini
                            item_b.cut['grammageMax'] = item_b.grammageMax
                            item_b.cut['price'] = item_b.price
                            item_b.cut['recipe'] = item_b.recipe

                            item_a.race.cuts.push({"wines":item_b.wines,"piece":item_b.cut,"race_libelle":item_a.race.libelle,"actif":true,"set":{"grammageMini":item_b.cut.grammageMini,"grammageMax":item_b.cut.grammageMax,"grammage":item_b.cut.grammage,"price":item_b.cut.price}})}

                    })

                    resp.data.criteres.map((item_c, index_b) => {
                        if(item_c.race.libelle == item_a.race.libelle) {item_a.race.critere.push({"value" : item_c.value,"set" : item_c.value, "libelle": item_c.critere.libelle, "id": item_c.critere.id })}
                    })

                })

                setsocialLink(resp.data.customer.socialLink)

                console.log("data MENU Cuts -> ", Races_Request)
                Races_RequestSort = Races_Request.sort(function (a, b) {
                    return a.race.category.libelle.localeCompare(b.race.category.libelle);
                });
                console.log("data MENU Cuts Races_RequestSort -> ", Races_RequestSort)


                RequestOk(true)
                //this.Races = Races_Request
                setSetting(Races_RequestSort);

                //console.log("les races -> ", Races_Import)
                //setSetting(Races_Import)
                
            };
            fetchData();
        }
    }, [urlVerified])

    return (
        
        <div className="d-block d-lg-none" >

        <picture className="d-block d-lg-none position-logo text-center">
        {data?.data?.customer?.companyName == null && <p className="title-of-p-dl text-center mb-4">Bonjour !</p>}
        {data?.data?.customer?.companyName != null && <p className="title-of-p-dl text-center mb-4">{data?.data?.customer?.companyName}</p>}
        </picture>

        <div className="commencer-accueil-text-dl"> 
            <div>
                <h1 className="title-of-h1-dl text-center">Viandes d'exception</h1>
                <p className="title-of-p-dl text-center">Notre établissement propose des viandes d’exception. Découvrez leurs histoires et origines, et faites votre choix !</p>
                <div className="voir-shop">
                {/* <Link to={"/restaurant-carte"} className={"mt-1 btn btn-primary class-full-widh"}>Voir la carte</Link> */}
                
                {load_ok ?
                    <Link to={{pathname: "/restaurant-carte", state: Races }} className={"mt-1 btn btn-primary class-full-widh"}>Voir la carte</Link>
                    :
                    <Link className={"mt-1 btn btn-secondary class-full-widh"}>Patientez ...</Link>
                }
                {socialLink &&
                    <a href={socialLink}><div className={"mt-1 btn avis-btn class-full-widh"}>Laissez-nous un avis</div></a>
                    // :
                    // <a href={socialLink}><div className={"mt-1 btn btn-secondary class-full-widh"}>Patientez ...</div></a>
                }
                </div>
            </div>
        </div> 
        
        {/* https://g.page/r/Cc40_Za1QhD2EB0/review */}

        <img src={LogoMiniImg} className="picto-down-home" property="schema:logo" alt="Pictogramme de La Coterie Française" loading="lazy" />


        <div className='decret'>
        Une réponse au Décret n°2022-65 sur affichage obligatoire de l'origine des viandes. 
        </div>

        <div className='flag'>
        </div>

    </div>
    );
}

export default withRouter(RestaurantAccueil);
