import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { confessionContext } from './ConfessionProvider';

const useConfession = () => {
    const {confession, setConfession, confessions, getConf} = useContext(confessionContext);

    return [confession, setConfession, confessions, getConf];
};

export default useConfession;
