import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from "react-cool-onclickoutside";
import { Modal } from 'react-bootstrap';
import { HiX } from 'react-icons/hi';

export default function ModalAddress(props) {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: ["fr"]}
        },
        debounce: 300,
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                props.onSelect({lat, lng});
                props.onHide();
                //console.log("📍 Coordinates: ", { lat, lng });
            })
            .catch((error) => {
                //console.log("😱 Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

        //console.log(ready, value)
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                centered
                size="lg"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered my-0">
                    <div className="modal-content border-0">
                        <div className="modal-header d-flex align-items-center justify-content-between">
                            <h5 className="modal-title" id="shareSocialModal">Vérifions les produits disponibles près de chez vous</h5>
                            <button className="icon btn p-0" onClick={props.onHide}>
                                <HiX size="1.1rem" />
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className="mb-3">Pour calculer nos tarifs et vous proposer nos artisans les plus proches, nous avons besoin de connaître de votre adresse de livraison ou ville la plus proche dans le cas d'une récupération en magasin.</p>
                            <div className="address-search-wrapper" ref={ref} >
                                <input
                                    value={value}
                                    onChange={handleInput}
                                    disabled={!ready}
                                    className="form-control"
                                    placeholder="Entrez une adresse"
                                />
                                {status === "OK" && <ul className="result-list">{renderSuggestions()}</ul>}
                            </div>
                        </div>
                        <div className="bg-dark p-3 text-light text-center">
                            <h3 className="mb-22"><strong>En livraison ou en boutique</strong></h3>
                            <p>Pour calculer nos tarifs et vous proposer nos artisans les plus proches, nous avons besoin de connaître de votre adresse de livraison ou ville la plus proche dans le cas d'une récupération en magasin.</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
