import React from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import usePagination from '../../hooks/usePagination';

const OFFSET = 2;

const Pagination = ({list, itemsPerPage, onChange}) => {
    const [currentPage, pages, setCurrentPage] = usePagination(list || [], itemsPerPage);

    const handleButton = (d) => {
        if (currentPage + d >= 0 && currentPage + d < pages?.length) {
            setCurrentPage(currentPage + d);
            if (onChange) {
                onChange(currentPage + d);
            }
        }
    }

    const handlePageSelection = (n) => {
        setCurrentPage(n);
        if (onChange) {
            onChange(n);
        }
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="row py-3">
                <button className="mr-1 btn btn-dark px-1" onClick={() => handleButton(-1)}><RiArrowLeftSLine size="1.1rem" /></button>
                {
                    pages?.map((el, i) => {
                        if ((i < currentPage - OFFSET && i !== 0) || (i > currentPage + OFFSET && pages.length - 1 > i)) {
                            return null;
                        }

                        return (<div className="col px-1" key={el}>
                            <button className={`btn ${currentPage === i ? "btn-outline-primary" : "btn-dark"} px-2`} onClick={() => handlePageSelection(i)}>
                                {
                                    ((i === currentPage - OFFSET && i !== 0) || ((i === (currentPage + OFFSET)) && pages.length - 1 !== i)) ?
                                    "..."
                                    :
                                    el
                                }
                            </button>
                        </div>);
                    })
                }
                <button className="ml-1 btn btn-dark px-1" onClick={() => handleButton(1)}><RiArrowRightSLine size="1.1rem" /></button>
            </div>
        </div>
    );
};

export default Pagination;