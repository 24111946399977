export const CartTypes = {
    ADD_ITEM: "ADD_ITEM_TO_CART",
    REMOVE_ITEM: "REMOVE_ITEM_FROM_CART",
    UPDATE_ITEM: "UPDATE_ITEM_IN_CART",
    REMOVE_CART: "REMOVE_ALL_CART",
    SET_CART: "SET_NEW_CART",
    CHANGE_CART: "CHANGE_TO_NEW_CART",
    CHANGE_PAYMENT: "CHANGE_PAYMENT",
    SET_CHECKOUT: "SET_CHECKOUT",
    SET_DISCOUNT: "SET_DISCOUNT",
    SET_DELIVERY_SERVICES: "SET_DELIVERY_SERVICES",
    SET_CODE_PROMO: "SET_CODE_PROMO",
}
