import React from 'react';
import { RiArrowRightSLine } from "react-icons/ri";

export default function ProductDescription(props) {

    return (
        <>
            <div className="accordion" id="accordionExample">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <h2 className="mb-0">
                            DESCRIPTION
                        </h2>
                        <RiArrowRightSLine className="arrow"/>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body" property="schema:description" content={props.description} dangerouslySetInnerHTML={{ __html: props.description?.replace(/(?:\r\n|\r|\n)/g, "<br/>") }}>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <h2 className="mb-0">
                            DÉLAI DE PRÉPARATION
                        </h2>
                        <RiArrowRightSLine className="arrow"/>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Suivant les pièces sélectionnées, il est possible qu’un délai de préparation soit nécessaire. Lorsqu’il est mentionné, nous vous demandons bien vouloir considérer ce temps nécessaire à la mise à disposition de la commande. Aussi, lorsque la commande est passée chez un producteur, il est bien de noté que ce dernier est soumis à une périodicité pouvant varier suivant sa relation avec l’abattoir. Acheter en circuit-court, c’est aussi prendre en compte les contraintes de disponibilité des viandes.</p>
                        </div>
                    </div>
                </div>
                <div className="card"  id="rates-anchor">
                    <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <h2 className="mb-0">
                            LIVRAISON
                        </h2>
                        <RiArrowRightSLine className="arrow"/>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Nos partenaires de livraison assurent le maintien de la chaîne du froid de la collecte à la livraison. Vos colis de viandes sont transportés dans des contenants réfrigérés et des véhicules frigorifiques répondant à la norme ATP. Pour de très longue distance (>20km) les viandes sont ensuite conservées en chambre froide selon la température exigée par le produit. Les températures sont enregistrées et contrôlées, si un écart est constaté nous sommes prévenus par notre transporteur.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

/*
description
delaiPreparation
livraison
*/
