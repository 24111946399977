import React from 'react';

import LogoDelaiPreparation from '../assets/icons/delai-de-preparation.png';
import LogoDelaiLivraison from '../assets/icons/delai-de-livraison.png';
import LogoRefrigere from '../assets/icons/transport-refrigere.png';

export default function DeliveryTime(props) {

    return (
        <div className="delivery-time container">
            <div className="row align-items-center">
                {props.preparationTime !== undefined && props.preparationTime !== null &&
                    <div className="col-12 col-md-4 my-md-2">
                        <div className="d-flex align-items-center justify-content-lg-center py-2">
                            <img className="logo mr-3 mr-lg-2" src={LogoDelaiPreparation} alt={"Délai de préparation " + props.product.title + " " + props.product.category.determinant + props.product.category.libelle.toLowerCase() + (props.confession !== null && props.confession.id !== 1 ? " ("+props.confession.libelle+")": "")}/>
                            <p className="mb-0"><span className="text-medium">Délai de préparation :</span><br/>
                                {props.preparationTime > 0
                                    ?
                                    props.preparationTime + " " + props.preparationTime > 1 ? 'jours' : 'jour'
                                    :
                                    "Sans délai"
                                }
                            </p>
                        </div>
                    </div>
                }
                {props.deliveryTime !== undefined && props.deliveryTime !== null &&
                    <div className="col-12 col-md-4 my-md-2">
                        <div className="d-flex align-items-center justify-content-lg-center py-2">
                            <img className="logo mr-3 mr-lg-2" src={LogoDelaiLivraison} alt={"Délai de livraison " + props.product.title + " " + props.product.category.determinant + props.product.category.libelle.toLowerCase() + (props.confession !== null && props.confession.id !== 1 ? " ("+props.confession.libelle+")": "")}/>
                            <p className="mb-0"><span className="text-medium">Délai de livraison :</span><br/>
                                {props.deliveryTime}&nbsp;{props.deliveryTime > 1 ? 'jours' : 'jour'}
                            </p>
                        </div>
                    </div>
                }
                <div className="col-12 col-md-4 my-md-2">
                    <div className="d-flex align-items-center justify-content-lg-center py-2">
                        <img className="logo mr-3 mr-lg-2" src={LogoRefrigere} alt={"Transport réfrigéré " + props.product.title + " " + props.product.category.determinant + props.product.category.libelle.toLowerCase() + (props.confession !== null && props.confession.id !== 1 ? " ("+props.confession.libelle+")": "")}/>
                        <p className="mb-0"><span className="text-medium">Transport réfrigéré :</span><br/>
                            Oui
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
