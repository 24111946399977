import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import LoginImg from '../assets/images/coterie-francaise-connexion.jpg'
import useContextApp from '../hooks/useContextApp';
import useRequest, {BASE_URL} from '../hooks/useRequest';
import {LOGIN_USER, MARQUES_API_URL, RESET_PASSWORD, VERIFY_RESET_PASSWORD} from '../utils/api';
import axios from "axios";

import HowStep from "../components/HowStep";
import TitleSimple from '../components/TitleSimple';
import StepWizard from 'react-step-wizard';


function CommencerConfession(props) {
    const params = props.match.params;
    const account = params.account;
    const token = params.token;

    const { register, handleSubmit } = useForm()
    const [handleRequest] = useRequest();
    const [config, {setToken, setUser}] = useContextApp();
    const [error, onError] = useState(null);

    const [urlValid, setUrlValid] = useState(false);
    const [urlVerified, setUrlVerified] = useState(false);
    const [urlVerify, setUrlVerify] = useState(false);

    useEffect(() => {
        if (!urlVerified && !urlVerify) {
            setUrlVerify(true);
            const fetchData = async() =>{
                const resp = await axios.get(BASE_URL + VERIFY_RESET_PASSWORD(account, token));
                console.log("resp", resp);
                setUrlValid(resp.data.success);
                setUrlVerified(true);
            };
            fetchData();
        }
    }, [urlVerified])

    var imageBgStyle = {
        backgroundImage: 'url(' + LoginImg + ')',
    };

    const toSubmit = (values) => {
        if (values.password !== values.password2) {
            onError("Les mots de passe ne sont pas identiques");
        } else {
            handleRequest("post", BASE_URL + RESET_PASSWORD(account, token), values, config, (data, status) => {
                if (status === "Done") {

                    props.history.push("/login")
                } else if (status === "Error") {
                    switch (data.status) {
                        case 401:
                            onError("Votre adresse mail ou mot de passe est éronné.");
                            break;
                        case 400:
                            onError("Veuillez remplir tous les champs nécéssaires.");
                            break;
                        default:
                            onError(`Une erreur de type ${data.status} est survenue...`);
                    }
                    console.error("Error on login", data);
                }
            })
        }
    }

    const Nav = (props) => {
        const dots = [];
        const texts = ['Profil', 'Adresse', 'Confession', 'Artisans','Panier','Récupération','Paiement']
        for (let i = 1; i <= props.totalSteps; i += 1) {
            const isActive = props.currentStep === i;
            dots.push((
                <div
                    key={`step-${i}`}
                    onClick={() => {
                        if (i < props.currentStep) {
                            props.goToStep(i)
                        }
                    }}
                    className="text-center"
                >
                    <div className={"dot " + (isActive ? 'active' : '')}>
                        {i}
                    </div>
                    <p className={"dot-description w-100 text-center " + (isActive ? 'active' : '')}>
                        {texts[i-1]}
                    </p>
                </div>
            ));
        }
    
        return (
            <div className="nav d-flex align-items-center justify-content-between">
                {dots}
            </div>
        );
    };

    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            enterLeft: "animated enterLeft",
            exitRight: "animated exitRight",
            exitLeft: "animated exitLeft",
        },
        demo: true, // uncomment to see more
    });

    const setInstance = () => updateState({
        ...state,
    });

    const First = props => { return null}
    const Second = props => {return null}
    const Third = props => {return null}
    const Quatre = props => {return null}
    const Cinq = props => {return null}
    const Six = props => {return null}
    const Last = props => {return null}

    const SetConfessionTrad = () => {
        window.gtag('set', 'user_properties', {
            Confession: 'traditionnelle',
        });
    }

    const SetConfessionHalal = () => {
        window.gtag('set', 'user_properties', {
            Confession: 'halal',
        });
    }

    return (
        
        <div className="login">

            <div className="pt-3 pr-5 pl-5 center-content-wiz"> 
                <div className="avatar-passe d-none d-lg-block " >1<div className="avatar-desc">Profil</div></div><div className="avatar-passe d-lg-none d-none" >1</div>
                <div className="avatar-passe d-none d-lg-block " >2<div className="avatar-desc">Adresse</div></div><div className="avatar-passe d-lg-none" >2</div>
                <div className="avatar-valide d-none d-lg-block " >3<div className="avatar-desc">Confession</div></div><div className="avatar-valide d-lg-none" >3</div>
                <div className="avatar d-none d-lg-block " >4<div className="avatar-desc">Artisans</div></div><div className="avatar d-lg-none  d-none" >4</div>
                <div className="avatar d-none d-lg-block " >5<div className="avatar-desc">Panier</div></div><div className="avatar d-lg-none  d-none" >5</div>
                <div className="avatar d-none d-lg-block " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none d-none" >6</div>
                <div className="avatar d-none d-lg-none " >6<div className="avatar-desc">Récupération</div></div><div className="avatar d-lg-none" >...</div>
            <div className="avatar d-none d-lg-block " >7<div className="avatar-desc">Paiement</div></div><div className="avatar d-lg-none" >7</div>
                    {/* <div className="avatar-valide" >2</div>
                    <div className="avatar" >...</div>
                    <div className="avatar">7</div> */}
                </div>
            <div className="center-content-wiz-line pb-4"></div>

            <TitleSimple 
                subtitle={"Choisissez votre confession"}
                text="La meilleure qualité de la viande pour toutes les confessions ! De par cette belle diversité culturelle et religieuse du territoire nous accompagnons toutes les communautés souhaitants mieux manger."
            />

            <div className="mb-3 center-content">
                <div className="btn-group btn-group-toggle btn-group-choix" data-toggle="buttons">
                    <Link onClick={SetConfessionTrad} to={"/commencer-vendeur"+window.location.search+"&confession=traditionnelle&limit=3"} className={"btn btn-dark py-2 col mr-1 ml-1 commencer-button-width commencer-button-width"}>Traditionnelle</Link>
                    <Link onClick={SetConfessionHalal} to={"/commencer-vendeur"+window.location.search+"&confession=halal&limit=3"} className={"btn btn-dark py-2 col mr-1 ml-1 commencer-button-width commencer-button-width"}>Halal</Link>
                    {/* <Link to={"/commencer-vendeur"+window.location.search+"&confession=casher&limit=3"} className={"btn btn-dark py-2 col mr-1 ml-1 commencer-button-width commencer-button-width"}>Casher</Link> */}
                    <div className={"btn btn-desactivate py-2 col mr-1 ml-1 commencer-button-width commencer-button-width"}>Casher</div>
                </div>
            </div>
            <div className="center-content">
                <Link to="/">Annuler</Link>
            </div>

        </div>
    );
}

export default withRouter(CommencerConfession);
