import { useContext } from 'react';
import { cartContext } from './CartProvider';
import { CartTypes } from './_reducer/CartTypes';

const useCart = () => {
    const {state, dispatch} = useContext(cartContext);

    const actions = {
        addItem: (data) => {
            const payload = data;
            dispatch({type: CartTypes.ADD_ITEM, payload});
        },
        removeItem: (data) => {
            const payload = data;
            dispatch({type: CartTypes.REMOVE_ITEM, payload});
        },
        updateItem: (data) => {
            const payload = data;
            dispatch({type: CartTypes.UPDATE_ITEM, payload});
        },
        removeCart: () => {
            dispatch({type: CartTypes.REMOVE_CART});
        },
        setCart: (data) => {
            const payload = data;
            dispatch({type: CartTypes.SET_CART, payload});
        },
        changeCart: (data) => {
            const payload = data;
            dispatch({type: CartTypes.CHANGE_CART, payload});
        },
        changePayment: (data) => {
            const payload = data;
            dispatch({type: CartTypes.CHANGE_PAYMENT, payload});
        },
        setCheckout: (data) => {
            const payload = data;
            dispatch({type: CartTypes.SET_CHECKOUT, payload});
        },
        setDiscount: (data) => {
            const payload = data;
            dispatch({type: CartTypes.SET_DISCOUNT, payload});
        },
        setDeliveryServices: (data) => {
            const payload = data;
            dispatch({type: CartTypes.SET_DELIVERY_SERVICES, payload});
        },
        setCodePromo: (data) => {
            const payload = data;
            dispatch({type: CartTypes.SET_CODE_PROMO, payload});
        }
    }

    return [state, actions];
};

export default useCart;
