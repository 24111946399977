import React from 'react';
import {Link} from "react-router-dom";
export default function Breadcrumb(props) {

// JSON

const mapBreadCrum = (data) => data.map((item, index) => {
    return(
        <li key={index} className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <span itemProp="item">
                {item.url !== null
                    ?
                    <Link to={item.url}>{item.value}</Link>
                    :
                    item.value
                }
            </span>
        </li>
    )
})

    return props.data.map((item, index) => {
            return(
                <nav key={index} aria-label="breadcrumb" className={index === props.selected ? "d-inline-block d-lg-block" : "d-none"} >
                    <ol className="breadcrumb mb-0" itemScope itemType="https://schema.org/BreadcrumbList">
                        {mapBreadCrum(item)}
                    </ol>
                </nav>
            )
        });
}
